import React, { useEffect, useState } from 'react';
import "@assets/css/landing-page.css"
import {
    CardCity,
} from "../../shared";
import free from "@assets/images/label-free.png"
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import axiosInstance from "@utils/axiosInstance";
import { useLocation } from 'react-router-dom';
import { IPost } from "../../../types/state/post";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { formatDate } from '@utils/constants';
import { map } from 'lodash';

const DetailNewsPageView = () => {
    const location = useLocation();
    const slug = location.pathname.split("/")[2];
    const [loading, setLoading] = useState<boolean>(false);
    const [post, setPost] = useState<IPost>();
    useEffect(() => {
        getNews();
    }, []);
    const getNews = () => {
        axiosInstance.get(`/news/findBySlug/${slug}`).then(response => {
            if (response.data.code === '200') {
                setPost(response.data.data)
                document.title = response.data.data.title;
                setLoading(false);
            }
        })
    }
    return (
        <>
            <div className="container-fluid detail-promotion">
                <div className="row">
                    <main className="col-md-12 ms-sm-auto col-lg-12">
                        <div className="row justify-content-center">

                            <div className="" style={{ backgroundColor: "#fff" }}>
                                <div className="section-promotion-detail vib-v2-section-promotion-detail">
                                    <div className="vib-v2-wrapper_new">
                                        <a href="" onClick={() => window.history.go(-1)}
                                            className="vib-v2-back-brand-car new-style-link">
                                            Trở về
                                        </a>
                                        {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> :
                                            <>
                                                {post?.type === "VIB" ?
                                                    <>
                                                        <div className="vib-v2-content-promotion-detail">
                                                            <div className="vib-v2-header-world-detail">
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div
                                                                            className="vib-v2-title-promotion-detail title-1">{post.title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="vib-v2-date-promotion">
                                                                    <h4>{formatDate(post.publishAt)}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="vib-v2-body-world-detail bg-white-shadow">
                                                                <div className="row">
                                                                    <div className="col vib-v2-left-body-world-detail">
                                                                        {map(post.contents, (content, index) => (
                                                                            <p key={index}>{content.content}</p>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="article-detail-content">
                                                            {map(post?.contents, (content, _) => (
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: content.content }} />
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="tu-van-card">
                            <div className="top-tu-van d-flex align-items-center justify-content-center">
                                <span className="top-title-tu-van">Quá nhiều thẻ tín dụng không biết nên chọn thẻ tín dụng nào ?</span>
                                <button onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }
                                } className="dg-card-v2-btn-dk01"
                                    style={{ backgroundColor: "#FFA20C" }}>
                                    <span>Tư vấn ngay</span>
                                    <img className="default-label" src={free} alt="free" />
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollToTopButton />
            </div>

            <CardCity />

        </>
    );
};

export default React.memo(DetailNewsPageView);
