import axios, {AxiosRequestConfig} from "axios";

interface CustomAxiosHeaders {
    Authorization?: string;
}

const instance = axios.create({
    baseURL: 'https://api.gocard.vn/api'
    // baseURL: 'http://localhost:8888'
});

instance.interceptors.request.use(
    (config: AxiosRequestConfig<CustomAxiosHeaders>) => {
        if (config.headers) {
            config.headers.Authorization = `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJwYXNzd29yZFwiOlwiXCIsXCJ1c2VybmFtZVwiOlwiZm9ybV9sYW5kaW5nX3BhZ2VcIixcImF1dGhvcml0aWVzXCI6W10sXCJhY2NvdW50Tm9uRXhwaXJlZFwiOnRydWUsXCJhY2NvdW50Tm9uTG9ja2VkXCI6dHJ1ZSxcImNyZWRlbnRpYWxzTm9uRXhwaXJlZFwiOnRydWUsXCJlbmFibGVkXCI6dHJ1ZX0iLCJleHAiOjE3Nzc3NzQ1MjcsImlhdCI6MTY5MTM3NDUyN30.NZFGk-xnrV5VlFf4MMllEEXz-Lk2uDwXHPTNz14ulZGYRze3b8zE7tWveVqDFcbMNMCZ5avRNtTicqQ4D5Ncqg`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

export default instance;
