import React, { PropsWithChildren } from 'react';
import './AppLayout.scss';
import { AppHeader1, AppFooter } from '@components/shared';
import { useLocation } from 'react-router-dom';
import ModalView from '../../views/ModalView/ModalView';

const AppLayout = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();
  const showHeader = location.pathname !== '/dang-ky-thanh-cong' && location.pathname !== '/dang-ky-mo-tai-khoan' && location.pathname !== '/success';
  return (
    <>
      <div style={{ overflow: 'hidden' }}
        id="page-container"
        className="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed side-trans-enabled"
      >
        <ModalView/>
        {showHeader && <AppHeader1 />}
        {children}
        {showHeader && <AppFooter />}
      </div>
    </>
  );
};

export default AppLayout;
