import React from 'react';
import './ModalPrivacy.scss';

interface props {
    closeModal: any;
}

const ModalPrivacy: React.FC<props> = ({closeModal}) => {

    return (
        <>
            <div className="modal-mask">
                <div className="modal-container">
                    <div className="modal-header" onClick={closeModal}><a>X </a></div>
                    <div className="modal-body">
                        <div className="ladi-headline ladi-transition">Chính sách bảo mật của Gocard&nbsp;<br/></div>
                        <div className="ladi-headline1 ladi-transition">
                            Điều khoản này (sau đây gọi tắt là “Điều khoản”) được xây dựng và cập nhật bởi <strong>CÔNG
                            TY
                            CP
                            CÔNG NGHỆ VÀ TRUYỀN THÔNG DAGORAS</strong> (sau đây gọi tắt là “DAGORAS”). Phạm vi áp dụng
                            của
                            Điều
                            khoản là tất cả các cá nhân được chuyển tiếp, truy cập, đăng ký tài khoản, đăng nhập, điền
                            thông tin, nhập, cung cấp thông tin tại trang truyền thông của DAGORAS (https://gocard.vn/)
                            (sau đây gọi tắt là “Người dùng”). <br/><br/>
                            Khi Người dùng đánh dấu/nhấn/ấn/click vào ô Đăng ký/Đăng ký ngay/Đồng ý, xác nhận Đăng
                            ký/Đăng ký ngay/Đồng ý qua tin nhắn, cuộc gọi, chọn các thiết lập kỹ thuật Đăng ký/Đăng ký
                            ngay/Đồng ý, … trên nền tảng website, ứng dụng, trang truyền thông của DAGORAS và/hoặc đối
                            tác của DAGORAS, Người dùng được coi là (i) đã đọc, hiểu, biết rõ và đồng ý với các điều
                            khoản của Điều Khoản này, (ii) đồng ý cung cấp, đăng ký và cho phép DAGORAS thực hiện thu
                            thập, sử dụng, tiết lộ, chuyển giao và/hoặc các hoạt động xử lý dữ liệu khác như được quy
                            định tại Điều Khoản này, (iii) đồng ý ràng buộc quyền và trách nhiệm đối với DAGORAS theo
                            Điều Khoản này, và (iv) không có bất kỳ thắc mắc, khiếu nại nào khác. <br/> <br/>

                            Trong trường hợp không đồng ý với Điều khoản và việc cung cấp thông tin, Người dùng có thể
                            không chọn Đăng ký/Đăng ký ngay/Đồng ý và không nhập/gửi/cung cấp bất kỳ thông tin cá nhân
                            nào tại trang truyền thông của DAGORAS. Vui lòng đọc kỹ Điều khoản này trước khi gửi/cung
                            cấp/nhập bất kỳ thông tin nào tại trang truyền thông của DAGORAS hoặc khi được DAGORAS yêu
                            cầu. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">1. CUNG CẤP DỮ LIỆU CÁ NHÂN </span><br/>
                            Dữ liệu cá nhân nghĩa là bất kỳ thông tin dưới dạng ký hiệu, chữ viết, chữ số, hình ảnh, âm
                            thanh hoặc dạng tương tự trên môi trường điện tử gắn liền với một con người cụ thể hoặc giúp
                            xác định một con người cụ thể (sau đây gọi tắt là “Dữ Liệu Cá Nhân”). Dữ liệu cá nhân mà
                            Người dùng cung cấp cho DAGORAS là các dữ liệu cá nhân cơ bản theo quy định pháp luật và các
                            thông tin khác do Người dùng nhập/cung cấp/đăng ký tài khoản, … bao gồm nhưng không giới
                            hạn: <br/>
                            <ul>
                                <li>Họ và Tên;</li>
                                <li>Số điện thoại;</li>
                                <li>Email;</li>
                                <li>Ngày, tháng, năm sinh;</li>
                                <li>Giới tính;</li>
                                <li>Số CMND/CCCD/Số định danh cá nhân;</li>
                                <li>Nơi sinh sống/Nơi ở hiện tại;</li>
                                <li>Nơi thường trú, nơi tạm trú;</li>
                                <li>Nghề nghiệp;</li>
                                <li>Thu nhập;</li>
                                <li>Hình thức nhận lương;</li>
                                <li>Nơi làm việc;</li>
                                <li>Các thông tin liên quan khác (có/không tham gia BHXH 3 tháng gần đây, có/không có
                                    thẻ tín dụng, có/không có khoản vay, …).
                                </li>
                            </ul>
                            <br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">2. THÔNG BÁO VỀ VIỆC XỬ LÝ DỮ LIỆU</span><br/>
                            Thông qua các nội dung nêu tại Điều khoản, DAGORAS thông báo cho Người dùng biết rõ về hoạt
                            động xử lý dữ liệu của DAGORAS trên cơ sở tuân thủ quy định pháp luật Việt Nam liên quan đến
                            bảo vệ dữ liệu cá nhân trước khi đồng ý cung cấp cho DAGORAS dữ liệu cá nhân phù hợp quy
                            định pháp luật, bao gồm các nội dung:
                            <ul>
                                <li>Mục đích xử lý;</li>
                                <li>Loại dữ liệu cá nhân được sử dụng có liên quan tới mục đích xử lý;</li>
                                <li>Cách thức xử lý;</li>
                                <li>Thông tin về các tổ chức, cá nhân khác có liên quan tới mục đích xử lý;</li>
                                <li>Hậu quả, thiệt hại không mong muốn có khả năng xảy ra;</li>
                                <li>Thời gian bắt đầu, thời gian kết thúc xử lý dữ liệu.</li>
                            </ul>
                            Việc thông báo được DAGORAS thực hiện một lần theo các nội dung tại Điều khoản trước khi
                            tiến hành đối với hoạt động xử lý dữ liệu cá nhân. DAGORAS sẽ không gửi thông báo cho Người
                            dùng mỗi khi tiến hành một hoặc nhiều hoạt động xử lý dữ liệu. <br/><br/>

                            Dữ liệu sẽ được xử lý mà không cần sự đồng ý của Người dùng khi DAGORAS xử lý dữ liệu để
                            thực hiện nghĩa vụ theo Hợp đồng/Thỏa thuận/Văn bản và tất cả các Văn bản đính kèm của Hợp
                            đồng/Thỏa thuận/Văn bản của Người dùng với DAGORAS hoặc các trường hợp khác theo quy định
                            của pháp luật. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">3. MỤC ĐÍCH XỬ LÝ DỮ LIỆU</span><br/>
                            Dữ liệu cá nhân được DAGORAS thu thập, xử lý và sử dụng cho các mục đích bao gồm: <br/>
                            3.1 <strong>Mục đích kinh doanh:</strong> Bao gồm nhưng không giới hạn các nội dung sau
                            đây: <br/>
                            <strong>a. Giới thiệu, cung cấp sản phẩm, dịch vụ</strong> <br/>
                            <ul className="no-dot">
                                <li>- Tư vấn, giới thiệu cho Người dùng về sản phẩm, dịch vụ của DAGORAS, Đối tác
                                    DAGORAS;
                                </li>
                                <li>- Triển khai truyền thông, quảng cáo về sản phẩm, dịch vụ;</li>
                                <li>- Sàng lọc, kiểm tra điều kiện cung cấp sản phẩm, dịch vụ, xác thực thông tin Người
                                    dùng hoặc bất kỳ hoạt động nào khác phục vụ thẩm định, xét duyệt nhu cầu của Người
                                    dùng theo quy định của Đối tác DAGORAS và pháp luật hiện hành;
                                </li>
                                <li>- Giao kết và thực hiện (các) hợp đồng, thỏa thuận, cam kết, biên bản hoặc bất kỳ
                                    tài liệu nào khác giữa DAGORAS và Người dùng theo các phương thức được các bên thống
                                    nhất;
                                </li>
                                <li>- Theo dõi, đôn đốc việc thực hiện các nghĩa vụ của Người dùng đối với DAGORAS như
                                    được quy định tại hợp đồng, thỏa thuận, cam kết, biên bản, điều khoản sử dụng hoặc
                                    bất kỳ tài liệu nào khác giữa các bên;
                                </li>
                                <li>- Các mục đích khác liên quan đến cung cấp sản phẩm, dịch vụ cho Người Dùng.</li>
                            </ul>
                            <br/>
                            <strong>b. Thực hiện hoạt động xúc tiến thương mại</strong><br/>
                            <ul className="no-dot">
                                <li>- Gửi đến khách hàng các nội dung chương trình, sự kiện, chiến dịch hoặc khuyến mại,
                                    quảng cáo, tiếp thị của DAGORAS bao gồm nhưng không giới hạn chương trình trao
                                    thưởng, rút thăm may mắn, gửi quà tặng và giải thưởng và bất kỳ hình thức xúc tiến
                                    thương mại khác theo quy định của pháp luật hiện hành.
                                </li>
                                <li>- Liên lạc, cung cấp các thông báo, khảo sát, thông tin sản phẩm, dịch vụ, làm rõ
                                    nhu
                                    cầu, yêu cầu của Người dùng về sản phẩm, dịch vụ, thực hiện các hoạt động truyền
                                    thông, quảng cáo, khuyến mại, phân tích, đánh giá, tối ưu các hoạt động truyền
                                    thông, quảng cáo, khuyến mại tới Người dùng liên quan đến dịch vụ được cung cấp bởi
                                    DAGORAS, Đối tác của DAGORAS.
                                </li>
                                <li>- Thực hiện hoạt động xúc tiến thương mại, truyền thông, quảng cáo với tần suất giới
                                    thiệu, truyền thông, quảng cáo, thông báo không hạn chế, không giới hạn không gian,
                                    thời gian bằng các hình thức bao gồm nhưng không giới hạn được thể hiện bằng tiếng
                                    nói, chữ viết, biểu tượng, màu sắc, ánh sáng, hình ảnh, hành động, âm thanh và các
                                    hình thức khác có khả năng truyền đạt nội dung, thông tin.
                                </li>
                            </ul>
                            <br/>
                            <strong>c. Phát triển sản phẩm, dịch vụ </strong><br/>
                            <ul className="no-dot">
                                <li>- Xác thực thông tin đăng ký tài khoản, chấp thuận quá trình đăng ký của Người dùng,
                                    cung ứng cho Người dùng một tài khoản đăng nhập vào Website (nếu có) cũng như các
                                    hoạt động sửa đổi, bổ sung, cập nhật, quản lý Thông tin đăng ký;
                                </li>
                                <li>- Thực hiện nghiên cứu, phân tích, thống kê trải nghiệm, cách thức sử dụng, nhu cầu
                                    sử dụng của Người dùng, khách hàng đối với sản phẩm, dịch vụ và/hoặc các website,
                                    nền tảng số của DAGORAS, Đối tác DAGORAS, nâng cao chất lượng dịch vụ, quản lý và
                                    bảo vệ các thông tin, hệ thống kỹ thuật dịch vụ, đo lường hiệu suất dịch vụ do
                                    DAGORAS cung ứng và cải thiện sản phẩm, dịch vụ của DAGORAS, Đối tác DAGORAS;
                                </li>
                                <li>- Lập kế hoạch sản phẩm, dịch vụ mới hoặc cải thiện, sửa đổi các sản phẩm, dịch vụ
                                    hiện tại nhằm nâng cao trải nghiệm khách hàng, nâng cao chất lượng sản phẩm, dịch vụ
                                    của DAGORAS, Đối tác DAGORAS;
                                </li>
                                <li>- Phát triển các sản phẩm, dịch vụ đặc thù phù hợp với sở thích, thói quen của từng
                                    nhóm khách hàng, Người dùng;
                                </li>
                                <li>- Đánh giá, kiểm tra, thử nghiệm các hệ thống hoặc tính năng mới của DAGORAS.</li>
                            </ul>
                            <strong>d. Cung cấp dịch vụ chăm sóc khách hàng </strong><br/>
                            <ul className="no-dot">
                                <li>- Giải quyết hoặc điều tra bất kỳ yêu cầu, phản ánh, khiếu nại nào;</li>
                                <li>- Liên lạc với Người dùng, khách hàng, bao gồm cả việc cung cấp thông tin cập nhật
                                    về các thay đổi đối với các sản phẩm, dịch vụ của DAGORAS, Đối tác DAGORAS bao gồm
                                    bất kỳ sự bổ sung, mở rộng, đình chỉ và thay thế đối với các sản phẩm, dịch vụ của
                                    DAGORAS, Đối tác DAGORAS và các điều khoản và điều kiện đi kèm.
                                </li>
                            </ul>
                            <br/>
                            3.2 <strong>Mục đích quản trị nội bộ</strong> <br/>
                            Thực hiện các nghĩa vụ của DAGORAS khi lấy ý kiến tư vấn hoặc cố vấn chuyên môn, bao gồm
                            nhưng không giới hạn tư vấn pháp lý, kế toán, kiểm toán, quản trị rủi ro, kiểm soát nội bộ,
                            đánh giá tín nhiệm, … kiểm tra, thử nghiệm các hệ thống hoặc tính năng mới của
                            DAGORAS. <br/>
                            3.3 <strong>Mục đích khác </strong> <br/>
                            Bên cạnh những mục đích đã nêu, Người dùng đồng ý cho DAGORAS thu thập, xử lý Dữ liệu cá
                            nhân của mình cho bất kỳ mục đích nào khác mà DAGORAS cho là phù hợp để thực hiện quyền
                            của DAGORAS theo các hợp đồng, thỏa thuận giữa DAGORAS và Người dùng, Điều khoản sử dụng với
                            điều kiện là việc thu thập, xử lý dữ liệu đó phù hợp với quy định của pháp luật hiện
                            hành. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">4. CÁCH THỨC THU THẬP, XỬ LÝ DỮ LIỆU</span><br/>
                            4.1. <strong>Thu thập Dữ liệu</strong><br/>
                            <strong><i>a. Thu thập trực tiếp </i></strong> <br/>
                            <ul className="no-dot">
                                <li>- <strong>Từ trang thông tin điện tử (“website”) của DAGORAS:</strong> bao gồm
                                    website:
                                    <a href="https://gocard.vn">https://gocard.vn/</a> hoặc các website khác mà DAGORAS
                                    có thể sẽ phát triển và trực
                                    tiếp quản lý trong tương lai. Thông qua cách thức này, DAGORAS có thể thu thập Dữ
                                    liệu cá nhân khi Người dùng truy cập website, điền dữ liệu tại các ô/trường thu thập
                                    thông tin và click chọn hoặc đánh dấu vào ô “Đăng ký”, “Xem kết quả” hoặc chọn các
                                    thiết lập kỹ thuật đồng ý hoặc qua một hành động tương tự, hoặc sử dụng bất kỳ
                                    tính năng, tài nguyên nào có sẵn trên hoặc thông qua website.
                                </li>
                                <li>- <strong>Từ các tương tác với nội dung hoặc quảng cáo của DAGORAS</strong> hoặc sử
                                    dụng, tiếp cận
                                    sản phẩm, dịch vụ của DAGORAS thông qua trang tin điện tử hoặc ứng dụng hoặc bất
                                    kỳ hình thức tương tác nào khác;
                                </li>
                                <li>- <strong>Từ các trao đổi, liên lạc của nhân viên, cộng tác viên của DAGORAS với
                                    Người dùng:</strong>
                                    DAGORAS có thể thu thập Dữ liệu cá nhân khi Người dùng cung cấp, chia sẻ cho
                                    DAGORAS thông qua các hình thức gặp mặt trực tiếp, qua thư, điện thoại, trực tuyến,
                                    liên lạc điện tử hoặc bất kỳ phương tiện liên lạc nào khác.
                                </li>
                                <li>- <strong>Từ các trao đổi, liên lạc của nhân viên, cộng tác viên của DAGORAS với
                                    Người dùng:</strong> DAGORAS có thể thu
                                    thập thông tin bao gồm địa chỉ IP, URL giới thiệu, hệ điều hành, trình duyệt tin
                                    điện tử và bất kỳ thông tin nào khác được ghi tự động từ kết nối:
                                </li>
                                <ul className="no-dot ps-4">
                                    <li>+ Cookie, flash cookie, thẻ pixel, tin điện tử beacons, hoặc các công nghệ theo
                                        dõi khác.
                                    </li>
                                    <li>+ Cookie, plug-in hoặc trình kết nối mạng xã hội của bên thứ ba.</li>
                                    <li>+ Bất kỳ công nghệ nào có khả năng thu thập hoạt động cá nhân trên các thiết bị
                                        hoặc trang tin điện tử.
                                    </li>
                                </ul>
                                <li>- <strong>Các phương tiện khác:</strong> DAGORAS có thể thu thập hoặc lấy Dữ liệu
                                    Cá nhân khi Người
                                    dùng tương tác với DAGORAS thông qua bất kỳ phương tiện nào khác.
                                </li>
                            </ul>
                            <br/>
                            <strong><i>b. Gián tiếp qua các đối tác thứ ba </i></strong><br/>
                            <ul className="no-dot">
                                <li>- Từ các tương tác với nội dung hoặc quảng cáo của Đối tác DAGORAS hoặc sử dụng,
                                    tiếp cận sản phẩm, dịch vụ của Đối tác DAGORAS thông qua trang tin điện tử hoặc ứng
                                    dụng hoặc bất kỳ hình thức tương tác nào khác;
                                </li>
                                <li>- Từ ứng dụng của Đối tác DAGORAS: thông qua hình thức này, DAGORAS có thể thu thập
                                    Dữ liệu cá nhân khi Người dùng thực hiện tải xuống, cài đặt, sử dụng ứng dụng
                                    và/hoặc điền Dữ liệu tại các ô/trường cung cấp Dữ liệu tại ứng dụng dành cho thiết
                                    bị di động của Người dùng.
                                </li>
                                <li>- DAGORAS có thể tiếp nhận Dữ liệu cá nhân của Người dùng từ các cơ quan nhà nước
                                    có thẩm quyền theo quy định pháp luật;
                                </li>
                                Việc thu thập Dữ liệu cá nhân qua bên thứ ba được thực hiện qua phương thức truyền
                                API, truyền qua file hoặc bất kỳ phương thức nào khác đảm bảo tính an toàn và bảo
                                mật thông tin phù hợp với quy định pháp luật.
                            </ul>
                            <br/>
                            <strong><i>c. Gián tiếp qua các nguồn dữ liệu công khai, gồm: </i></strong><br/>
                            <ul className="no-dot">
                                <li>- Trang thông tin điện tử (website) được phát triển và quản lý hợp pháp bởi bên thứ
                                    ba mà đối tượng truy cập, tiếp cận không bị hạn chế;
                                </li>
                                <li>- Báo điện tử, xuất bản điện tử và quảng cáo trên mạng;</li>
                                <li>- Mạng xã hội (social network): hệ thống thông tin cung cấp cho cộng đồng người sử
                                    dụng mạng các dịch vụ lưu trữ, cung cấp, sử dụng, tìm kiếm, chia sê và trao đổi
                                    thông tin với nhau;
                                </li>
                                <li>- Sản phẩm báo chí khác bao gồm nhưng không giới hạn ấn phẩm, báo in, báo nói, bản
                                    tin thông tấn, kênh phát thanh, kênh truyền hình;
                                </li>
                                <li>- Các nguồn dữ liệu công khai, hợp pháp khác.</li>
                            </ul>
                            <br/>
                            4.2. <strong>Phân tích Dữ liệu cá nhân </strong><br/>
                            a. Đối với Dữ liệu cá nhân mà DAGORAS thu thập được qua các cách thức quy định tại Điều
                            khoản này, DAGORAS sẽ thực hiện phân tích thông qua so sánh, đối chiếu, thẩm định, tổng
                            hợp, … phục vụ cho các mục đích được quy định tại Điều 4 Điều khoản này. Hoạt động phân
                            tích Dữ liệu cá nhân sẽ được DAGORAS thực hiện trên hệ thống nội bộ của DAGORAS với các
                            biện pháp kỹ thuật bảo mật dữ liệu cần thiết và phù hợp với quy định của pháp luật.
                            <br/>
                            b. Trong trường hợp việc phân tích được thực hiện qua hệ thống của đối tác của DAGORAS,
                            DAGORAS sẽ yêu cầu các đối tác này thực hiện đầy đủ các biện pháp bảo vệ dữ liệu theo quy
                            định của pháp luật.
                            4.3. <strong>Chia sẻ Dữ liệu cá nhân </strong><br/>
                            DAGORAS có thể chia sẻ Dữ liệu cá nhân của Người dùng cho các đối tượng sau: <br/>
                            a. Cho bất kỳ chi nhánh, công ty con, văn phòng đại diện, đại lý hoặc công ty liên kết nào
                            của DAGORAS và các công ty thuộc tập đoàn, công ty con thuộc tập đoàn;<br/>

                            b. Các nhà cung cấp dịch vụ hoặc thực hiện các dịch vụ thay mặt DAGORAS (bao gồm nhưng
                            không giới hạn bởi dịch vụ CNTT, hậu cần, viễn thông, tư vấn, phân phối, truyền thông và
                            tiếp thị);<br/>

                            c. Các đối tác của DAGORAS nếu việc chia sẻ đó là cần thiết để DAGORAS, Đối tác của
                            DAGORAS cung cấp sản phẩm, dịch vụ cho khách hàng, Người dùng hoặc thực hiện các nghĩa vụ
                            hoặc giao dịch theo hợp đồng của DAGORAS, Đối tác của DAGORAS (ví dụ: đối tác cung cấp các
                            sản phẩm thẻ tín dụng, chấm điểm tín dụng cá nhân, xác minh dữ liệu, …);<br/>

                            d. Cơ quan tài chính, thuế, hành chính, hình sự hoặc tư pháp địa phương hoặc nước ngoài,
                            cơ quan quản lý, trọng tài hoặc hòa giải viên, cơ quan thực thi pháp luật, cơ quan nhà
                            nước, cơ quan phòng chống gian lận hoặc cơ quan công quyền, mà DAGORAS được yêu cầu tiết
                            lộ: <br/>
                            <ul className="no-dot">
                                <li>- Theo yêu cầu của họ;</li>
                                <li>- Nhằm bảo vệ hoặc phản hồi về một vấn đề, hành động hoặc thủ tục pháp lý; và /
                                    hoặc
                                </li>
                                <li>- Nhằm tuân thủ luật pháp, quy định hoặc hướng dẫn từ các cơ quan có thẩm quyền
                                    áp dụng cho DAGORAS;
                                </li>
                            </ul>
                            e. Một số cá nhân, tổ chức nhất định như luật sư, công chứng viên, cơ quan xếp hạng hoặc
                            kiểm toán viên khi cần thiết trong các trường hợp cụ thể (ví dụ: tư vấn, kiện tụng, kiểm
                            toán);<br/>

                            f. Chia sẻ cho cơ quan nhà nước có thẩm quyền, các tổ chức, cá nhân liên quan khác để
                            phục vụ hoạt động phòng chống, phát hiện và xử lý hành vi gian lận, vi phạm pháp luật của
                            Người dùng trong mối quan hệ với DAGORAS. <br/><br/>
                            4.4. <strong>Lưu trữ Dữ liệu cá nhân </strong><br/>
                            DAGORAS sẽ sử dụng ứng dụng đám mây, hệ thống máy chủ để lưu trữ trong quá trình xử lý dữ
                            liệu và thực hiện các biện pháp hợp lý để bảo vệ Dữ Liệu Cá Nhân. Việc lưu trữ có thể được
                            một nhà cung cấp dịch vụ khác thay mặt DAGORAS thực hiện. Khi nhà cung cấp dịch vụ thứ ba
                            được sử dụng, DAGORAS đảm bảo có hợp đồng hợp pháp và yêu cầu nhà cung cấp bảo mật Dữ liệu
                            cá nhân, đồng thời thực hiện đầy đủ các nghĩa vụ theo quy định của pháp luật. <br/><br/>
                            4.5. <strong>Các hoạt động xử lý khác </strong><br/>
                            Các hoạt động xử lý khác đối với Dữ liệu cá nhân phục vụ mục đích quy định tại Điều
                            Khoản này và phù hợp với quy định pháp luật. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">5. QUYỀN VÀ NGHĨA VỤ CỦA NGƯỜI DÙNG </span><br/>
                            Người dùng sẽ có đầy đủ quyền và nghĩa vụ của Người dùng theo quy định của pháp luật về
                            bảo vệ Dữ liệu cá nhân, bao gồm: <br/>
                            a. Được biết về hoạt động xử lý Dữ liệu như đã được đề cập tại văn bản này. <br/>

                            b. Được quyền đồng ý hoặc không đồng ý cho phép xử lý Dữ liệu khi đề nghị DAGORAS cung cấp
                            sản phẩm, dịch vụ.
                            <br/>
                            c. Được yêu cầu DAGORAS cung cấp Dữ liệu, được hỗ trợ truy cập để xem Dữ liệu hoặc đề nghị
                            DAGORAS cập nhật, chỉnh sửa khi nhận thấy Dữ liệu mà DAGORAS đang nắm giữ là không chính
                            xác, không đầy đủ. Khách hàng hiểu rằng, DAGORAS có thể từ chối yêu cầu truy cập vào Dữ liệu
                            trong một số trường hợp nhất định như không xác định được danh tính của Khách hàng, dữ liệu
                            được yêu cầu có tính chất bảo mật hoặc Khách hàng lặp lại yêu cầu đối với cùng một dữ liệu.
                            <br/>
                            d. Khách hàng có thể rút lại sự đồng ý của mình đối với bất kỳ mục đích xử lý Dữ liệu nào
                            được nêu trong văn bản này dưới hình thức văn giấy hoặc văn bản điện tử có thể kiểm chứng
                            được. Tuy nhiên, Khách hàng hiểu và đồng ý rằng, việc rút lại sự đồng ý sẽ loại trừ đối với
                            mục đích xử lý Dữ liệu của DAGORAS nhằm bảo vệ quyền và lợi ích hợp pháp của DAGORAS, thực
                            hiện các trách nhiệm báo cáo, quản trị, lưu trữ theo quy định của pháp luật và/hoặc theo yêu
                            cầu của cơ quan nhà nước có thẩm quyền.
                            <br/>
                            e. Được khiếu nại, tố cáo, khởi kiện, yêu cầu bồi thường thiệt hại; quyền tự bảo vệ theo quy
                            định của pháp luật, hoặc yêu cầu cơ quan, tổ chức có thẩm quyền thực hiện các phương thức
                            bảo vệ quyền dân sự theo quy định của pháp luật.
                            <br/>
                            f. Khách hàng có các nghĩa vụ: <br/>
                            <ul className="no-dot">
                                <li>- Tự bảo vệ Dữ liệu;</li>
                                <li>- Yêu cầu các tổ chức, cá nhân khác có liên quan bảo vệ Dữ liệu;</li>
                                <li>
                                    - Tôn trọng, bảo vệ dữ liệu cá nhân của người khác. Nếu một cá nhân cung cấp cho
                                    DAGORAS Dữ liệu cá nhân của một bên thứ ba (bao gồm nhưng không giới hạn: thông tin
                                    của người phụ thuộc, người có liên quan theo quy định của pháp luật, vợ/chồng, con
                                    cái và/hoặc cha mẹ và/hoặc người giám hộ, bạn bè, người tham chiếu, bên thụ hưởng,
                                    người được ủy quyền, đối tác, người liên hệ trong các trường hợp khẩn cấp), cá nhân
                                    cam đoan, bảo đảm và chịu trách nhiệm rằng đã có được sự đồng ý hợp pháp của bên thứ
                                    ba là Người dùng cho phép DAGORAS xử lý Dữ liệu cá nhân theo mục đích và điều khoản,
                                    điều kiện quy định tại Điều khoản này;
                                </li>
                                <li>- Cung cấp đầy đủ, chính xác Dữ liệu khi đồng ý cho phép xử lý Dữ liệu.</li>
                            </ul>

                            g. Các quyền và nghĩa vụ khác theo quy định của pháp luật. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">6. TUÂN THỦ THỜI GIAN XỬ LÝ DỮ LIỆU CÁ NHÂN </span><br/>
                            <strong>6.1. Thời gian bắt đầu </strong><br/>
                            DAGORAS có thể bắt đầu thu thập, xử lý Dữ liệu cá nhân kể từ thời điểm DAGORAS có Dữ liệu
                            cá nhân của Người dùng hoặc tại bất kỳ thời điểm nào sau khi được Người dùng cung cấp, nhập,
                            đồng ý theo các cách thức quy định tại Điều khoản này. <br/><br/>
                            <strong>6.2. Thời gian kết thúc</strong><br/>
                            DAGORAS sẽ chấm dứt xử lý Dữ liệu cá nhân khi Người dùng yêu cầu DAGORAS xóa Dữ liệu cá
                            nhân phù hợp với quy định của Điều khoản này và quy định của pháp luật hoặc khi xảy ra một
                            hoặc các sự kiện dưới đây (tùy sự kiện nào diễn ra sau cùng): <br/>
                            <ul className="no-dot">
                                <li>Người dùng đã hoàn thành mọi nghĩa vụ với DAGORAS theo hợp đồng ký kết với
                                    DAGORAS.
                                </li>
                                <li>DAGORAS đã hoàn thành các mục đích xử lý Dữ liệu cá nhân quy định tại Điều khoản
                                    này.
                                </li>
                                <li>DAGORAS hoàn thành nghĩa vụ lưu trữ hồ sơ liên quan đến Dữ liệu cá nhân theo quy
                                    định của pháp luật.
                                </li>
                            </ul>
                            Việc kết thúc xử lý Dữ liệu trước khi hoàn thành mục đích xử lý Dữ liệu có thể gây ảnh hưởng
                            đến Mục đích xử lý dữ liệu và các công việc, hành động, mục đích theo thỏa thuận, văn bản,
                            Hợp đồng và tất cả các phụ lục đính kèm của thỏa thuận, văn bản, Hợp đồng giữa DAGORAS và
                            Người dùng. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">7. XÓA, HỦY DỮ LIỆU CÁ NHÂN SAU THỜI GIAN XỬ LÝ DỮ LIỆU CÁ NHÂN</span><br/>
                            <strong>7.1. Xóa không thể khôi phục</strong><br/>
                            DAGORAS sẽ thực hiện xóa không thể khôi phục trong trường hợp: <br/>
                            <ul className="no-dot">
                                <li>- Xử lý dữ liệu không đúng mục đích hoặc đã hoàn thành mục đích xử lý Dữ liệu cá
                                    nhân được Người dùng đồng ý;
                                </li>
                                <li>- Việc lưu trữ Dữ liệu cá nhân không còn cần thiết với hoạt động của DAGORAS;</li>
                                <li>- DAGORAS bị giải thể hoặc không còn hoạt động hoặc tuyên bố phá sản hoặc bị chấm
                                    dứt hoạt động kinh doanh theo quy định của pháp luật.
                                </li>
                            </ul>
                            <strong>7.2. Xóa, hủy dữ liệu theo yêu cầu của Người dùng</strong><br/>
                            Trong trường hợp Người dùng rút lại sự đồng ý của mình, yêu cầu xóa Dữ liệu cá nhân và/hoặc
                            thực hiện các quyền có liên quan khác đối với bất kỳ hoặc tất cả các Dữ liệu cá nhân của
                            của mình, và tuỳ thuộc vào bản chất yêu cầu của Người dùng, DAGORAS, Đối tác của DAGORAS sẽ
                            xem xét và quyết định về việc không tiếp tục cung cấp các sản phẩm, dịch vụ cho Người
                            dùng. Tùy thuộc vào nội dung thỏa thuận xác lập mối quan hệ giữa Người dùng và DAGORAS, Đối
                            tác của DAGORAS, các hành vi được thực hiện bởi Người dùng theo quy định này có thể được
                            xem là sự đơn phương chấm dứt từ phía Người dùng đối với mối quan hệ giữa Người dùng với
                            DAGORAS, Người dùng với Đối tác của DAGORAS, và hoàn toàn có thể dẫn đến sự vi phạm nghĩa
                            vụ hoặc các cam kết theo thỏa thuận giữa Người dùng và DAGORAS, Người dùng với Đối tác của
                            DAGORAS; đồng thời DAGORAS có quyền bảo lưu các quyền và biện pháp khắc phục hợp pháp
                            trong những trường hợp đó. Ngoài ra, DAGORAS sẽ không phải chịu trách nhiệm đối với Người
                            dùng cho bất kỳ tổn thất, thiệt hại nào phát sinh từ hoặc liên quan đến việc Người dùng thực
                            hiện quyền đối với Dữ liệu cá nhân của mình. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">8. BẢO VỆ DỮ LIỆU </span><br/>
                            Dữ liệu được áp dụng các biện pháp bảo vệ, bảo mật trong quá trình xử lý, bao gồm cả việc
                            bảo vệ trước các hành vi vi phạm quy định về bảo vệ dữ liệu và phòng, chống sự mất mát, phá
                            hủy hoặc thiệt hại do sự cố, sử dụng các biện pháp kỹ thuật. <br/><br/>

                            Nghĩa vụ bảo mật không áp dụng đối với Dữ liệu, thông tin được cung cấp công khai mà không
                            vi phạm nghĩa vụ bảo mật, hoặc Dữ liệu, thông tin đã nhận được từ Bên thứ ba mà không có
                            nghĩa vụ bảo mật, hoặc Dữ liệu, thông tin do DAGORAS, Đối tác của DAGORAS, Bên thứ ba tự tìm
                            ra, được Bên bất kỳ cung cấp, tiết lộ và Bên bất kỳ không có nghĩa vụ bảo mật. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">9. HẬU QUẢ, THIỆT HẠI KHÔNG MONG MUỐN CÓ KHẢ NĂNG XẢY RA </span><br/>
                            DAGORAS sẽ áp dụng các phương pháp xử lý dữ liệu thích hợp cũng như các biện pháp bảo mật
                            kỹ thuật và tổ chức thích hợp, để tránh việc truy cập, đọc, sử dụng, thay đổi, cung cấp,
                            phá hủy trái phép hoặc các quá trình xử lý Dữ liệu cá nhân. Tuy nhiên, Người dùng và DAGORAS
                            đồng ý rằng DAGORAS không thể lường trước tất cả hậu quả, thiệt hại không mong muốn xảy ra
                            đối với Dữ liệu cá nhân do yếu tố tác động từ bên ngoài, bao gồm nhưng không giới hạn: <br/>
                            <ul>
                                <li>- Mã độc, tấn công mạng, xâm nhập mạng;</li>
                                <li>- Tội phạm an ninh mạng.</li>
                            </ul>
                            <br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">10. MIỄN TRỪ TRÁCH NHIỆM </span><br/>
                            DAGORAS sẽ được miễn trừ trách nhiệm đối với các hậu quả, thiệt hại xảy ra đối với Dữ liệu
                            cá nhân nếu hậu quả, thiệt hại đó phát sinh hoàn toàn do lỗi của Người dùng hoặc do xảy ra
                            sự kiện nêu tại Điều 10 Điều khoản này hoặc sự kiện bất khả kháng khác theo quy định của
                            pháp luật. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">11. THÔNG BÁO VỀ ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ </span><br/>
                            11.1. Khách hàng đồng ý rằng DAGORAS, Bên thứ ba có thể gửi thông báo liên quan đến Điều
                            khoản này qua bất kỳ phương thức nào bao gồm: <br/>
                            a. Công bố công khai tại website của DAGORAS; Người dùng được coi là đã nhận được thông báo
                            vào ngày thông báo được công bố công khai; hoặc
                            <br/>
                            b. Gặp mặt trao đổi trực tiếp hoặc trao đổi qua điện thoại; Người dùng được coi là đã nhận
                            được thông báo tại thời điểm trao đổi trên thực tế giữa các bên; hoặc
                            <br/>
                            c. Bất kỳ phương tiện điện tử nào (bao gồm nhưng không giới hạn: SMS, email); Người dùng
                            được coi là đã nhận được thông báo khi hệ thống phương tiện điện tử ghi nhận gửi thông báo
                            thành công;
                            <br/>
                            d. Nếu Người dùng muốn liên hệ với DAGORAS liên quan đến quyền của mình hoặc nếu có bất kỳ
                            góp ý, phản hồi hoặc yêu cầu nào liên quan đến Điều khoản này, thì sẽ liên hệ với DAGORAS
                            bằng cách Gửi thư điện tử (email) cho chúng tôi theo địa chỉ: support@gocard.vn; và/hoặc qua
                            số điện thoại hotline: 02466890248, DAGORAS sẽ xử lý các yêu cầu của Người dùng sớm nhất có
                            thể trong khả năng và phạm vi theo quy định của pháp luật. <br/><br/>
                            11.2. Những thông báo này sẽ là một phần không tách rời của Điều khoản này. Các nội dung
                            chỉnh sửa, bổ sung Điều khoản sẽ được áp dụng trong trường hợp có xung đột với Điều khoản
                            này. Các sửa đổi sẽ có hiệu lực ngay khi đăng/thông báo chính thức các Điều khoản được cập
                            nhật. Người Dùng do đó hiển nhiên chịu sự ràng buộc của các Điều khoản đã được sửa đổi nếu
                            việc xử lý dữ liệu chưa kết thúc kể từ thời điểm các sửa đổi được thông báo bằng các phương
                            thức nêu trên. <br/>
                            11.3. Tất cả các quyền và nghĩa vụ của DAGORAS và Người dùng tại Điều khoản này sẽ không
                            thay thế, chấm dứt hoặc thay đổi, mà sẽ bổ sung vào các quyền, nghĩa vụ mà DAGORAS và Người
                            dùng đang có đối với bên còn lại ở bất kỳ văn bản nào. <br/>
                            11.4. DAGORAS có quyền sửa đổi, bổ sung Điều khoản này vào bất cứ thời điểm nào, đảm bảo các
                            sửa đổi, bổ sung đó phù hợp với quy định pháp luật và sẽ được thông báo tới Người dùng
                            bằng các phương thức nêu tại Điều này. <br/><br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">12. QUYỀN TỪ CHỐI CUNG CẤP DỊCH VỤ </span><br/>
                            Trong mọi trường hợp, DAGORAS có quyền đơn phương từ chối cung cấp dịch vụ tạm thời hoặc
                            vĩnh viễn với Người dùng hoặc Người dùng bị nghi vấn có thực hiện một trong các hành vi
                            sau: <br/>
                            <ul>
                                <li>Đăng ký tài khoản, đăng nhập, cung cấp thông tin, hồ sơ sai lệch, không trung thực,
                                    không chính xác, thiếu thông tin, dữ liệu; hoặc
                                </li>
                                <li>Đăng ký tài khoản, đăng nhập, cung cấp thông tin, hồ sơ giả mạo của cá nhân khác.
                                </li>
                            </ul>
                            <br/>
                            <span style={{fontWeight: "bold"}}
                                  data-v-c3a2a11c="">13. ĐIỀU KHOẢN CHUNG </span><br/>
                            13.1. Điều khoản này được giải thích theo và điều chỉnh bởi pháp luật Việt Nam. <br/>
                            13.2. Các mâu thuẫn phát sinh từ và liên quan đến Điều khoản này sẽ được giải quyết thông
                            qua đàm phán, thương lượng một cách thiện chí giữa DAGORAS và Người dùng để đảm bảo các
                            quyền lợi hợp pháp của mỗi bên. Nếu mâu thuẫn không được giải quyết trong vòng 60 ngày thì
                            sẽ được giải quyết tại cơ quan tài phán có thẩm quyền theo quy định pháp luật.
                        </div>
                    </div>
                    <div className="modal-footer"/>
                </div>
            </div>
        </>
    );
}

export default React.memo(ModalPrivacy);
