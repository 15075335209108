import React from 'react';
import vib from "@assets/images/vib.png";
import "./PartnerGocard.scss"
import {CustomArrowLeft, CustomArrowRight} from "@components/elements";
import Carousel from "react-multi-carousel";
import tpbank from "@assets/images/tpbank.png";
// import vpbank from "@assets/images/vpbank.png";

const PartnerGocard = () => {

    return (
        <>
            <div className="inner-partner text-center">
                <div className="label_result partner text-center mt-4">
                    <span>ĐỐI TÁC TIÊU BIỂU CỦA GOCARD</span>
                </div>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    customLeftArrow={<CustomArrowLeft/>}
                    customRightArrow={<CustomArrowRight/>}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {/*<div className="col-4 finace_item"*/}
                    {/*     style={{width: "100%", display: "inline-block"}}>*/}
                    {/*    <div className="img-finance">*/}
                    {/*        <img className="img_finace_mb" src={vpbank} alt=""/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-4 finace_item"
                         style={{width: "100%", display: "inline-block"}}>
                        <div className="img-finance">
                            <img className="img_finace_mb" src={vib} alt=""/>
                        </div>
                    </div>
                    <div className="col-4 finace_item"
                         style={{width: "100%", display: "inline-block"}}>
                        <div className="img-finance">
                            <img className="img_finace_mb" src={tpbank} alt=""/>
                        </div>
                    </div>
                </Carousel>
            </div>
        </>
    );
}

export default React.memo(PartnerGocard);
