import React, {useEffect, useState} from 'react';
import "./CardCompare.scss"
import * as data from "@assets/data/data.module.json"
import AppImage from "../../elements/AppImage/AppImage";
import {ModalAddCard} from "@components/shared";

interface CardCompareProps {
    onCompare: (id: string) => void;
    cardsCompare: any;
}

const CardCompare: React.FC<CardCompareProps> = ({onCompare, cardsCompare}) => {
    const [transform, setTransform] = useState<number>(0);
    const [isCLose, setIsClose] = useState<boolean>(false);
    const [newCardsCompare, setCardsCompare] = useState<string[]>();
    const [isModalAddCard, setIsModalAddCard] = useState<boolean>(false);

    const filterById: (id: string) => any = (id: string) => {
        return [...data.partners, ...data.otherCards].filter(card => card.id === id);
    }
    useEffect(() => {
        setCardsCompare(cardsCompare)
    }, [cardsCompare]);
    const onCloseCompare = () => {
        if (window.innerWidth <= 743) {
            setTransform(175);
            setIsClose(true);
        } else {
            setTransform(75);
            setIsClose(true);
        }
    }
    const openModal = () => {
        setIsModalAddCard(true);
    }
    const closeModal = () => {
        setIsModalAddCard(false);
        // Get the existing cardsCompare array from localStorage
        const existingCardsCompare = JSON.parse(localStorage.getItem("cardsCompare") as string) || [];
        setCardsCompare(existingCardsCompare);
    }
    const onOpenCompare = () => {
        setTransform(0);
        setIsClose(false);
    }
    const onSubmit = () => {
        // Replace the current URL with the new URL '/so-sanh-the'
        window.location.replace(`/so-sanh-the`);
    }
    return (
        <>
            <div className="compare" style={{transform: `translateY(${transform}px)`}}>
                {!isCLose &&
                <div onClick={onCloseCompare} className="close-compare text-center">
                    <i className="material-icons opacity-6 text-md">expand_more</i>
                </div>
                }
                {isCLose &&
                <div onClick={onOpenCompare} className="close-compare text-center">
                    <i className="material-icons opacity-6 text-md">expand_less</i>
                </div>
                }
                <div className="grid wide">
                    <div className="compare-imgs">
                        {newCardsCompare && newCardsCompare?.length > 0 ?
                            <div className="compare-imgs-wrapper">
                                <AppImage className={"img-item"} pathImg={filterById(newCardsCompare[0])[0].img}/>
                                <div className="close-icon" onClick={() => onCompare(newCardsCompare[0])}>
                                    <span className="icons icons-small icon-general-close"/>
                                </div>
                            </div> :
                            <div className="empty-slot" onClick={openModal}>
                                <span className="icons icons-small icon-general-plus-gray"/>
                            </div>
                        }
                        {newCardsCompare && newCardsCompare?.length > 1 ?
                            <div className="compare-imgs-wrapper">
                                <AppImage className={"img-item"} pathImg={filterById(newCardsCompare[1])[0].img}/>
                                <div className="close-icon" onClick={() => onCompare(newCardsCompare[1])}>
                                    <span className="icons icons-small icon-general-close"/>
                                </div>
                            </div> :
                            <div className="empty-slot" onClick={openModal}>
                                <span className="icons icons-small icon-general-plus-gray"/>
                            </div>
                        }
                        {newCardsCompare && newCardsCompare?.length > 2 ?
                            <div className="compare-imgs-wrapper">
                                <AppImage className={"img-item"} pathImg={filterById(newCardsCompare[2])[0].img}/>
                                <div className="close-icon" onClick={() => onCompare(newCardsCompare[2])}>
                                    <span className="icons icons-small icon-general-close"/>
                                </div>
                            </div> :
                            <div className="empty-slot" onClick={openModal}>
                                <span className="icons icons-small icon-general-plus-gray"/>
                            </div>
                        }
                    </div>
                    <span className="compare-title">
                            Bạn đã chọn <span
                        className="card-number">{newCardsCompare && newCardsCompare.length}/3</span> thẻ
                        </span>
                    <a className={`cmp-button cmp-button__primary compare-btn ${newCardsCompare && newCardsCompare.length > 1 ? '' : 'disable'}`}
                       onClick={onSubmit}>
                        Bắt đầu so sánh
                    </a>
                </div>
            </div>
            {isModalAddCard && <ModalAddCard onCompare={onCompare} closeModal={closeModal}/>}
        </>
    );
}

export default React.memo(CardCompare);
