import React from 'react';
import './AboutPageView.scss';

const AboutPageView = () => {

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-left" style={{margin: '100px 0'}}>
                        <p className="page-title font-weight-normal text-black">
                            {/*<span className="back-page" onClick={() => window.history.go(-1)}>
                                <i className="fa fa-angle-left" aria-hidden="true" /></span>*/}
                            <span className="font-size-26 svn-bold" >Thông tin giới thiệu chi tiết</span>
                        </p>
                        <div className="content font-size-14 svn-font">
                            <p className='text'>
                                GoCard phá vỡ mọi rào cản truyền thống, giúp bạn lựa chọn các sản phẩm tài chính trở nên đơn giản và nhanh chóng hơn bao giờ hết. Với mục tiêu đơn giản hoá các bước tiếp cận tài chính và cá nhân hóa trải nghiệm, GoCard luôn nỗ lực mang đến những giải pháp phù hợp cùng nhiều chương trình ưu đãi hấp dẫn, đáp ứng nhu cầu chi tiêu đa dạng của người dùng.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(AboutPageView);
