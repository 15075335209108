import React from 'react';
import './TermPageView.scss';

const TermPageView = () => (
  <div className="px-4 pt-1 overflow-y-scroll term">
    <h1 className="term-heading">Điều khoản</h1>
    <p className="term-paragraph">
      Xin vui lòng xem kỹ những quy định dưới đây trước khi Người dùng tiếp tục truy cập website. Việc truy cập vào website có nghĩa rằng
      Người dùng đồng ý với những quy định và điều kiện dưới đây. Nếu Người dùng không chấp nhận các Điều khoản này, vui lòng không
      truy cập Website. Thông tin chúng tôi sử dụng trên website chỉ dành cho mục đích tham khảo và có thể thay đổi mà không cần phải
      thông báo và không nên sử dụng chúng như những lời khuyên. GoCard.vn có thể thay đổi các quy định này và cập nhật bất cứ lúc nào,
      do đó, Người dùng nên truy cập website thường xuyên để có được thông tin mới nhất.
    </p>

    <h1 className="term-heading">Điều khoản chung</h1>
    <p className="term-paragraph">
      Website được thiết kế, tạo lập nhằm cung cấp các so sánh, đề xuất, giới thiệu cho Người dùng các thông tin về các sản phẩm tài chính,
      dịch vụ tài chính tiêu dùng của các tổ chức tín dụng, tổ chức tài chính. GoCard.vn không phải là tổ chức tín dụng, ngân hàng.
      Việc cung cấp sản phẩm thẻ tín dụng, mở tài khoản và các sản phẩm dịch vụ ngân hàng khác sẽ được thực hiện bởi các tổ chức tín dụng
      và các ngân hàng cung cấp dịch vụ.
    </p>
    <p className="term-paragraph">
      Trước khi bắt đầu sử dụng Website, Người dùng phải đọc kỹ và hiểu các Điều khoản. Bằng cách sử dụng Website hoặc cung cấp thông tin cá nhân
      cho Website, Người dùng đồng ý chấp nhận với các Điều khoản. Nếu Người dùng không đồng ý với các Điều khoản, Người dùng sẽ không được sử dụng
      Website.
    </p>
    <p className="term-paragraph">Bằng cách bấm nút “Nhận đề xuất”/trả lời câu hỏi/nhập dữ liệu cá nhân, thông tin khác trên Website, Người dùng/Quý khách xác nhận rằng,
      đã đọc, hiểu và đồng ý với nội dung của Điều khoản sử dụng và Chính sách bảo mật của Trang web.
    </p>

    <h1 className="term-heading">Xử lý dữ liệu cá nhân</h1>
    <p className="term-paragraph">
      Người dùng xác nhận đồng ý chuyển dữ liệu cá nhân của mình theo yêu cầu của
      Ngân hàng để Ngân hàng có thể đưa ra các sản phẩm/dịch vụ phù hợp.
    </p>
    <p className="term-paragraph">
      Người dùng đồng ý để Công ty chuyển dữ liệu cá nhân của mình cho (các) bên thứ ba là đối tác của Công ty để phục vụ mục đích xác minh,
      thẩm định thông tin, nâng cao chất lượng cung cấp sản phẩm/dịch vụ và/hoặc để các bên thứ ba đó hỗ trợ kết nối và cung cấp thêm các
      sản phẩm/dịch vụ tài chính khác phù hợp với Người dùng.
    </p>
    <p className="term-paragraph">
      Bằng cách trả lời các câu hỏi trên Website, Người dùng xác nhận, đồng ý việc lưu trữ,
      chuyển dữ liệu cá nhân cho các Tổ chức tín dụng, Ngân hàng.
    </p>
    <p className="term-paragraph">Người dùng hiểu rằng Công ty không chịu trách nhiệm xử lý dữ liệu của các Tổ chức tín dụng, Ngân hàng.</p>
    <p className="term-paragraph">
      Người dùng đồng ý rằng Công ty sẽ sử dụng địa chỉ nơi ở, địa chỉ email và/hoặc số điện thoại được cung cấp bởi Người dùng để gửi
      thông tin thương mại cho các sản phẩm, dịch vụ hoặc chương trình khuyến mại được cung cấp bởi Công ty hoặc các Tổ chức tín dụng, Ngân hàng.
    </p>
    <p className="term-paragraph">
      Người dùng đồng ý rằng họ có thể được liên hệ bởi bất kỳ Tổ chức tín dụng/Ngân hàng/đối tác nào trong mạng lưới đối tác của
      GoCard.vn để cung cấp các sản phẩm, dịch vụ ngân hàng khác.
    </p>
    <p className="term-paragraph">
      Dữ liệu của Người dùng sẽ được xóa theo yêu cầu của Người dùng bằng cách gửi email đến địa chỉ: …
    </p>

    <h1 className="term-heading">Quyền người dùng</h1>
    <p className="term-paragraph">
      Người dùng được truy cập, nhận đề xuất, sử dụng miễn phí các dịch vụ của GoCard.vn trên Website, trừ các sản phẩm/dịch vụ của
      Bên thứ ba, Đối tác, Tổ chức tín dụng, Ngân hàng có trên Website hoặc được liên kết với Website.
    </p>
  </div>
);

export default TermPageView;
