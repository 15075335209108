import React, {useEffect, useState} from 'react';
import "./RedirectPageView.scss";
import mbbank from "@assets/images/mbbank.png";
import vpbank from "@assets/images/vpbank.png";

const MoTaiKhoanPageView = () => {
    const [pValue, setPValue] = useState<string | null>('');
    useEffect(() => {
        setPValue(localStorage.getItem("pValue"));
    }, [])
    return (
        <>
            <div className="redirect-bg-open-account text-center">
                <div className="content-redirect">
                    <div className="header-redirect-title">
                        <span>Cảm ơn bạn đã đăng ký mở thẻ tín dụng trên GoCard. </span>
                        <span>Thông tin đăng ký của bạn đã được ghi nhận</span>
                    </div>
                    <div className="sub-title">
                        <span>Để nâng cao khả năng được phê duyệt thẻ tín dụng hạn mức lớn </span>
                        <span>bạn có thể đăng ký mở tài khoản ngay tại:</span>
                    </div>
                    <div className="merchant-img-redirect">
                        <a target="_blank"
                           href={`https://partner.dagoras.io/gocard/mbank?p=${pValue}`}>
                            <div className="col-4 finace_item"
                                 style={{width: "100%", display: "inline-block"}}>
                                <div className="img-finance">
                                    <img className="img_finace_mb" src={mbbank} alt=""/>
                                </div>
                            </div>
                        </a>
                        <a target="_blank"
                           href={`https://partner.dagoras.io/gocard/vpbankaccount?p=${pValue}`}>
                            <div className="col-4 finace_item"
                                 style={{width: "100%", display: "inline-block"}}>
                                <div className="img-finance">
                                    <img className="img_finace_mb" src={vpbank} alt=""/>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
};
export default React.memo(MoTaiKhoanPageView);
