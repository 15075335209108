import React, {useEffect, useState} from 'react';
import arrow from '../../../../assets/images/arrow.png';
import {useLocation} from "react-router-dom";
import {images} from '@assets/images';

interface PropsCard {
    cardItem: any
}

const SuccessPageView = () => {
    const location = useLocation<PropsCard>();
    let {cardItem} = location.state || {};

    // If cardItem is null, retrieve it from localStorage
    if (!cardItem) {
        const storedCardData = localStorage.getItem('cardData');
        if (storedCardData) {
            cardItem = JSON.parse(storedCardData);
        }
    }

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const duration = 5000; // 5 seconds
        const intervalTime = 50; // Update progress every 50 milliseconds
        const totalSteps = duration / intervalTime; // Total steps required to reach 100%
        const increment = 100 / totalSteps; // Increment progress by this value every interval

        // Increment progress every 50 milliseconds
        const interval = setInterval(() => {
            setProgress((prevProgress) => Math.min(prevProgress + increment, 100));
        }, intervalTime);

        // Redirect exactly after 5 seconds
        const timer = setTimeout(() => {
            if (cardItem?.navigateLink) {
                window.location.href = cardItem.navigateLink;

                localStorage.removeItem('cardData');
            }
        }, duration);

        // Cleanup on component unmount
        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        };
    }, [cardItem]);

    return (
        <>
            <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    height: '100vh',
                    backgroundColor: cardItem?.partner === 'VPBank' ? '#fff' : cardItem?.partner === 'VIB' ? '#fff' : '#d9fc00',
                    width: '100vw',
                }}
            >
                <div className="d-flex flex-row align-items-center justify-content-evenly w-70">
                    <img src={images.gocard.logoSuccess} alt="logo"
                         style={{
                             width: '72px',
                             height: '72px'
                         }}
                    />
                    <img src={arrow} alt="arrow" style={{width: '19px', height: '12px'}}/>
                    <img src={cardItem?.iconUrl} alt="icon"
                         style={{
                             width: cardItem?.partner === 'VPBank' ? '155px' : cardItem?.partner === 'VIB' ? '74px' : '72px',
                             height: cardItem?.partner === 'VPBank' ? '36px' : cardItem?.partner === 'VIB' ? '36px' : '72px',
                             backgroundColor: 'white',
                             objectFit: 'contain',
                             borderRadius: cardItem?.partner === 'VPBank' ? '0' : cardItem?.partner === 'VIB' ? '0' : '50%',
                         }}/>
                </div>
                <div className="progress" style={{width: '80%', marginTop: '20px'}}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{width: `${progress}%`, backgroundColor: '#007bff'}}
                        aria-valuenow={progress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    />
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center my-5 mx-3">
                    <p className="m-0">
                        Xử lý yêu cầu thành công!
                    </p>
                    <p className="text-center m-0">
                        Hệ thống sẽ tự động chuyển hướng đến trang website của <span
                        className="fw-bold">{cardItem?.partner}</span> trong
                        5s
                    </p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center position-relative">
                    <img
                        src={cardItem?.imageUrl}
                        alt="success"
                        style={{
                            width: '5rem', position: 'absolute', top: '0.5rem', left: '2.7rem',
                        }}
                    />
                    <img src={images.gocard.success} alt="success" style={{width: '20rem'}}/>
                </div>
            </div>
        </>
    );
}

export default React.memo(SuccessPageView);
