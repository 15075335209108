import React from 'react';
import "./Guide.scss"
import step1 from "@assets/images/step1.png";
import step2 from "@assets/images/step2.png";
import step3 from "@assets/images/step3.png";
import step4 from "@assets/images/step4.png";
import step5 from "@assets/images/step5.png";

const Guide = () => {

    return (
        <>
            <div className="client-report row justify-content-center mt-4">
                <div className="row col-12 col-md-11 col-lg-10">

                    <div className="guide-card">
                        <div className="label_result partner text-center mt-4">
                            <span>5 BƯỚC MỞ THẺ TÍN DỤNG DÙNG NGAY</span>
                        </div>
                        <div className="main-container mt-6">
                            <div className="steps-container">
                                <div className="step completed">
                                    <span>1</span>
                                    <div className="label completed">
                                        Nhập thông tin yêu cầu của bạn về thẻ tín dụng
                                    </div>
                                    <div className="icon completed">
                                        <img src={step1} alt="step1" style={{height: "150px"}}/>
                                    </div>
                                </div>
                                <div className="line completed"/>
                                <div className="step completed second">
                                    <span>2</span>
                                    <div className="label completed">
                                        Gợi ý, so sánh và lựa chọn thẻ tín dụng
                                    </div>
                                    <div className="icon completed" style={{left: "-34px"}}>
                                        <img src={step2} alt="step1" style={{height: "150px"}}/>
                                    </div>
                                </div>
                                <div className="line completed"/>
                                <div className="step completed third">
                                    <span>3</span>
                                    <div className="label completed">
                                        Đăng ký thẻ tín dụng và chờ hướng dẫn hoàn tất thủ tục
                                    </div>
                                    <div className="icon completed" style={{left: "-35px"}}>
                                        <img src={step3} alt="step1" style={{height: "150px"}}/>
                                    </div>
                                </div>
                                <div className="line completed"/>
                                <div className="step completed fourth">
                                    <span>4</span>
                                    <div className="label completed">
                                        Cung cấp đủ thủ tục cần thiết và hoàn tất hợp đồng
                                    </div>
                                    <div className="icon completed" style={{left: "-41px"}}>
                                        <img src={step4} alt="step1" style={{height: "150px"}}/>
                                    </div>
                                </div>
                                <div className="line completed"/>
                                <div className="step completed last">
                                    <span>5</span>
                                    <div className="label completed">
                                        Sau khi hoàn tất, ngân hàng sẽ gửi bạn thẻ tín dụng vật lý
                                    </div>
                                    <div className="icon completed" style={{left: "-41px"}}>
                                        <img src={step5} alt="step1" style={{width: "150px"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(Guide);
