import React, {useEffect, useState} from 'react';
import axiosInstance from "@utils/axiosInstance";
import {IPromotion} from "@types";
import {Pagination, PaginationProps, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {map} from "lodash";
import {formatDate} from "@utils/constants";

const PromotionsCardsGroup = ({code}: any) => {
    const [promotions, setPromotions] = useState<IPromotion[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState({pageNum: 0, pageSize: 9})
    const [totalElements, setTotalElements] = useState<number>(0);

    useEffect(() => {
        const fetchDataAndScrollToTop = async () => {
            await window.scrollTo({top: 200, behavior: 'smooth'});
            setTimeout(() => {
                onSearch();
            }, 500); // Adjust the delay as needed
        };

        fetchDataAndScrollToTop().then(r => console.log(r));
    }, [search, code]);
    const onSearch = () => {
        setLoading(true);
        axiosInstance.get(`/promotion/group?pageNum=${search.pageNum}&pageSize=${search.pageSize}&type=${code}`).then(response => {
            if (response.data.code === '200') {
                setTotalElements(response.data.data.totalElements)
                setPromotions(response.data.data.content)
                setLoading(false);
            }
        })
    }
    const onChange: PaginationProps['onChange'] = (pageNumber: number) => {
        setSearch((prevSearch) => ({
            ...prevSearch,
            pageNum: pageNumber - 1
        }));
    };
    return (
        <>
            <div className="row">
                {loading ? <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/> :
                    <>
                        {map(promotions, (promotion, index) => (
                            <div key={index} className="col-md-4 col-sm-6 col-12">
                                <div
                                    className="eael-filter-gallery-container eael-filter-gallery-grid">
                                    <div
                                        className="eael-filterable-gallery-item-wrap eael-cf-mua-sắm">
                                        <div className="eael-gallery-grid-item">
                                            <a href={"/uu-dai/" + promotion.slug}
                                               style={{
                                                   position: 'relative',
                                                   overflow: 'hidden',
                                                   display: 'block',
                                               }}>
                                                <div className="gallery-item-thumbnail-wrap"><img
                                                    src={promotion.image}
                                                    alt={promotion.title} className="gallery-item-thumbnail"/>
                                                </div>
                                            </a>
                                            <div
                                                className="gallery-item-caption-wrap caption-style-card ">
                                                <div className="gallery-item-caption-over"><h5
                                                    className="fg-item-title">{promotion.title}</h5>
                                                    <div className="fg-item-content"><p>Ngày hết
                                                        hạn: {formatDate(promotion.expiredAt)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>
            {totalElements > search.pageNum && <div className="pagination-wrap pagination-slider">
                <Pagination current={search.pageNum + 1} total={totalElements} onChange={onChange}/>
            </div>}

        </>
    );
};

export default React.memo(PromotionsCardsGroup);
