import React, { useEffect, useState } from 'react';
import "./DetailPromotionPageView.scss"
import "@assets/css/landing-page.css"
import {
    CardCity
} from "../../shared";
import free from "@assets/images/label-free.png"
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import { useLocation } from "react-router-dom";
import axiosInstance from "@utils/axiosInstance";
import { IPromotion } from "@types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { formatDate } from "@utils/constants";
import { map } from 'lodash';

const DetailPromotionPageView = () => {
    const location = useLocation();
    const slug = location.pathname.split("/")[2];
    const [loading, setLoading] = useState<boolean>(false);
    const [promotion, setPromotion] = useState<IPromotion>({
        btnUrl: "",
        contents: [],
        createdBy: "",
        createdDate: "",
        deleted: false,
        description: "",
        expiredAt: "",
        id: "",
        image: "",
        rule: "",
        slug: "",
        time: "",
        title: "",
        type: "",
        updatedBy: "",
        updatedDate: "",
        url: ""
    });
    const [promotions, setPromotions] = useState<IPromotion[]>([]);
    useEffect(() => {
        getPromotion();
        getPromotions();
    }, []);
    const getPromotion = () => {
        axiosInstance.get(`/promotion/findBySlug/${slug}`).then(response => {
            if (response.data.code === '200') {
                setPromotion(response.data.data)
                document.title = response.data.data.title;
                setLoading(false);
            }
        })
    }
    const getPromotions = () => {
        axiosInstance.get(`/promotion/findByOtherSlug/${slug}`).then(response => {
            if (response.data.code === '200') {
                setPromotions(response.data.data)
            }
        })
    }
    return (
        <>
            <div className="container-fluid detail-promotion">
                <div className="row">
                    <main className="col-md-12 ms-sm-auto col-lg-12">
                        <div className="row justify-content-center">

                            <div className="" style={{ backgroundColor: "#fff" }}>
                                <div className="section-promotion-detail vib-v2-section-promotion-detail">
                                    <div className="vib-v2-wrapper_new">
                                        <a href="" onClick={() => window.history.go(-1)}
                                            className="vib-v2-back-brand-car new-style-link">
                                            Trở về
                                        </a>
                                        {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> :
                                            <>
                                                <div className="vib-v2-content-promotion-detail">
                                                    <div className="vib-v2-header-world-detail">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div
                                                                    className="vib-v2-title-promotion-detail title-1">{promotion?.title}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="vib-v2-date-promotion">
                                                            <h4>Ngày hết hạn: {formatDate(promotion?.expiredAt)}</h4>
                                                        </div>
                                                        <div className="vib-v2-btn-share-promotion">
                                                            <a href={promotion.btnUrl}
                                                                style={{
                                                                    width: "130px",
                                                                    marginBottom: "10px",
                                                                    marginLeft: 0
                                                                }}
                                                                target="_blank"
                                                                className="dg-card-v2-btn-dk01">Đăng ký ngay&nbsp;</a>
                                                        </div>
                                                    </div>
                                                    <div className="vib-v2-body-world-detail bg-white-shadow">
                                                        <div className="row">
                                                            <div className="col vib-v2-left-body-world-detail">
                                                                <b>Tên chương
                                                                    trình: <br /></b> {promotion.title}<br /><br />
                                                                <b>Thời gian khuyến mãi: </b> <br />{promotion.time}<br /><br />
                                                                {promotion.rule &&
                                                                    <>
                                                                        <b>Thể lệ: </b> <br />
                                                                        <div
                                                                            dangerouslySetInnerHTML={{ __html: promotion.rule }} />
                                                                        <br />
                                                                    </>}
                                                                {promotion.contents &&
                                                                    <>
                                                                        <b>Chi tiết ưu đãi:</b><br />
                                                                        {map(promotion.contents, (content, index) => (
                                                                            <div key={index}
                                                                                dangerouslySetInnerHTML={{ __html: content.content }} />
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="col-auto vib-v2-right-body-world-detail">
                                                                <img
                                                                    src={promotion.image}
                                                                    alt={promotion.title} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="vib-v2-promotion-list vib-v2-promotion-favourite">
                                    <div className="vib-v2-wrapper_new">
                                        <div className="vib-v2-title-news title-1">Ưu đãi khác</div>
                                        <div className="row" style={{ marginLeft: "-11px", marginRight: "-10px" }}>
                                            {map(promotions, (promotion, index) => (
                                                <>
                                                    <div key={index} className="col-md-4 col-sm-6 col-12">
                                                        <div
                                                            className="eael-filter-gallery-container eael-filter-gallery-grid">
                                                            <div
                                                                className="eael-filterable-gallery-item-wrap eael-cf-mua-sắm">
                                                                <div className="eael-gallery-grid-item">
                                                                    <a href={"/uu-dai/" + promotion.slug}
                                                                        style={{
                                                                            position: 'relative',
                                                                            overflow: 'hidden',
                                                                            display: 'block',
                                                                        }}>
                                                                        <div className="gallery-item-thumbnail-wrap">
                                                                            <img
                                                                                src={promotion.image}
                                                                                alt={promotion.title}
                                                                                className="gallery-item-thumbnail" />
                                                                        </div>
                                                                    </a>
                                                                    <div
                                                                        className="gallery-item-caption-wrap caption-style-card ">
                                                                        <div className="gallery-item-caption-over"><h5
                                                                            className="fg-item-title">{promotion.title}</h5>
                                                                            <div className="fg-item-content"><p>Ngày hết
                                                                                hạn: {formatDate(promotion.expiredAt)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="tu-van-card">
                            <div className="top-tu-van d-flex align-items-center justify-content-center">
                                <span className="top-title-tu-van">Quá nhiều thẻ tín dụng không biết nên chọn thẻ tín dụng nào ?</span>
                                <button onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }
                                } className="dg-card-v2-btn-dk01"
                                    style={{ backgroundColor: "#FFA20C" }}>
                                    <span>Tư vấn ngay</span>
                                    <img className="default-label" src={free} alt="free" />
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollToTopButton />
            </div>

            <CardCity />
        </>
    );
};

export default React.memo(DetailPromotionPageView);
