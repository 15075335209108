import React, {useEffect, useRef} from 'react';
import "./CardCity.scss";
import * as data from "@assets/data/data.module.json"
import eclipse from "@assets/images/eclipse.png"
import gsap from "gsap";
import Draggable from "gsap/Draggable"
import $ from 'jquery';
import {map} from 'lodash';
import AppImage from "../../elements/AppImage/AppImage";

const CardCity = () => {
    const hoveringRef = useRef(false);
    useEffect(() => {
        gsap.registerPlugin(Draggable);

        const MAX_DUPE_TIMES = 2;

        const oriItemHTML = $('.cards').html();
        for (let i = 0; i < MAX_DUPE_TIMES; i++) {
            $('.cards').html($('.cards').html() + oriItemHTML);
        }

        gsap.set('.cards .item', {
            xPercent: 400,
            opacity: 0,
            scale: 0,
        });

        const spacing = 0.1;
        const cards = gsap.utils.toArray('.cards .item');

        const animateFunc = (element: any) => {
            const tl = gsap.timeline();
            tl.fromTo(
                element,
                {
                    scale: 1,
                    opacity: 1,
                },
                {
                    scale: 1,
                    opacity: 1,
                    zIndex: 100,
                    duration: 0.5,
                    yoyo: true,
                    repeat: 1,
                    ease: 'power1.in',
                    immediateRender: false,
                }
            ).fromTo(
                element,
                {
                    xPercent: 600,
                },
                {
                    xPercent: -600,
                    duration: 1,
                    ease: 'none',
                    immediateRender: false,
                },
                0
            );
            return tl;
        };

        const seamlessLoop = buildSeamlessLoop(cards, spacing, animateFunc);

        const playhead = {
            offset: 0,
        };

        const wrapTime = gsap.utils.wrap(0, seamlessLoop.duration());

        const scrub = gsap.to(playhead, {
            offset: 0,
            onUpdate() {
                seamlessLoop.time(wrapTime(playhead.offset));
            },
            duration: 0.8,
            ease: 'power3',
            paused: true,
        });

        function buildSeamlessLoop(items: any, spacing: number, animateFunc: any) {
            let rawSequence = gsap.timeline({
                paused: true,
            });
            let seamlessLoop = gsap.timeline({
                paused: true,
                repeat: -1,
                onRepeat() {
                    this._time === this._dur && (this._tTime += this._dur - 0.01);
                },
                onReverseComplete() {
                    this.totalTime(this.rawTime() + this.duration() * 100);
                },
            });
            const cycleDuration = 0.1 * items.length;
            let dur: number = 0;

            const oriItems = items;
            for (let i = 0; i < MAX_DUPE_TIMES; i++) {
                items = items.concat(oriItems);
            }

            items.forEach((_: any, i: number) => {
                let anim = animateFunc(items[i % items.length]);
                rawSequence.add(anim, i * spacing);
                dur || (dur = anim.duration());
            });

            seamlessLoop.fromTo(
                rawSequence,
                {
                    time: cycleDuration + dur / 2,
                },
                {
                    time: `+=${cycleDuration}`,
                    duration: cycleDuration,
                    ease: 'none',
                }
            );
            return seamlessLoop;
        }

        function autoScroll() {
            setTimeout(function () {
                if (!hoveringRef.current) {
                    scrub.vars.offset = scrub.vars.offset + 0.002;
                    scrub.invalidate().restart();
                }
                autoScroll();
            }, 100);
        }

        autoScroll();

        $('.scrollsection .box').hover(
            function () {
                $('.vib-scroll-animations').addClass('stop-scroll');
                hoveringRef.current = true;
            },
            function () {
                $('.vib-scroll-animations').removeClass('stop-scroll');
                hoveringRef.current = false;
            }
        );
        $('.scrollsection .box').hover(
            function () {
                hoveringRef.current = true;
            }, function () {
                hoveringRef.current = false;
            }
        );
        const draggable = Draggable.create('.drag-proxy', {
            type: 'x',
            trigger: '.cards',
            onPress() {
                this.startOffset = scrub.vars.offset;
                hoveringRef.current = true;
            },
            onDrag() {
                scrub.vars.offset = this.startOffset + (this.startX - this.x) * 0.0004;
                scrub.invalidate().restart();
            },
            onDragEnd() {
                hoveringRef.current = false;
            },
        });

        return () => {
            draggable.forEach((item) => item.kill());
        };

    }, []);

    return (
        <>

            <div className="vib-banner-bottom" id="vib_scroll_bottom_bar">
                <div className="label_result partner text-center mt-4">
                    <span>THÀNH PHỐ THẺ GOCARD</span>
                </div>
                <div className="content">
                    <div className="vib-scroll-animations">
                        <div className="wrap">
                            <div className=" block-loop-scroll gallery" id="gallery">
                                <div className="scrollsection cards"
                                     style={{
                                         touchAction: 'pan-y',
                                         cursor: 'grab',
                                         userSelect: 'none'
                                     }}>
                                    {map(data.partners.sort(() => Math.random() - 0.5).slice(0, 8), (card, index: number) => (
                                        <div key={index} className={`item ${index % 2 !== 0 ? 'big' : ''} item-tablet`}
                                             style={{
                                                 touchAction: 'pan-y',
                                                 opacity: 1,
                                                 zIndex: 8
                                             }}>
                                            <div className="image -active" style={{touchAction: 'pan-y'}}>
                                                <div className="box" style={{touchAction: 'pan-y'}}>
                                                    <a href={`chi-tiet-the/${card.id}`}
                                                       style={{touchAction: 'pan-y'}}>
                                                        <AppImage className="image -active" pathImg={card.img}/>
                                                        <img className="icon-epclise" src={eclipse}
                                                             style={{touchAction: 'pan-y'}} alt="eclipse"/>
                                                    </a>
                                                    <div className="info" style={{touchAction: 'pan-y'}}>
                                                        <div className="vib-v2-news-best"
                                                             style={{touchAction: 'pan-y'}}>{card.label}
                                                        </div>
                                                        <a href={`chi-tiet-the/${card.id}`}
                                                           style={{touchAction: 'pan-y'}}><p
                                                            style={{touchAction: 'pan-y'}}>Hạn mức {card.han_muc}</p>
                                                        </a>
                                                        <a href={`chi-tiet-the/${card.id}`}
                                                           className="vib-v2-btn-dk01"
                                                           style={{touchAction: 'pan-y'}}>Xem chi tiết</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                </div>
                                <div className="drag-proxy"
                                     style={{
                                         transform: 'translate3d(0px, 0px, 0px)',
                                         zIndex: 1003
                                     }}/>
                            </div>


                        </div>


                    </div>

                </div>


            </div>
        </>
    );
}

export default React.memo(CardCity);
