import React from 'react';
import "./Select.scss";
import warning from "@assets/images/icon-warning.png";
import {Select2, Select2Data, Select2UpdateValue} from "select2-react-component";

interface SelectProps {
    placeholder: string;
    onSelect: (event: Select2UpdateValue) => void;
    value?: string;
    required: boolean;
    image?: any;
    label?: string;
    error: string;
    data: Select2Data
}

const Select: React.FC<SelectProps> = ({
                                           placeholder,
                                           onSelect,
                                           required,
                                           image,
                                           label,
                                           value,
                                           error,
                                           data
                                       }) => {
        return (
            <>
                <div className={`input-group input-group-outline ${error ? 'mb-5' : 'mb-3'} ${value ? 'is-focused' : ''}`}>
                    {image && <img src={image} alt="icon"/>}
                    <label className="form-label-1">{label} {required &&
                    <span>(<span className="fire_engine_red">*</span>)</span>}
                    </label>
                    <Select2 placeholder={placeholder}
                             update={onSelect}
                             value={value}
                             data={data}/>
                    {error &&
                    <p className="error_show" style={{marginTop: "7.5px"}}>
                        <img src={warning} alt={"warning"}/>
                        {error}
                    </p>
                    }
                </div>
            </>
        )
    }
;

export default React.memo(Select);
