import React, { useEffect } from 'react';
import './PrivacyPageView.scss';

const PrivacyPageView = () => {
    useEffect(() => {
        document.title = "Chính sách và quyền riêng tư";
    }, []);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-left">
                        <p className="page-title font-weight-normal text-black">
                            {/*<span className="back-page" onClick={() => window.history.go(-1)}>
                                <i className="fa fa-angle-left" aria-hidden="true" /></span>*/}
                            <span className="font-size-26 svn-bold" >  Chính sách và quyền riêng tư</span>
                        </p>
                        <hr />
                        <div className="content-terms">
                            <p className="lh-120">
                                Chính sách bảo mật này thể hiện cam kết của chúng tôi về quyền được bảo mật thông tin
                                riêng
                                tư đối với Quý khách và người dùng của Trang web này. Chính sách bảo mật này quyết định
                                cách
                                thức Công ty cổ phần công nghệ tài chính Dagoras sử dụng, duy trì và quản lý thông tin
                                được
                                thu thập từ Quý khách và người dùng Trang web của mình.
                            </p>

                            <h2>Cam kết của GoCard.vn</h2>
                            <p className="lh-120">
                                GoCard.vn chịu trách nhiệm bảo mật thông tin về dữ liệu của Quý khách và tuyệt đối không
                                tiết
                                lộ cho bất kỳ bên thứ ba nào khác ngoại trừ yêu cầu của cơ quan có thẩm quyền theo quy
                                định
                                của pháp luật, hoặc có sự đồng ý của Khách hàng. GoCard.vn sẽ thực hiện các bước cần
                                thiết để
                                đảm bảo lưu trữ an toàn thông tin cá nhân Quý khách cung cấp cho công ty. GoCard.vn
                                không
                                chịu trách nhiệm về các thất thoát dữ liệu, bí mật thông tin của Khách hàng do Khách
                                hàng vô
                                tình hoặc cố ý gây ra.
                            </p>

                            <h2>Thu thập thông tin</h2>
                            <p className="lh-120">
                                Thông tin cá nhân từ người dùng sẽ được Trang web ghi nhận, thu thập trực tuyến khi
                                người
                                dùng đăng ký tài khoản sử dụng dịch vụ, sử dụng dịch vụ, tương tác trên Trang web hay
                                trong
                                các trường hợp khác mà người dùng được mời để tình nguyện cung cấp thông tin, chủ động,
                                trực
                                tiếp cung cấp thông tin cho GoCard.vn, thông tin có được từ Bên thứ ba, thông tin được
                                tự
                                động lưu trữ dữ liệu về người dùng, cũng như dữ liệu về máy tính/ thiết bị, thông tin
                                thu
                                thập từ Cookies (Cookies là các tệp dữ liệu được lưu trữ trên ổ cứng của người dùng.
                                Chúng
                                tôi có thể sử dụng Cookies theo phiên (sẽ hết hạn sau khi bạn đóng trình duyệt web) và
                                Cookies liên tục (vẫn còn lưu trên máy tính của người dùng cho đến khi được xóa) để cung
                                cấp
                                cho người dùng trải nghiệm tốt nhất).
                            </p>
                            <p className="lh-120">
                                Dữ liệu GoCard.vn thu thập là thông tin nhận dạng cá nhân, tất cả thông tin về sản phẩm
                                và
                                các số liệu liên quan đến sản phẩm, dịch vụ, chẳng hạn như: tên, địa chỉ bưu chính, địa
                                chỉ
                                email, số điện thoại, thông tin nhân khẩu học (giới tính và nghề nghiệp), …
                            </p>
                            <h2>Mục đích thu thập, sử dụng</h2>
                            <p className="lh-120">
                                Tất cả các thông tin này được sử dụng với mục đích:
                            </p>
                            <ol>
                                <li>
                                    Nhằm cung cấp cho khách hàng các thông tin, nghiên cứu, so sánh, thống kê, kết luận,
                                    đề
                                    xuất về các sản phẩm,dịch vụ ngân hàng cho khách hàng;
                                </li>
                                <li>
                                    Nhằm cung cấp các sản phẩm và dịch vụ mà Quý khách yêu cầu;
                                </li>
                                <li>
                                    Nhằm thu thập, cung cấp và sử dụng những thông tin (bao gồm thông tin cá nhân
                                    và thông tin không phải là thông tin cá nhân) cho mục đích tiếp thị và nghiên
                                    cứu thị trường của GoCard.vn và các văn phòng đại diện, địa điểm kinh
                                    doanh, chi nhánh, các công ty trong hệ sinh thái, các công ty liên kết, đối tác
                                    và công ty trực thuộc của GoCard.vn;
                                </li>
                                <li>
                                    Nhằm liên lạc với Khách hàng và/hoặc người dùng Trang web của GoCard.vn; trả lời các
                                    câu
                                    hỏi của Khách hàng; gửi thông tin: bao gồm xác nhận,
                                    hóa đơn, thông báo kỹ thuật, cập nhật, cảnh báo bảo mật, hỗ trợ và quản trị;
                                    liên lạc về các chương trình khuyến mại, sự kiện và các tin tức khác về sản phẩm
                                    và dịch vụ do GoCard.vn và các Đối tác cung cấp;
                                </li>
                                <li>
                                    Nhằm vận hành, duy trì và cải thiện các Trang web, sản phẩm và dịch vụ của
                                    GoCard.vn;
                                </li>
                                <li>
                                    Nhằm đáp ứng yêu cầu báo cáo theo quy định pháp luật hoặc cơ quan có thẩm quyền,
                                    Đối tác của GoCard.vn;
                                </li>
                                <li>
                                    Nhằm bảo vệ, điều tra và ngăn chặn các hoạt động gian lận, trái phép hoặc bất
                                    hợp pháp;
                                </li>
                                <li>
                                    Nhằm báo cáo thống kê và nghiên cứu;
                                </li>
                                <li>
                                    Nhằm thực hiện các mục tiêu khác có liên quan theo điểm (1) đến (8) ở trên.
                                </li>
                            </ol>

                            <h2>Chia sẻ thông tin cá nhân</h2>
                            <p className="lh-120">
                                Chúng tôi có thể chia sẻ thông tin cá nhân của người dùng theo các điều sau:
                            </p>
                            <ul>
                                <li>
                                    Chia sẻ thông tin cá nhân với sự đồng ý của người dùng. Ví dụ: Người dùng có
                                    thể cho phép chúng tôi chia sẻ thông tin cá nhân với các bên liên quan để sử
                                    dụng với mục đích quảng bá/tiếp thị sản phẩm dịch vụ cho Người dùng. Chia sẻ
                                    thông tin cho Đối tác, tổ chức tín dụng nhằm thực hiện yêu cầu của Người dùng.
                                    Hoạt động này phải được tuân theo chính sách bảo mật của các bên liên quan.
                                </li>
                                <li>
                                    Chia sẻ thông tin cá nhân khi chúng tôi thực hiện một thỏa thuận kinh doanh
                                    hoặc đàm phán một thỏa thuận kinh doanh liên quan đến việc bán hoặc chuyển
                                    nhượng tất cả, hoặc một phần của doanh nghiệp, hoặc tài sản của chúng tôi. Các
                                    giao dịch này có thể bao gồm bất kỳ giao dịch hoặc sáp nhập, tài trợ, mua lại
                                    hoặc thủ tục phá sản.
                                </li>
                                <li>
                                    Chia sẻ thông tin cá nhân cho các mục đích pháp lý, bảo vệ hoặc bảo mật.
                                </li>
                                <li>
                                    Chia sẻ thông tin để tuân thủ Luật pháp, đáp ứng các yêu cầu pháp lý và quy
                                    trình pháp lý.
                                </li>
                                <li>
                                    Chia sẻ thông tin để bảo vệ quyền và tài sản của Công ty cổ phần công nghệ tài
                                    chính Dagoras, đại lý, Quý khách, Đối tác và các bên liên quan.
                                </li>
                                <li>
                                    Chia sẻ thông tin trong trường hợp khẩn cấp. Điều này bao gồm bảo vệ sự an toàn
                                    của nhân viên và đại lý, Đối tác hoặc bất kỳ các bên liên quan.
                                </li>
                                <li>
                                    Chia sẻ dữ liệu tổng hợp và/hoặc ẩn danh với các bên liên quan.
                                </li>
                            </ul>

                            <h2>An toàn thông tin </h2>
                            <p className="lh-120">
                                GoCard.vn triển khai nhiều biện pháp để đảm bảo dữ liệu cá nhân của Quý khách
                                được bảo vệ khỏi những truy cập, xử lý hoặc xóa bỏ trái phép.
                            </p>

                            <h2>Truy cập và cập nhật thông tin</h2>
                            <p className="lh-120">
                                Các cập nhật, chỉnh sửa, thay đổi và xoá thông tin như trên sẽ không ảnh hưởng đến
                                các thông tin khác trên hệ thống của chúng tôi, cũng như các thông tin mà chúng tôi
                                đã cung cấp cho bên thứ ba theo Chính sách quyền riêng tư này trước khi thực hiện
                                cập nhật, chỉnh sửa, hoặc thay đổi. Để bảo vệ quyền riêng tư và bảo mật, chúng tôi
                                thực hiện các bước cần thiết (chẳng hạn như yêu cầu mật khẩu dạng duy nhất) để xác
                                minh danh tính của Quý khách trước khi cấp cho Người có quyền truy cập hồ sơ hoặc
                                thực hiện các chỉnh sửa. Quý khách có trách nhiệm không tiết lộ mật khẩu cũng như
                                thông tin tài khoản trong suốt quá trình sử dụng.
                            </p>
                            <p className="lh-120">
                                Trên thực tế, chúng tôi không thể xóa hết tất cả các thông tin mà Quý khách đã cung
                                cấp khỏi hệ thống. Việc sao lưu thông tin trên hệ thống diễn ra thường xuyên và là
                                điều cần thiết để tránh bỏ sót thông tin, đồng nghĩa với việc thông tin của Quý
                                khách tồn tại ở dạng không thể xoá hoặc rất khó để xác định.
                            </p>
                            <p className="lh-120">
                                Ngay sau khi nhận được yêu cầu của Quý khách, tất cả thông tin cá nhân được lưu trữ
                                trong cơ sở dữ liệu mà chúng tôi có quyền sử dụng, và các thông tin có thể tìm kiến
                                dễ dàng sẽ được cập nhật, sửa chữa, thay đổi hoặc xóa, trong phạm vi và khả năng cho
                                phép của chúng tôi.
                            </p>
                            <p className="lh-120">
                                Nếu Quý khách muốn cập nhật, xóa hoặc truy xuất bất kỳ thông tin nào, Quý khách có
                                thể liên hệ với chúng tôi qua Fanpage: <a target="_blank"
                                    href="/">GoCard.vn</a> để
                                làm theo hướng dẫn hoặc liên hệ với tổ chức tài chính mà Quý khách là khách hàng của họ.
                            </p>

                            <h2>Bản quyền và thương hiệu</h2>
                            <p className="lh-120">
                                Mọi thông tin và hình ảnh trên Website này đều thuộc quyền sở hữu của GoCard.vn, ngoại
                                trừ
                                những thông tin và hình ảnh về các đối tác, tổ chức tín dụng, Bên
                                thứ ba khác. Mọi hình thức sao chép, sử dụng hay phát tán những thông tin này đều
                                không được phép, trừ khi được GoCard.vn đồng ý trước bằng văn bản.
                            </p>

                            <h2>Thay đổi chính sách riêng tư</h2>
                            <p className="lh-120">
                                Chúng tôi có thể thay đổi chính sách bảo mật này mà không cần thông báo trước. Nếu
                                chúng tôi thay đổi, chúng tôi sẽ thay đổi ngày cập nhật mới nhất.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(PrivacyPageView);
