import React, { useEffect, useState } from 'react';
import { Breadcrumb, Layout, Card, Row, Col, Spin } from 'antd';
import { useParams, useHistory, Link } from 'react-router-dom';
import publicService from "../../../service/PublicService";

const { Content } = Layout;

interface Blog {
    id: number;
    title: string;
    imageUrl: string;
}

interface Category {
    id: number;
    name: string;
    acronymLink: string;
}

interface RouteParams {
    acronymLink?: string;
}

const BlogPageView: React.FC = () => {
    const { acronymLink } = useParams<RouteParams>();
    const history = useHistory();
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchCategories = async () => {
        try {
            const response = await publicService.findAllCategory();
            setCategories(response.data);
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu categories:", error);
        }
    };

    const fetchBlogs = async () => {
        setLoading(true);
        try {
            if (acronymLink) {
                const resp = await publicService.getBlogsByCategory(acronymLink);
                setBlogs(resp.data);
            } else {
                const resp = await publicService.findAllBlog();
                setBlogs(resp.data);
            }
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu blogs:", error);
            setBlogs([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        fetchBlogs();
    }, [acronymLink]);

    const handleBlogClick = (blogId: number) => {
        history.push(`/blog-detail/${blogId}`);
    };

    return (
        <>
            <div className="container">
                <Spin spinning={loading}>
                    <Layout>
                        <Content style={{ padding: '0 10px' }}>
                            {/* Breadcrumb Navigation */}
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/">Trang chủ</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to="/blog">Danh mục</Link>
                                </Breadcrumb.Item>
                                {acronymLink && (
                                    <Breadcrumb.Item>
                                        {categories.find(category => category.acronymLink === acronymLink)?.name || "Category"}
                                    </Breadcrumb.Item>
                                )}
                            </Breadcrumb>

                            {/* Danh sách các liên kết category */}
                            <div style={{ margin: '16px 0' }}>
                                {categories.map(category => (
                                    <Link
                                        key={category.id}
                                        to={`/blog/${category.acronymLink}`}
                                        className={acronymLink === category.acronymLink ? 'active' : ''}
                                        style={{ marginRight: '20px' }}
                                    >
                                        {category.name}
                                    </Link>
                                ))}
                            </div>

                            {/* Danh sách blogs */}
                            <div style={{ margin: '24px 0' }}>
                                <Row gutter={[16, 16]}>
                                    {blogs.length > 0 ? (
                                        blogs.map(blog => (
                                            <Col span={12} key={blog.id}>
                                                <Card
                                                    hoverable
                                                    cover={
                                                        blog.imageUrl ? (
                                                            <img alt="blog cover" src={blog.imageUrl} />
                                                        ) : (
                                                            <img alt="default cover" src="/default-image.png" />
                                                        )
                                                    }
                                                    onClick={() => handleBlogClick(blog.id)}
                                                >
                                                    <Card.Meta title={blog.title} />
                                                </Card>
                                            </Col>
                                        ))
                                    ) : (
                                        <p>Không có blog nào được hiển thị</p>
                                    )}
                                </Row>
                            </div>
                        </Content>
                    </Layout>
                </Spin>
            </div>
        </>
    );
};

export default BlogPageView;