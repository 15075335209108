import React, {FC} from 'react';
// import tool from "@assets/icon/tool.svg"
// import tool_active from "@assets/icon/tool-active.svg"
// import news from "@assets/icon/news.svg"
// import news_active from "@assets/icon/news-active.svg"
// import promotion from "@assets/icon/promotion.svg"
// import promotion_active from "@assets/icon/promotion-active.svg"
// import credit from "@assets/icon/credit.svg"
// import credit_active from "@assets/icon/credit-active.svg"
// import home from "@assets/icon/home.svg";
// import home_active from "@assets/icon/home-active.svg";
// import {
//     useHistory,
//     useLocation,
// } from 'react-router-dom'
import "./TabBar.scss";

const TabBarBottom: FC = () => {
    {
    // const history = useHistory();
    // const location = useLocation();
    // const {pathname} = location;

    // const setRouteActive = (value: string) => {
    //     history.push(value)
    }

    return (
        // <div className="bottom uk-hidden@m">
        //     <div className="adm-tab-bar">
        //         <div className="adm-tab-bar-wrap">
        //             <div
        //                 className={`adm-tab-bar-item ${pathname.includes('/the-tin-dung') && 'adm-tab-bar-item-active'}`}
        //                 onClick={() => setRouteActive("/the-tin-dung")}>
        //                 <div className="adm-badge-wrapper">
        //                     <div className="adm-tab-bar-item-icon">
        //                         <img src={pathname.includes('/the-tin-dung') ? credit_active : credit} alt="icon"/>
        //                     </div>
        //                 </div>
        //                 <div className="adm-tab-bar-item-title adm-tab-bar-item-title-with-icon">Thẻ tín dụng</div>
        //             </div>
        //             <div className={`adm-tab-bar-item ${pathname.includes('/uu-dai') && 'adm-tab-bar-item-active'}`}
        //                  onClick={() => setRouteActive("/uu-dai")}>
        //                 <div className="adm-badge-wrapper">
        //                     <div className="adm-tab-bar-item-icon">
        //                         <img src={pathname.includes('/uu-dai') ? promotion_active : promotion} alt="icon"/>
        //                     </div>
        //                 </div>
        //                 <div className="adm-tab-bar-item-title adm-tab-bar-item-title-with-icon">Ưu đãi</div>
        //             </div>
        //             <div className={`adm-tab-bar-item ${pathname === '/' && 'adm-tab-bar-item-active'}`}
        //                  onClick={() => setRouteActive("/")}>
        //                 <div className="adm-badge-wrapper">
        //                     <div className="adm-tab-bar-item-icon">
        //                         <img src={pathname === '/' ? home_active : home} alt="icon"/>
        //                     </div>
        //                 </div>
        //                 <div className="adm-tab-bar-item-title adm-tab-bar-item-title-with-icon">Trang chủ</div>
        //             </div>
        //             <div
        //                 className={`adm-tab-bar-item ${pathname.includes('/cong-cu') && 'adm-tab-bar-item-active'}`}
        //                 onClick={() => setRouteActive("/cong-cu")}>
        //                 <div className="adm-badge-wrapper">
        //                     <div className="adm-tab-bar-item-icon">
        //                         <img src={pathname.includes('/cong-cu') ? tool_active : tool} alt="icon"/>
        //                     </div>
        //                 </div>
        //                 <div className="adm-tab-bar-item-title adm-tab-bar-item-title-with-icon">Công cụ</div>
        //             </div>
        //             <div className={`adm-tab-bar-item ${pathname.includes('/tin-tuc') && 'adm-tab-bar-item-active'}`}
        //                  onClick={() => setRouteActive("/tin-tuc")}>
        //                 <div className="adm-badge-wrapper">
        //                     <div className="adm-tab-bar-item-icon">
        //                         <img src={pathname.includes('/tin-tuc') ? news_active : news} alt="icon"/>
        //                     </div>
        //                 </div>
        //                 <div className="adm-tab-bar-item-title adm-tab-bar-item-title-with-icon">Tin tức</div>
        //             </div>

        //         </div>
        //     </div>
        // </div>
        <div>

        </div>
    )
}

export default React.memo(TabBarBottom);
