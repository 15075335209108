import React from 'react';
import './ContactPageView.scss';

const ContactPageView = () => (
  <div className="px-4 pt-7 overflow-y-scroll contact">
    {/* <h1 className="contact-heading">Thông tin giới thiệu chi tiết</h1>
    <p className="contact-paragraph">GoCard phá vỡ mọi rào cản truyền thống, giúp bạn lựa chọn các sản phẩm tài chính trở nên đơn giản và nhanh chóng hơn bao giờ hết.
      Với mục tiêu đơn giản hoá các bước tiếp cận tài chính và cá nhân hóa trải nghiệm, GoCard luôn nỗ lực mang đến những giải pháp
      phù hợp cùng nhiều chương trình ưu đãi hấp dẫn, đáp ứng nhu cầu chi tiêu đa dạng của người dùng.
    </p> */}

    <h1 className="contact-heading">Thông tin liên hệ</h1>
    <p className="contact-paragraph">Trụ sở: Tầng 08, 80 Duy Tân, Cầu Giấy, Hà Nội</p>
    <p className="contact-paragraph">Hotline: 024.6292.5308</p>
    <p className="contact-paragraph">Email: support@gocard.vn</p>
  </div>
);

export default ContactPageView;
