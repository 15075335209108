import React, {useEffect, useState} from 'react';
import './BlogDetailPageView.scss';
import publicService from "../../../service/PublicService";
import {useParams} from "react-router-dom";

interface Blog {
    id: number;
    title: string;
    imageUrl: string;
    content: string;
}

const BlogDetailPageView = () => {
    const {id} = useParams<{ id: string }>();
    const [blogs, setBlogs] = useState<Blog>();

    const fetchBlog = async (id: number) => {
        try {
            const resp = await publicService.findBlogById(id);
            setBlogs(resp.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (id) {
            fetchBlog(Number(id));
        }

        window.scroll(0, 0);
    }, [id]);

    return (
        <div className="blogs">
            <div className="container-blog-content">
                {blogs ? (
                    <div className="blog-content">
                        <div
                            className="blog-content-items"
                            dangerouslySetInnerHTML={{__html: blogs.content}}
                        />
                    </div>
                ) : (<div></div>)}
            </div>
        </div>
    );
}

export default React.memo(BlogDetailPageView);