import React, {useEffect, useState} from 'react';
import './ModalAddCard.scss';
import {map} from "lodash"
import * as data from "@assets/data/data.module.json"
import {ICard} from "@types";
import AppImage from "../../elements/AppImage/AppImage";
import {Select2, Select2Data, Select2UpdateValue} from "select2-react-component";

const ModalAddCard = ({onCompare, closeModal}: any) => {
    const [cards, setCards] = useState<ICard[]>([...data.partners, ...data.otherCards]);
    const [cardsCompare, setCardsCompare] = useState<string[]>();
    const [bank, setBank] = useState<string>("");
    const [name, setName] = useState<string>("");
    const banks: Select2Data = JSON.parse(JSON.stringify([
        {value: "vib", label: "Ngân Hàng Quốc Tế Việt Nam (VIB)"},
        {value: "vpbank", label: "Ngân hàng TMCP Việt Nam Thịnh Vượng (VPBank)"},
        {value: "vietcombank", label: "Ngân hàng TMCP Ngoại thương Việt Nam (Vietcombank)"},
        {value: "tpbank", label: "Ngân hàng Thương mại Cổ phần Tiên Phong (TPBank)"},
        {value: "agribank", label: "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam (Agribank)"},
        {value: "hsbc", label: "Ngân hàng TNHH Một thành viên HSBC (HSBC)"},
        {value: "techcombank", label: "Ngân hàng Thương mại cổ phần Kỹ thương Việt Nam (Techcombank)"},
    ]));

    const filterByName = (name: string) => {
        setName(name);
        const lowerCaseName = name.toLowerCase();
        const filteredCards = cards.sort((a, b) => a.stt - b.stt).filter(card =>
            card.name.toLowerCase().includes(lowerCaseName)
        );
        setCards(filteredCards);
    }
    useEffect(() => {
        filterByName("");
        // Get the existing cardsCompare array from localStorage
        const existingCardsCompare = JSON.parse(localStorage.getItem("cardsCompare") as string) || [];
        setCardsCompare(existingCardsCompare);
    }, [onCompare])

    const onSelectBank = (e: Select2UpdateValue) => {
        const value = e as string;
        setBank(value);
        let cardsSearch;
        if (name) {
            cardsSearch = cards;
        } else {
            cardsSearch = [...data.partners, ...data.otherCards];
        }
        const filteredCards = cardsSearch.sort((a, b) => a.stt - b.stt).filter(card =>
            card.type === value
        );
        setCards(filteredCards);
    }
    return (
        <>
            <div className="compare-overlay d-flex">
                <div className="compare-popup showMobile">
                    <h3 className="compare-popup__title">
                        Bạn đã chọn <span>{cardsCompare?.length}</span>/3 thẻ
                    </h3>
                    <div onClick={closeModal} className="close-icon">
                        <span className="icons icons-small icon-general-close"/>
                    </div>
                    <div className="compare-search__title">
                        <div className="form-group" style={{marginBottom: "9px"}}>
                            <input onChange={(e) => filterByName(e.target.value)} className="form-field" type="text"
                                   placeholder="Tìm kiếm theo tên"/>
                        </div>
                        <Select2 placeholder="Ngân hàng phát hành thẻ"
                                 update={onSelectBank}
                                 value={bank}
                                 data={banks}/>
                    </div>

                    <div className="compare-popup__card-list">
                        {map(cards, (card: ICard, index: number) => (
                            <div key={index}
                                 className={`card-item ${cardsCompare?.includes(card.id) ? 'checked' : ''} ${cardsCompare && cardsCompare.length > 2 && !cardsCompare?.includes(card.id) ? 'disabled' : ''}`}
                                 onClick={() => onCompare(card.id)}>
                                <input type="checkbox" className="card-item__checkbox checkboxFake"
                                       id="checkbox-compare-0"/>
                                {cardsCompare?.includes(card.id) &&
                                <label className="checkboxFakeChecked icons icons-small icon-checkbox-checked"/>}
                                {!cardsCompare?.includes(card.id) &&
                                <label className="checkboxFakeUncheck icons icons-small icon-checkbox-uncheck"/>}

                                <div className="card-item__img-wrapper">
                                    <AppImage pathImg={card.img}/>
                                </div>
                                <div className="card-item__info">
                                    <span className="card-item__info-name">{card.name}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="compare-popup__bottom" onClick={closeModal}>
                        <button className="compare-popup__bottom-btn">Áp dụng</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(ModalAddCard);
