import React, {useState} from 'react';
import axiosInstance from "@utils/axiosInstance";
import {formatDoubleInput, number} from '@utils/constants';

interface TinhLaiVay {
    soTienVay: string;
    kyhan: string;
    month: number;
    laiSuat: string;
    type: number;
}

interface TinhLaiGui {
    soTienGui: string;
    kyhan: string;
    laiSuat: string;
}

const TinhLaiPage = ({type}: any) => {
    const [vay, setVay] = useState<TinhLaiVay>({
        month: 0,
        type: 1,
        kyhan: "",
        laiSuat: "",
        soTienVay: ""
    });
    const [gui, setGui] = useState<TinhLaiGui>({
        kyhan: "",
        laiSuat: "",
        soTienGui: ""
    });
    const [result, setResult] = useState({totalAmount: "", totalLai: "", totalMonth: ""});
    const [resultGui, setResultGui] = useState({totalAmount: "", totalLai: ""});
    const [errors, setErrors] = useState({
        kyhan: "",
        laiSuat: "",
        soTienVay: ""
    });
    const [errorsGui, setErrorsGui] = useState({
        kyhan: "",
        laiSuat: "",
        soTienGui: ""
    });
    const onChangeGui = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setGui(info => ({
                ...info,
                [name]: value ? value : ""
            })
        )
        setErrorsGui((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() === "" ? "Vui lòng nhập trường này" : ""
        }))
    }
    const onChangeVay = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setVay(info => ({
                ...info,
                [name]: value ? value : ""
            })
        )
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() === "" ? "Vui lòng nhập trường này" : ""
        }))
    }
    const validateFormGui = () => {
        const {laiSuat, kyhan, soTienGui} = gui;
        const newErrors = {
            laiSuat: !laiSuat ? "Vui lòng nhập lãi suất" : "",
            kyhan: !kyhan ? "Vui lòng nhập kỳ hạn" : "",
            soTienGui: !soTienGui ? "Vui lòng nhập số tiền" : "",
        };
        setErrorsGui(newErrors);
        return !Object.values(newErrors).some((error) => error);
    }
    const validateForm = () => {
        const {laiSuat, kyhan, soTienVay} = vay;
        const newErrors = {
            laiSuat: !laiSuat ? "Vui lòng nhập lãi suất" : "",
            kyhan: !kyhan ? "Vui lòng nhập kỳ hạn" : "",
            soTienVay: !soTienVay ? "Vui lòng nhập số tiền" : "",
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error);
    }
    const onSubmitGui = () => {
        const valid = validateFormGui();
        if (valid) {
            console.log(gui)
            setResultGui({
                totalLai: number(parseInt(gui.soTienGui.replace(/[^0-9]/g, '')) * parseFloat(gui.laiSuat) / 100 * parseInt(gui.kyhan) / 12),
                totalAmount: number(parseInt(gui.soTienGui.replace(/[^0-9]/g, '')) + parseInt(gui.soTienGui.replace(/[^0-9]/g, '')) * parseFloat(gui.laiSuat) / 100 * parseInt(gui.kyhan) / 12)
            })
        }
    }
    const onSubmitVay = () => {
        const valid = validateForm();
        if (valid) {
            const updatedInfo: TinhLaiVay = {
                ...vay,
                month: number(vay.kyhan),
                type: 1
            };
            axiosInstance.post("/calculate/permanent", updatedInfo).then(response => {
                setResult({
                    totalAmount: response.data.total.totalAmount,
                    totalLai: response.data.total.totalLai,
                    totalMonth: response.data.total.totalMonth,
                })
            }).catch(errors => {
                console.log(errors);
            });
        }
    }
    return (
        <>
            <div>
                <div className="block-calc-content row">
                    <div className="block-calc-content-left col-12 col-md-6">
                        {type === 'vay' &&
                        <>
                            <div className="calc-content-left-item">
                                <p style={{color: errors.soTienVay ? 'red' : 'initial'}}
                                   className="content-left-title">Số tiền {type} *</p>
                                <input style={{borderColor: errors.soTienVay ? 'red' : 'initial'}} type="text"
                                       name="soTienVay" onChange={onChangeVay}
                                       className="custom-input-calc-left deposits"
                                       value={number(vay.soTienVay)}/><span
                                style={{color: errors.soTienVay ? 'red' : 'initial'}}
                                className="icon-end-input">đ</span>
                            </div>
                            <div className="calc-content-left-item">
                                <p style={{color: errors.soTienVay ? 'red' : 'initial'}}
                                   className="content-left-title">Lãi suất {type} *</p>
                                <input style={{borderColor: errors.soTienVay ? 'red' : 'initial'}} type="text"
                                       onChange={onChangeVay}
                                       name="laiSuat"
                                       className="custom-input-calc-left depositRate"
                                       value={formatDoubleInput(vay.laiSuat)} maxLength={4}/><span
                                style={{color: errors.soTienVay ? 'red' : 'initial'}}
                                className="icon-end-input">%/Năm</span>
                            </div>
                            <div className="calc-content-left-item">
                                <p style={{color: errors.soTienVay ? 'red' : 'initial'}}
                                   className="content-left-title">Kỳ hạn {type} *</p>
                                <input style={{borderColor: errors.soTienVay ? 'red' : 'initial'}} type="text"
                                       name="kyhan" onChange={onChangeVay}
                                       className="custom-input-calc-left depositTerm"
                                       value={vay.kyhan} maxLength={2}/><span
                                style={{color: errors.soTienVay ? 'red' : 'initial'}}
                                className="icon-end-input">Tháng</span>
                            </div>
                        </>
                        }
                        {type === 'gửi' &&
                        <>
                            <div className="calc-content-left-item">
                                <p style={{color: errorsGui.soTienGui ? 'red' : 'initial'}}
                                   className="content-left-title">Số tiền {type} *</p>
                                <input style={{borderColor: errorsGui.soTienGui ? 'red' : 'initial'}} type="text"
                                       onChange={onChangeGui}
                                       name="soTienGui"
                                       className="custom-input-calc-left deposits"
                                       value={number(gui.soTienGui)}/><span
                                style={{color: errorsGui.soTienGui ? 'red' : 'initial'}}
                                className="icon-end-input">đ</span>
                            </div>
                            <div className="calc-content-left-item">
                                <p style={{color: errorsGui.laiSuat ? 'red' : 'initial'}}
                                   className="content-left-title">Lãi suất {type} *</p>
                                <input style={{borderColor: errorsGui.laiSuat ? 'red' : 'initial'}} type="text"
                                       onChange={onChangeGui}
                                       name="laiSuat"
                                       className="custom-input-calc-left depositRate"
                                       value={gui.laiSuat} maxLength={4}/><span
                                style={{color: errorsGui.soTienGui ? 'red' : 'initial'}}
                                className="icon-end-input">%/Năm</span>
                            </div>
                            <div className="calc-content-left-item">
                                <p style={{color: errorsGui.kyhan ? 'red' : 'initial'}}
                                   className="content-left-title">Kỳ hạn {type} *</p>
                                <input style={{borderColor: errorsGui.kyhan ? 'red' : 'initial'}} type="text"
                                       onChange={onChangeGui}
                                       name="kyhan"
                                       className="custom-input-calc-left depositTerm"
                                       value={gui.kyhan} maxLength={2}/><span
                                style={{color: errorsGui.kyhan ? 'red' : 'initial'}}
                                className="icon-end-input">Tháng</span>
                            </div>
                        </>
                        }
                        <p className="description-text">(*): Thông tin bắt buộc</p>
                        <p className="description-text">(*) Nội dung trên có tính chất tham khảo.</p>
                        {type === 'gửi' ? <p className="description-text">Lưu ý: Lãi tiền gửi ước tính
                                theo phương thức trả lãi cuối kỳ</p> :
                            <p className="description-text">Lưu ý: Lãi tiền vay ước tính
                                theo lãi suất cố định</p>
                        }

                    </div>
                    <div className="block-calc-content-right col-12 col-md-6">
                        <div className="calc-tool-box">
                            {type === 'gửi' ? <p className="calc-tool-box-tile">Số tiền lãi nhận được</p>
                                : <p className="calc-tool-box-tile">Số tiền phải trả hàng tháng:</p>}

                            <div className="calc-tool-box-value">
                                <span
                                    className="interestReceive">{type === 'vay' ? number(result.totalMonth) : resultGui.totalLai}</span>
                                <sup>đ</sup></div>
                            {type === 'vay' && <>
                                <p className="calc-tool-box-tile">Tổng lãi phải trả</p>
                                <div className="calc-tool-box-value">
                                    <span className="interestReceive">{number(result.totalLai)}</span>
                                    <sup>đ</sup></div>
                            </>
                            }
                            {type === 'gửi' ?
                                <p className="calc-tool-box-tile">Tổng số tiền nhận được khi
                                    đến hạn</p> :
                                <p className="calc-tool-box-tile">Tổng số tiền phải trả</p>}
                            <div className="calc-tool-box-value">
                                <span
                                    className="totalReceive">{type === 'vay' ? number(result.totalAmount) : resultGui.totalAmount}</span>
                                <sup>đ</sup></div>
                        </div>
                    </div>
                    <div className="block-calc-content-bottom col-12 col-lg-5">
                        <a onClick={type === 'vay' ? onSubmitVay : onSubmitGui}
                           className="btn-calc-content btn-calc-deposits">XEM
                            KẾT QUẢ</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(TinhLaiPage);
