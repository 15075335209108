import React, {useEffect, useState} from 'react';
import './PersonalInfoPageView.scss';
import cities from '../../../utils/city.json';
import districts from '../../../utils/district.json';
import {useHistory, useParams} from 'react-router-dom';
import {notification, Spin} from 'antd';
import Select, {SingleValue} from 'react-select';
import publicService from 'src/app/service/PublicService';
import {callReceiver} from 'src/app/service/SendDataToPartner';

interface RouteParams {
    type: string;
}

interface CreditCard {
    cashAdvanceFee: any;
    id: number;
    name: string;
    imageUrl: string;
    creditLimitFrom: number;
    creditLimitTo: number;
    annualFee: number;
    monthlyIncome: number;
    partner: string;
    requiredAge: number;
    features: string;
    interestRate: string;
    navigateLink: string;
}

declare global {
    interface Window {
        BM: any;
    }
}

const PersonalInfoPageView = () => {

    const {type} = useParams<RouteParams>();
    const history = useHistory();
    const [errorNotify, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const storedLink = localStorage.getItem('link') || '';
    const hasOtpSub = storedLink.includes('sub=otp');

    const [contactInfo, setContactInfo] = useState({
        selectedCity: '',
        cityCode: '',
        selectedDistrict: null,
        inputName: '',
        inputCccd: '',
        validName: true,
        validCccd: true,
        validSelectedCity: true,
        validSelectedDistrict: true
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://apicampaign.bizfly.vn/js/sdk.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.BM) {
                window.BM.init({
                    project_token: '70963d3f-220d-449a-8014-35fe11e8fdb2',
                    app_key: 'ad53d774-dfdc-4b4a-a6c3-c62594b62904',
                    version: 'v3.0'
                });
            } else {
                console.error('BizMail SDK not loaded correctly, window.BM is undefined.');
            }
        };
        script.onerror = () => {
            console.error('Failed to load BizMail SDK script.');
        };
        document.body.appendChild(script);
    }, []);

    const handleCityChange = (selectedOption: SingleValue<any>) => {
        setContactInfo({
            ...contactInfo,
            selectedCity: selectedOption?.value || '',
            cityCode: selectedOption?.code || '',
            selectedDistrict: null,
            validSelectedCity: true
        });
    };

    const handleDistrictChange = (selectedOption: any) => {
        setContactInfo({
            ...contactInfo,
            selectedDistrict: selectedOption?.value || null,
            validSelectedDistrict: true
        });
    };

    const handleNameChange = (e: any) => {
        setContactInfo({
            ...contactInfo,
            inputName: e.target.value,
            validName: e.target.value.trim() !== ''
        });
    };

    const handleCccdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^\d{12}$/;
        if (/^\d*$/.test(value) && value.length <= 12) {
            setContactInfo({
                ...contactInfo,
                inputCccd: value,
                validCccd: value.length === 12 && regex.test(value)
            });
        }
    };

    const handleSubmit = async () => {
        const {
            inputName,
            inputCccd,
            selectedCity,
            selectedDistrict,
            validName,
            validCccd,
            validSelectedCity,
            validSelectedDistrict
        } = contactInfo;

        // Validation checks
        if (!validName || !validCccd || !validSelectedCity || !validSelectedDistrict) {
            return;
        }

        if (inputName.trim() === '' || inputCccd.trim() === '' || selectedCity === '' || selectedDistrict === null) {
            if (inputName.trim() === '') {
                setContactInfo(prevState => ({
                    ...prevState,
                    validName: false,
                }));
            }
            if (inputCccd.trim() === '') {
                setContactInfo(prevState => ({
                    ...prevState,
                    validCccd: false,
                }));
            }
            if (selectedCity === '') {
                setContactInfo(prevState => ({
                    ...prevState,
                    validSelectedCity: false,
                }));
            }
            if (selectedDistrict === null) {
                setContactInfo(prevState => ({
                    ...prevState,
                    validSelectedDistrict: false,
                }));
            }
            return;
        }

        if (window.BM) {
            const email = localStorage.getItem("userEmail")
            window.BM.importAutomation({
                uuid: '75c185d6-3cb5-4a08-8d82-5727e3c9b055',
                source: 'source-name',
                subscriber: {
                    name: 'Tên người đăng ký',
                    emails: email,
                    phone: 'số điện thoại'
                }
            });
        }

        const leadDto = {
            fullName: inputName,
            identityNumber: inputCccd,
            city: selectedCity,
            district: selectedDistrict,
            byPassOtp: hasOtpSub
        };

        const existingLoanInfo = sessionStorage.getItem('loanInfo');

        // Nếu có loanInfo rồi thì tiếp tục lưu vào sessionStorage
        const loanInfo = existingLoanInfo ? JSON.parse(existingLoanInfo) : {};
        loanInfo.identityNumber = inputCccd;
        loanInfo.fullName = inputName;
        sessionStorage.setItem('loanInfo', JSON.stringify(loanInfo));

        const storedCardData = localStorage.getItem('cardData');
        let step = 'nhap-thong-tin-ca-nhan';

        if (storedCardData) {
            const cardData = JSON.parse(storedCardData) as CreditCard;

            if (cardData.partner) {
                step = 'nhap-thong-tin-merchant';

                Object.assign(leadDto, {
                    product: cardData.name,
                    creditLimit: cardData.creditLimitTo,
                    monthlyIncome: cardData.monthlyIncome,
                    partner: cardData.partner,
                });

                setLoading(true);
                try {
                    const UUID = sessionStorage.getItem('user_id');

                    try {
                        await Promise.all([
                            publicService.saveLeadProduct({
                                name: cardData.name,
                                creditLimitTo: cardData.creditLimitTo,
                                creditLimitFrom: cardData.creditLimitFrom,
                                annualFee: cardData.annualFee,
                                cashAdvanceFee: cardData.cashAdvanceFee,
                                monthlyIncome: cardData.monthlyIncome,
                                requiredAge: cardData.requiredAge,
                                features: cardData.features,
                                interestRate: cardData.interestRate,
                                uuid: UUID,
                            }),
                            publicService.applyRegister(step, type, leadDto)
                        ]);

                        console.log("Cả hai hàm đã được thực thi thành công");
                    } catch (error) {
                        console.error("Có lỗi xảy ra khi thực thi:", error);
                    }

                    history.push('/success');

                    return;
                } catch (error: any) {
                    errorNotify.error({
                        message: 'Lỗi',
                        description: error.response?.data || 'An error occurred',
                    });
                } finally {
                    setLoading(false);
                }
            }
        }

        setLoading(true);
        try {
            const resp = await publicService.applyRegister(step, type, leadDto);

            if (resp.status === 200) {
                if (type === 'credit-card') {
                    history.push('/danh-sach-the', {listCard: resp.data.creditCards, filterType: resp.data.filterType});
                } else {
                    localStorage.removeItem('cardData');

                    await callReceiver();

                    const selectedLoanPackage = JSON.parse(localStorage.getItem('selectedLoanPackage') || '{}');
                    const selectedLoanData = JSON.parse(sessionStorage.getItem('loanData') || '{}');
                    const userUUID = sessionStorage.getItem('user_id');

                    const loanData = {
                        ...selectedLoanPackage,
                        ...selectedLoanData,
                        uuid: userUUID,
                    };

                    const response = await publicService.saveLeadProduct(loanData);

                    if (response.status === 200) {
                        history.push('/success-view');
                    }
                }
            }
        } catch (error: any) {
            errorNotify.error({
                message: 'Lỗi',
                description: error.response?.data || 'An error occurred',
            });
        } finally {
            setLoading(false);
        }
    };


    const cityOptions = Object.entries(cities).map(([code, city]) => ({
        code: code,
        value: city.name_with_type,
        label: city.name_with_type
    })).sort((a, b) => a.label.localeCompare(b.label));

    const districtOptions = Object.entries(districts)
        .filter(([, district]) => district.parent_code === contactInfo.cityCode)
        .map(([, district]) => ({
            value: district.name_with_type,
            label: district.name_with_type
        })).sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            {contextHolder}
            <div className="mx-3 mt-4 contact-form">
                <Spin spinning={loading}>
                    <p style={{fontWeight: '1000', marginBottom: '0.2em'}} className="fs-4 title-page">
                        Thông tin cá nhân
                    </p>
                    <div>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}} className="fw-semibold m-0 svn-font">Họ và
                                Tên</p>
                            <input
                                type="text"
                                className="form-control border border-1"
                                id="name"
                                placeholder="Vui lòng nhập"
                                value={contactInfo.inputName}
                                onChange={handleNameChange}
                                required
                            />
                            {!contactInfo.validName && (<p className='text-danger'>Vui lòng nhập họ và tên</p>)}
                        </div>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}}
                               className="fw-semibold m-0 svn-font">CCCD/CMND</p>
                            <input
                                type="text"
                                className="form-control border border-black"
                                id="CCCD"
                                pattern="\d*"
                                placeholder="Vui lòng nhập"
                                value={contactInfo.inputCccd}
                                onChange={handleCccdChange}
                                maxLength={12}
                                required
                            />
                            {!contactInfo.validCccd && (
                                <p className='text-danger'>Vui lòng nhập 12 kí tự CCCD/CMND</p>)}
                        </div>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}} className="fw-semibold m-0 svn-font">Địa
                                chỉ</p>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <Select
                                        options={cityOptions}
                                        onChange={handleCityChange}
                                        value={cityOptions.find(option => option.value === contactInfo.selectedCity)}
                                        placeholder="Chọn Tỉnh/Thành"
                                        className="basic-single"
                                    />
                                    {!contactInfo.validSelectedCity &&
                                        <p className='text-danger'>Vui lòng chọn Tỉnh/thành</p>}
                                </div>
                                <div className="col-6">
                                    <Select
                                        className="basic-single"
                                        options={districtOptions}
                                        onChange={handleDistrictChange}
                                        value={contactInfo.selectedDistrict ? districtOptions.find(option => option.value === contactInfo.selectedDistrict) : null} // Updated value
                                        placeholder="Chọn Quận/Huyện"
                                    />
                                    {!contactInfo.validSelectedDistrict &&
                                        <p className='text-danger'>Vui lòng chọn Quận/huyện</p>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn-confirm" onClick={handleSubmit}>
                                Xác nhận
                            </button>
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default React.memo(PersonalInfoPageView);
