import React, {useEffect, useState} from 'react';
import './SuccessView.scss';
import {images} from '@assets/images';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';
import publicService from "../../../service/PublicService";
import {useHistory} from "react-router-dom";

interface LoanData {
    term: number;
    loanLimit: number;
}

const getAllLoanPackages = async () => {
    try {
        const resp = await publicService.getAllLoanPackages();
        return resp.data;
    } catch (error) {
        console.error("Error", error);
        return [];
    }
}

const SuccessView = () => {
    const [loanData, setLoanData] = useState<LoanData | null>(null);
    const [loanPackage, setLoanPackage] = useState<any[]>([]);
    const history = useHistory();

    useEffect(() => {
        const storedCardData = sessionStorage.getItem('loanData');
        if (storedCardData) {
            setLoanData(JSON.parse(storedCardData));
        }

        const fetchData = async () => {
            const data = await getAllLoanPackages();
            setLoanPackage(data);
        }

        fetchData();

        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        window.location.href = '/';
    }

    const handleCreateLoanPackage = (loanPackageItem: any) => {
        history.push('/', {loanItem: loanPackageItem, active: 'loan'});
    }

    const handleLoanDetail = (loanPackageItem: any) => {
        history.push({
            pathname: '/loan-detail',
            state: {loanItem: loanPackageItem}
        });
    }

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const formatLoanLimit = (loanLimit: number) => {
        const loanLimitInMillions = loanLimit / 1000000;
        return formatNumberCustom(loanLimitInMillions);
    };

    return (
        <>
            <div className='container'>
                <div className='img'>
                    <img className='img-success' src={images.gocard.successImage} alt="success-image"/>
                </div>
                <span className='label-success svn-bold'>Đăng ký thành công</span>
                {loanData && (
                    <>
                        <span style={{fontSize: '16px', padding: '30px 0 10px 0', fontFamily: 'SVN-Bold'}}>Chi tiết khoản vay</span>
                        <div className="loan-tnex">
                            <div className="loan-tnex-img">
                                <img src={images.gocard.tnexFinanceIcon} alt="tnex-finance-logo"/>
                            </div>
                            <div className="loan-tnex-detail">
                                <ul>
                                    <li>
                                        <span>
                                            <img src={images.gocard.loanTimeIcon} alt="loan-time-icon"/>
                                            Thời gian vay
                                        </span>
                                        <p>{loanData.term} tháng</p>
                                    </li>
                                    <li>
                                        <span>
                                            <img src={images.gocard.loanLimitIcon} alt="loan-limit-icon"/>
                                            Hạn mức vay
                                        </span>
                                        <p>{formatLoanLimit(loanData.loanLimit)} triệu đồng</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}
                <p className='txt-success svn-font'>
                    Để nhận khoản vay ngay, vui lòng làm theo <a href="/blog-detail/16">hướng dẫn tại đây</a>.
                    Hoặc nhân viên tư vấn sẽ liên hệ với bạn trong vòng 24h.
                </p>
                <div className='btn-back'>
                    <button onClick={handleClick}>Quay về trang chủ</button>
                </div>

                {loanPackage.length > 0 && (
                    <div className="loan-packages">
                        <span style={{fontFamily: 'SVN-Bold'}}>Tham khảo các sản phẩm khác của chúng tôi</span>
                        <ul>
                            {loanPackage.map((loan, index) => (
                                <li key={index} className="loan-package">
                                    <div key={loan.id} className='loan-package-item-container'>
                                        <div className='loan-package-item'>
                                            <div className="img-loan-package-item">
                                                <img src={loan.iconUrl} alt="loan"/>
                                            </div>
                                            <span>Lãi suất hấp dẫn chỉ từ {loan.interestRate}%/năm</span>
                                            <div className='text-loan-package-item'>
                                                <p>Lãi suất<br/> <b>từ {loan.interestRate}%</b> <br/>Năm</p>
                                                <p>Ưu đãi<br/> <b>{loan.term}</b> <br/>Tháng</p>
                                            </div>
                                            <button
                                                onClick={() => handleCreateLoanPackage(loan)}
                                            >Đăng ký ngay
                                            </button>
                                            <a className='see-detail-loan-package-item'
                                                onClick={() => handleLoanDetail(loan)}
                                            >Xem chi tiết</a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <ScrollToTopButton/>
            </div>
        </>
    );
}

export default React.memo(SuccessView);
