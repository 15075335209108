import React from 'react';
import "./RedirectPageView.scss";

const RedirectPageView = () => {

    return (
        <>
            <div className="redirect-bg-open-account text-center">
                <div className="header-redirect-title ctv">
                    <span>Cảm ơn bạn đã đăng ký mở thẻ tín dụng trên GoCard. </span>
                    <span>Thông tin đăng ký của bạn đã được ghi nhận. </span>
                    <span>Chúng tôi sẽ sớm liên hệ tư vấn cho bạn</span>
                </div>

            </div>
        </>
    )
};
export default React.memo(RedirectPageView);
