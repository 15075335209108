import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { map } from 'lodash';
import { appRoutes } from '@app.routes';
import { NotFoundView } from '@components/views';
// import {getAppRoute} from "@utils/router";
// import {TAppRouteName} from "@types";

// const renderRedirect = (from: string, to: TAppRouteName) => () => <Redirect from={from} to={getAppRoute(to)}/>;

const AppRouter = () => {
    const routes = useMemo(() => map(appRoutes, (route, key) => <Route path={route.path}
        component={route.component as any}
        key={`${key}`} />), []);

    return (
        <Switch>
            {/*<Route exact path="/" render={renderRedirect('/', 'homePage')}/>*/}
            {routes}
            <Route component={NotFoundView} />
        </Switch>
    );
};

export default AppRouter;
