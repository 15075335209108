import React, {useEffect, useState} from 'react';
import {Spin} from "antd";
import axiosInstance from "@utils/axiosInstance";
import {IPost} from "../../../types/state/post";
import {map} from 'lodash';
import {LoadingOutlined} from '@ant-design/icons';
import no_img from "@assets/images/no_img.png"
import {formatDate} from "@utils/constants";

const NewsCardsLatest = () => {
    const [posts, setPosts] = useState<IPost[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchDataAndScrollToTop = async () => {
            await window.scrollTo({top: 200, behavior: 'smooth'});
            setTimeout(() => {
                onSearch();
            }, 500); // Adjust the delay as needed
        };

        fetchDataAndScrollToTop().then(r => console.log(r));
    }, []);
    const onSearch = () => {
        setLoading(true);
        axiosInstance.get(`/news/findNewsLatest`).then(response => {
            if (response.data.code === '200') {
                setPosts(response.data.data)
                setLoading(false);
            }
        })
    }

    const groupedData = [];
    for (let i = 0; i < posts?.length; i += 3) {
        groupedData.push(posts?.slice(i, i + 3));
    }
    return (
        <>
            <div
                className="flex_column av_one_full flex_column_div av-zero-column-padding first">
                <div
                    className="avia-content-slider avia-content-slider-odd">
                    <div className="avia-content-slider-inner">
                        <div className="slide-entry-wrap">
                            {loading ? <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/> :
                                <>
                                    {map(groupedData, (entryPosts, key) => (
                                        <div key={key} className="slide-entry-wrap">
                                            {map(entryPosts, (item, index) => (
                                                <>
                                                    <article key={key}
                                                             className={`slide-entry flex_column post-entry slide-entry-overview slide-parity-${index % 2 === 0 ? 'odd' : 'even'} av_one_third${index === 0 ? ' first' : ''}`}>
                                                        <a href={"/tin-tuc/" + item.slug}
                                                           className="slide-image"
                                                           title="">
                                                            <img
                                                                src={item.image ? item.image : no_img}
                                                                alt={item.title}/>
                                                        </a>
                                                        <div className="slide-content">
                                                            <header className="entry-content-header"><span
                                                                className="blog-categories minor-meta"><a
                                                                href="#"
                                                                rel="tag">{item.type}</a> </span><h3
                                                                className="slide-entry-title entry-title"><a
                                                                href={"/tin-tuc/" + item.slug}
                                                                title={item.title}>{item.title}</a>
                                                            </h3>
                                                            </header>

                                                        </div>
                                                        <footer className="entry-footer">
                                                            <div className="slide-meta">
                                                                <time>{formatDate(item.publishAt)}
                                                                </time>
                                                            </div>
                                                        </footer>
                                                    </article>
                                                </>
                                            ))}
                                        </div>
                                    ))}
                                </>
                            }

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(NewsCardsLatest);
