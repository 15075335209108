import React, { useEffect, useState } from 'react';
import "./FormLandingPage.scss"
import banner from "@assets/images/top-banner.png"
import { AButton, Input1, Radio1, Select } from "@components/elements";
import { personOutline, phonePortraitOutline, cardOutline } from 'ionicons/icons';
import { ILead } from "@types";
import { provincesArray, validation } from "@utils/constants";
import { Select2Data, Select2UpdateValue } from "select2-react-component";
import axiosInstance from "@utils/axiosInstance";
import { useLocation } from "react-router-dom";
import { CardCity, LoadingPage, ModalPrivacy } from '@components/shared';

const FormLandingPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const p = queryParams.get('p');
    const [isPrivacy, setIsPrivacy] = useState<boolean>(false);
    const [lead, setLead] = useState<ILead>({
        province: "",
        income: "",
        name: "",
        phone: "",
        career: "",
        idCard: "",
        status: "",
        bhxh: undefined,
        du_no: undefined,
        the_tin_dung: undefined,
        khoan_vay: undefined,
        no_xau: undefined,
        source: (p === 'mbf-gocard' || p === 'mvt-gocard') ? p : "gocard"
    });

    const [errors, setErrors] = useState({
        name: "",
        phone: "",
        idCard: "",
        province: "",
        career: ""
    });
    const provinces: Select2Data = JSON.parse(JSON.stringify(provincesArray));
    const [isForm2, setIsForm2] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const incomes: Select2Data = JSON.parse(JSON.stringify([
        { value: "Dưới 7 triệu", label: "Dưới 7 triệu" },
        { value: "Từ 7 triệu - 10 triệu", label: "Từ 7 triệu - 10 triệu" },
        { value: "Từ 10 triệu - 15 triệu", label: "Từ 10 triệu - 15 triệu" },
        { value: "Từ 15 triệu - 20 triệu", label: "Từ 15 triệu - 20 triệu" },
        { value: "Trên 20 triệu", label: "Trên 20 triệu" },
    ]));
    const careers: Select2Data = JSON.parse(JSON.stringify([
        { value: "Nhân viên văn phòng", label: "Nhân viên văn phòng" },
        { value: "Kỹ sư", label: "Kỹ sư" },
        { value: "Công an", label: "Công an" },
        { value: "Quân đội", label: "Quân đội" },
        { value: "Ngân hàng", label: "Ngân hàng" },
        { value: "Bác sĩ/y tá", label: "Bác sĩ/y tá" },
        { value: "Tự do", label: "Tự do" },
        { value: "Công nhân, tạp vụ", label: "Công nhân, tạp vụ" },
        { value: "Luật sư", label: "Luật sư" },
        { value: "Giáo viên/Giảng viên", label: "Giáo viên/Giảng viên" },
        { value: "Tài xế", label: "Tài xế" },
        { value: "Phi công", label: "Phi công" },
        { value: "Thuyền viên, thuỷ thủ", label: "Thuyền viên, thuỷ thủ" },
        { value: "Nông dân", label: "Nông dân" },
        {
            value: "Nhân viên massage, bar/club/pub/lounge, sòng bạc, tiệm cầm đồ",
            label: "Nhân viên massage, bar/club/pub/lounge, sòng bạc, tiệm cầm đồ"
        },
        { value: "Nhân viên thu nợ", label: "Nhân viên thu nợ" },
        { value: "Tự kinh doanh", label: "Tự kinh doanh" },
        { value: "Nghề nghiệp khác", label: "Nghề nghiệp khác" },
    ]));
    useEffect(() => {
        const leadForm = localStorage.getItem('leadForm');
        if (leadForm) {
            setLead(lead => ({
                ...lead,
                name: JSON.parse(leadForm).name,
                phone: JSON.parse(leadForm).phone,
                income: JSON.parse(leadForm).income,
                career: JSON.parse(leadForm).career,
            }))
        }

    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLead(lead => {
            let updatedLead = {
                ...lead,
                [name]: value ? value : ""
            };

            if (name === 'du_no') {
                updatedLead.no_xau = value === 'Còn dư nợ' ? value : undefined;
            }

            if (name === 'khoan_vay') {
                updatedLead.no_xau = (value === 'Còn dư nợ' && lead.du_no === 'Còn dư nợ') ? value : undefined;
                updatedLead.du_no = value === 'Có' ? lead.du_no : undefined;
            }

            return updatedLead;
        });

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() === "" ? "Vui lòng nhập trường này" :
                (((name === 'phone' && !validation.isNumberPhone(value))
                    || (name === 'idCard' && !validation.isIdCard(value)))
                    ? "Nhập sai định dạng" : "")
        }));
    }

    const onSelectProvince = (e: Select2UpdateValue) => {
        const value = e as string;
        setLead(lead => ({
            ...lead,
            province: value ? value : ""
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            province: value.trim() === "" ? "Vui lòng chọn tỉnh/ thành phố" : "",
        }));
    }
    const onSelectIncome = (e: Select2UpdateValue) => {
        const value = e as string;
        setLead(lead => ({
            ...lead,
            income: value ? value : ""
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            income: value.trim() === "" ? "Vui lòng chọn mức thu nhập" : "",
        }));
    }
    const onSelectCareer = (e: Select2UpdateValue) => {
        const value = e as string;
        setLead(lead => ({
            ...lead,
            career: value ? value : ""
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            career: value.trim() === "" ? "Vui lòng chọn nghề nghiệp" : "",
        }));
    }
    const validateForm = () => {
        const { name, idCard, phone, province, career } = lead;
        const newErrors = {
            name: !name ? "Vui lòng nhập trường này" : "",
            idCard: !idCard
                ? "Vui lòng nhập trường này"
                : !validation.isIdCard(idCard)
                    ? "Nhập sai định dạng"
                    : "",
            phone: !phone
                ? "Vui lòng nhập trường này"
                : !validation.isNumberPhone(phone)
                    ? "Nhập sai định dạng"
                    : "",
            province: !province ? "Vui lòng chọn tỉnh/ thành phố" : "",
            career: !career ? "Vui lòng chọn nghề nghiệp" : "",
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error);
    };

    const onSubmit = () => {
        const isValid = validateForm();
        if (isValid) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
                axiosInstance.post("", lead).then(r => console.log(r));
                window.location.replace("/");
            }, 1000)
        }
    }
    const sendLead = () => {
        const isValid = validateForm();
        if (isValid) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
                setIsForm2(true);
                axiosInstance.post("", lead).then(r => console.log(r));
            }, 1000)
        }
    }
    return (
        <>
            <div className="form-landing-page compare-overlay d-flex">
                <div className="compare-popup showMobile">
                    <img className="top-banner" src={banner} alt="banner" />
                    <div className="form">
                        <div className="title-ldp text-center">
                            <span>{!isForm2 ? <>NHẬP THÔNG TIN ĐĂNG KÝ NGAY ĐỂ TĂNG TỈ LỆ MỞ THẺ HẠN MỨC LỚN</> :
                                <>
                                    Nhằm gợi ý thẻ tín dụng phù hợp nhất với bạn <br />
                                    Vui lòng bổ sung thêm các thông tin sau
                                </>
                            }</span>
                        </div>
                        <div className="form-content">
                            <div className="container">
                                {!isForm2 &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input1 name="name"
                                                    type="text"
                                                    label="Họ và tên"
                                                    value={lead.name}
                                                    placeholder="Vui lòng nhập"
                                                    onChange={onChange}
                                                    icon={personOutline}
                                                    required={true}
                                                    error={errors.name} />
                                            </div>
                                            <div className="col-md-6">
                                                <Input1 name="phone"
                                                    type="text"
                                                    inputMode="numeric"
                                                    label="Số điện thoại"
                                                    value={lead.phone.replace(/[^0-9.]/g, '')}
                                                    onChange={onChange}
                                                    placeholder="Vui lòng nhập"
                                                    maxLength={10}
                                                    icon={phonePortraitOutline}
                                                    required={true}
                                                    error={errors.phone} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input1 name="idCard"
                                                    type="text"
                                                    inputMode="numeric"
                                                    label="Số CCCD/CMND"
                                                    value={lead.idCard.replace(/[^0-9.]/g, '')}
                                                    onChange={onChange}
                                                    maxLength={12}
                                                    placeholder="Vui lòng nhập"
                                                    minLength={9}
                                                    icon={cardOutline}
                                                    required={true}
                                                    error={errors.idCard} />
                                            </div>
                                            <div className="col-md-6">
                                                <Select placeholder="Chọn tỉnh/ thành phố"
                                                    value={lead.province}
                                                    onSelect={onSelectProvince}
                                                    required={true}
                                                    label="Bạn sống ở?"
                                                    data={provinces}
                                                    error={errors.province} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Select placeholder="Chọn công việc của bạn"
                                                    value={lead.career}
                                                    onSelect={onSelectCareer}
                                                    required={true}
                                                    label="Công việc của bạn?"
                                                    data={careers}
                                                    error={errors.career} />
                                            </div>
                                            <div className="col-md-6">
                                                <Select placeholder="Chọn mức thu nhập của bạn"
                                                    value={lead.income}
                                                    onSelect={onSelectIncome}
                                                    required={false}
                                                    label="Mức thu nhập của bạn?"
                                                    data={incomes}
                                                    error={""} />
                                            </div>
                                        </div>
                                    </>
                                }

                                {isForm2 &&
                                    <>
                                        <div className="row">
                                            <Radio1 label="Bạn có đóng Bảo hiểm xã hội 3 tháng gần đây không?"
                                                name="bhxh"
                                                value={lead.bhxh}
                                                onChange={onChange}
                                                required={false}
                                                value1="Có"
                                                value2="Không"
                                                error={""}
                                            />
                                        </div>
                                        <div className="row">
                                            <Radio1 label="Bạn có thẻ tín dụng ngân hàng nào chưa?"
                                                name="the_tin_dung"
                                                value={lead.the_tin_dung}
                                                onChange={onChange}
                                                required={false}
                                                value1="Có"
                                                value2="Không"
                                                error={""}
                                            />
                                        </div>
                                        <div className="row">
                                            <Radio1
                                                label="Bạn có khoản vay/thẻ tín dụng tại các công ty tài chính nào chưa?"
                                                name="khoan_vay"
                                                value={lead.khoan_vay}
                                                onChange={onChange}
                                                required={false}
                                                value1="Có"
                                                value2="Không"
                                                error={""}
                                            />
                                        </div>
                                        <div className="row">
                                            {lead.khoan_vay === 'Có' &&
                                                <Radio1 label="Tình trạng dư nợ của bạn?"
                                                    name="du_no"
                                                    value={lead.du_no}
                                                    onChange={onChange}
                                                    required={false}
                                                    width1={"50%"}
                                                    width2={"60%"}
                                                    value1="Còn dư nợ"
                                                    value2="Thanh toán hết dư nợ"
                                                    error={""}
                                                />}
                                        </div>
                                        <div className="row">
                                            {lead.du_no === 'Còn dư nợ' && lead.khoan_vay === 'Có' &&
                                                <Radio1 label="Bạn có nợ xấu không?"
                                                    name="no_xau"
                                                    value={lead.no_xau}
                                                    onChange={onChange}
                                                    required={false}
                                                    value1="Có"
                                                    value2="Không"
                                                    error={""}
                                                />}
                                        </div>
                                    </>}

                                <div className="d-grid justify-content-end">
                                    <AButton className="mb-4 px-5 d-inline-block w-auto"
                                        name={!isForm2 ? "Gửi thông tin" : "Hoàn tất đăng ký"}
                                        onClick={!isForm2 ? sendLead : onSubmit} />
                                </div>
                                {!isForm2 &&
                                    <div className="text-center" style={{ fontStyle: "italic" }}>Ấn nút “Gửi thông tin” là bạn
                                        đã chấp nhận các điều khoản và <a onClick={() => setIsPrivacy(true)}
                                            style={{ color: "blue" }}>chính sách bảo
                                            mật</a> của
                                        GoCard</div>}
                            </div>
                        </div>
                        <CardCity />
                    </div>
                </div>
                {isPrivacy && <ModalPrivacy closeModal={() => setIsPrivacy(false)} />}
            </div>
            {isLoading && <LoadingPage />}
        </>
    )
}
export default React.memo(FormLandingPage);
