import {ICard, ILead} from "@types";
import axios from "axios";

export const validation = {
    isNumberPhone: function (str: string) {
        const pattern = /(0[3|5|7|8|9])+([0-9]{8})\b$/;
        return pattern.test(str);
    },
    isEmail: function (str: string) {
        const pattern = /^[a-z0-9](\.?[a-z0-9]){2,}@([a-z]+\.)+([a-z]+)$/;
        return pattern.test(str);
    },
    isIdCard: function (str: string) {
        return !((str.length !== 12 && str.length !== 9) || !/^\d+$/.test(str));
    }
};
export const formatDoubleInput = (value: string): string => {
    const cleanedValue = value.replace(/[^0-9.,-]/g, ''); // Remove non-numeric characters except . , -
    const parts = cleanedValue.split(/[,.]/);
    const integerPart = parts[0].replace(/-/g, ''); // Remove - from integer part
    let formattedValue = integerPart + (parts[1] ? '.' + parts[1] : '');

    // Allow only one decimal separator
    if (parts.length > 1) {
        const decimalPart = parts[1].replace(/[^0-9]/g, '');
        formattedValue = integerPart + '.' + decimalPart;
    }

    return value.startsWith('-') ? '-' + formattedValue : formattedValue;
};

export function number(number: any) {
    let parts = number.toString().replace(/,/g, '');
    parts = parts.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts;
}

export function convertLink(pValue: string | null, card: ICard | undefined) {
    if (card?.code === "vpbankthe") {
        if (pValue === 'mvt-gocard') {
            return card.url + '&utm_medium=MyViettel&utm_term=GoCard';
        } else if (pValue === 'mbf-gocard') {
            return card.url + '&utm_medium=GoCardSMS';
        } else return card.url + '&utm_medium=GoCardWeb';
    } else {
        return card?.url_tracking + "?p=" + (pValue ? pValue : "gocard") + "&utm_medium=" + (pValue ? pValue : "gocard");
    }
}

export const onSubmit = (card: ICard | undefined, pValue: string | null, refId: string | null) => {
    if (card?.code === "vpbankthe") {
        //luong tracking Dsbomber
        let clickId = Math.random().toString(36).substring(2, 26);
        const currentDate = new Date();
        const fullYear = currentDate.getFullYear();
        const month = ((currentDate.getMonth() + 1) + '').padStart(2, '0');
        const date = (currentDate.getDate() + '').padStart(2, '0');
        clickId = fullYear + month + date + '_' + clickId;

        const url = "https://gocard.vn/gocard/vpbankthe" + "?p=" + (pValue ? pValue : "gocard") + `&clickId=${clickId}` + `&referenceClickId=${refId ? refId : 1}`;
        axios.post("https://tracking.sanphamvay.com/click",
            {
                url: url
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            console.log(response)
            console.log(url)
        })
    }
}

export const countUndefinedFields = (lead: ILead, ...fieldNames: (keyof ILead)[]): number => {
    return fieldNames.reduce((count, fieldName) => {
        if (lead[fieldName] === undefined) {
            return count + 1;
        }
        return count;
    }, 0);
};
export const convert_ty: (string: string) => number = (string: string) => {
    let numericValue: string = "";
    if (string.includes("Tỷ")) {
        const str: string[] = string.split("-");
        if (str.length === 2) {
            numericValue = str[1].trim().replace(/\D/g, "");
        } else {
            numericValue = str[0].trim().replace(/\D/g, "");
        }
    }
    return parseInt(numericValue);
}
export const formatDate = (isoDate: string) => {
    return new Date(isoDate).toLocaleDateString('vi-VN', {day: '2-digit', month: '2-digit', year: 'numeric'});
};
export const formatDate2 = (isoDate: string) => {
    return new Date(isoDate).toLocaleDateString('vi-VN',
        {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
        });
};
export const convert_trieu: (string: string) => number = (string: string) => {
    let numericValue: string;
    const str: string[] = string.split("-");
    if (str.length === 2) {
        numericValue = str[0].trim().replace(/\D/g, "");
    } else {
        numericValue = str[0].trim().replace(/\D/g, "");
    }
    return parseInt(numericValue);
}
export const convert_thu_nhap: (string: string) => number = (string: string) => {
    const matchResult = string.match(/\d+(\.\d+)?/);
    if (matchResult) {
        const numericalValue = matchResult[0];
        return parseInt(numericalValue.includes(".") ? numericalValue.replace(".", ",") : numericalValue);
    } else {
        return 0;
    }
}

export const provincesArray = [
    {value: "Thành phố Hồ Chí Minh", label: "Thành phố Hồ Chí Minh"},
    {value: "Thành phố Hà Nội", label: "Thành phố Hà Nội"},
    {value: "Tỉnh An Giang", label: "Tỉnh An Giang"},
    {value: "Tỉnh Bà Rịa - Vũng Tàu", label: "Tỉnh Bà Rịa - Vũng Tàu"},
    {value: "Tỉnh Bạc Liêu", label: "Tỉnh Bạc Liêu"},
    {value: "Tỉnh Bắc Giang", label: "Tỉnh Bắc Giang"},
    {value: "Tỉnh Bắc Kạn", label: "Tỉnh Bắc Kạn"},
    {value: "Tỉnh Bắc Ninh", label: "Tỉnh Bắc Ninh"},
    {value: "Tỉnh Bến Tre", label: "Tỉnh Bến Tre"},
    {value: "Tỉnh Bình Dương", label: "Tỉnh Bình Dương"},
    {value: "Tỉnh Bình Định", label: "Tỉnh Bình Định"},
    {value: "Tỉnh Bình Phước", label: "Tỉnh Bình Phước"},
    {value: "Tỉnh Bình Thuận", label: "Tỉnh Bình Thuận"},
    {value: "Tỉnh Cà Mau", label: "Tỉnh Cà Mau"},
    {value: "Tỉnh Cao Bằng", label: "Tỉnh Cao Bằng"},
    {value: "Thành phố Cần Thơ", label: "Thành phố Cần Thơ"},
    {value: "Thành phố Đà Nẵng", label: "Thành phố Đà Nẵng"},
    {value: "Tỉnh Đắk Lắk", label: "Tỉnh Đắk Lắk"},
    {value: "Tỉnh Đắk Nông", label: "Tỉnh Đắk Nông"},
    {value: "Tỉnh Điện Biên", label: "Tỉnh Điện Biên"},
    {value: "Tỉnh Đồng Nai", label: "Tỉnh Đồng Nai"},
    {value: "Tỉnh Đồng Tháp", label: "Tỉnh Đồng Tháp"},
    {value: "Tỉnh Gia Lai", label: "Tỉnh Gia Lai"},
    {value: "Tỉnh Hà Giang", label: "Tỉnh Hà Giang"},
    {value: "Tỉnh Hà Nam", label: "Tỉnh Hà Nam"},
    {value: "Tỉnh Hà Tĩnh", label: "Tỉnh Hà Tĩnh"},
    {value: "Tỉnh Hải Dương", label: "Tỉnh Hải Dương"},
    {value: "Thành phố Hải Phòng", label: "Thành phố Hải Phòng"},
    {value: "Tỉnh Hậu Giang", label: "Tỉnh Hậu Giang"},
    {value: "Tỉnh Hoà Bình", label: "Tỉnh Hoà Bình"},
    {value: "Tỉnh Hưng Yên", label: "Tỉnh Hưng Yên"},
    {value: "Tỉnh Khánh Hòa", label: "Tỉnh Khánh Hòa"},
    {value: "Tỉnh Kiên Giang", label: "Tỉnh Kiên Giang"},
    {value: "Tỉnh Kon Tum", label: "Tỉnh Kon Tum"},
    {value: "Tỉnh Lai Châu", label: "Tỉnh Lai Châu"},
    {value: "Tỉnh Lạng Sơn", label: "Tỉnh Lạng Sơn"},
    {value: "Tỉnh Lào Cai", label: "Tỉnh Lào Cai"},
    {value: "Tỉnh Lâm Đồng", label: "Tỉnh Lâm Đồng"},
    {value: "Tỉnh Long An", label: "Tỉnh Long An"},
    {value: "Tỉnh Nam Định", label: "Tỉnh Nam Định"},
    {value: "Tỉnh Nghệ An", label: "Tỉnh Nghệ An"},
    {value: "Tỉnh Ninh Bình", label: "Tỉnh Ninh Bình"},
    {value: "Tỉnh Ninh Thuận", label: "Tỉnh Ninh Thuận"},
    {value: "Tỉnh Phú Thọ", label: "Tỉnh Phú Thọ"},
    {value: "Tỉnh Phú Yên", label: "Tỉnh Phú Yên"},
    {value: "Tỉnh Quảng Bình", label: "Tỉnh Quảng Bình"},
    {value: "Tỉnh Quảng Nam", label: "Tỉnh Quảng Nam"},
    {value: "Tỉnh Quảng Ngãi", label: "Tỉnh Quảng Ngãi"},
    {value: "Tỉnh Quảng Ninh", label: "Tỉnh Quảng Ninh"},
    {value: "Tỉnh Quảng Trị", label: "Tỉnh Quảng Trị"},
    {value: "Tỉnh Sóc Trăng", label: "Tỉnh Sóc Trăng"},
    {value: "Tỉnh Sơn La", label: "Tỉnh Sơn La"},
    {value: "Tỉnh Tây Ninh", label: "Tỉnh Tây Ninh"},
    {value: "Tỉnh Thái Bình", label: "Tỉnh Thái Bình"},
    {value: "Tỉnh Thái Nguyên", label: "Tỉnh Thái Nguyên"},
    {value: "Tỉnh Thanh Hóa", label: "Tỉnh Thanh Hóa"},
    {value: "Tỉnh Thừa Thiên Huế", label: "Tỉnh Thừa Thiên Huế"},
    {value: "Tỉnh Tiền Giang", label: "Tỉnh Tiền Giang"},
    {value: "Tỉnh Trà Vinh", label: "Tỉnh Trà Vinh"},
    {value: "Tỉnh Tuyên Quang", label: "Tỉnh Tuyên Quang"},
    {value: "Tỉnh Vĩnh Long", label: "Tỉnh Vĩnh Long"},
    {value: "Tỉnh Vĩnh Phúc", label: "Tỉnh Vĩnh Phúc"},
    {value: "Tỉnh Yên Bái", label: "Tỉnh Yên Bái"}
];
