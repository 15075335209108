import React, {useEffect, useState} from 'react';
import './ScrollToTopButton.scss'

const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        // Add the scroll event listener and handle scroll behavior
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <>
            {showButton && (
                <i id="myBtn"
                   onClick={scrollToTop}
                   className="material-icons opacity-6 me-2 text-md">arrow_upward</i>
            )}
        </>
    );
};

export default ScrollToTopButton;
