import { apiRoutes } from '@api.routes';
import * as views from '@components/views';
import { createRoute } from '@utils/router';

export const appRoutes = {
  aboutPage: createRoute('/gioi-thieu')({
    component: views.AboutPageView,
  }),
  licensePage: createRoute('/dieu-khoan-su-dung')({
    component: views.LicensePageView,
  }),
  privacyPage: createRoute('/chinh-sach-quyen-rieng-tu')({
    component: views.PrivacyPageView,
  }),
  comparePage: createRoute('/so-sanh-the')({
    component: views.ComparePageView,
  }),
  detailCardPage: createRoute(apiRoutes.getDetailCard(':id'))({
    component: views.DetailCardPageView,
  }),
  moTaiKhoanPage: createRoute('/dang-ky-thong-tin')({
    component: views.FormLandingPage,
  }),
  adsFakePage: createRoute('/sms')({
    component: views.AdsFakePageView,
  }),
  creditPage: createRoute('/the-tin-dung')({
    component: views.CreditPageView,
  }),
  toolsPage: createRoute('/cong-cu')({
    component: views.ToolsPageView,
  }),
  detailNewsPage: createRoute(apiRoutes.getDetailNews(':id'))({
    component: views.DetailNewsPageView,
  }),
  newsPage: createRoute('/tin-tuc')({
    component: views.TinTucPageView,
  }),
  detailPromotionPage: createRoute(apiRoutes.getDetailPromotion(':id'))({
    component: views.DetailPromotionPageView,
  }),
  promotionPage: createRoute('/uu-dai')({
    component: views.PromotionPageView,
  }),

  listCard: createRoute("/danh-sach-the")({
    component: views.ListCardPageView,
  }),
  listCard1: createRoute("/card")({
    component: views.ListCardPageView1,
  }),
  listLoan: createRoute("/loan")({
    component: views.ListLoanPageView,
  }),
  blogPageView: createRoute("/blog")({
    component: views.BlogPageView,
  }),
  blogDetailPageView: createRoute("/blog-detail/:id")({
    component: views.BlogDetailPageView,
  }),
  successView: createRoute("/success-view")({
    component: views.SuccessView,
  }),
  contactInfoPage: createRoute('/contact-info/:type')({
    component: views.ContactInfoPageView,
  }),
  personalInfoPage: createRoute('/info/:type')({
    component: views.PersonalInfoPageView,
  }),
  successPage: createRoute('/success')({
    component: views.SuccessPageView,
  }),
  cardDetailPage: createRoute('/card-detail')({
    component: views.CardDetailPageView,
  }),
  cardDetailPage1: createRoute('/chi-tiet-the')({
    component: views.CardDetailPageView1,
  }),
  termPage: createRoute('/terms')({
    component: views.TermPageView,
  }),
  policyPage: createRoute('/policies')({
    component: views.PolicyPageView,
  }),
  contactPage: createRoute('/contact')({
    component: views.ContactPageView,
  }),
  introducePage: createRoute('/introduce')({
    component: views.IntroducePageView,
  }),
  managingUnitPage: createRoute('/managing-unit')({
    component: views.ManagingUnitPageView,
  }),
  receiveOTPPage: createRoute('/receive-OTP/:type')({
    component: views.ReceiveOTPPageView,
  }),
  sentPhoneOTPPage: createRoute('/sent-phone-OTP/:type')({
    component: views.SentPhoneOTPPageView,
  }),
  registerLoanPage: createRoute('/register-loan')({
    component: views.RegisterLoanPageView,
  }),
  loanDetailPage: createRoute('/loan-detail')({
    component: views.LoanDetailPageView,
  }),
  homePage: createRoute('/')({
    component: views.HomePageView,
  }),
};