import {createReducer} from "@utils/reducer";
import {IAppAction, ICheckIdCard, ICheckPhone} from "@types";
import immutable from "seamless-immutable";
import {CHECK_PHONE_BLACK_LIST, CHECK_ID_CARD_BLACK_LIST} from "@redux/actions/checkBlackList.actions";

const initialPhoneState = immutable<ICheckPhone>(<ICheckPhone>{
    result: {}
});
const initialIdCardState = immutable<ICheckIdCard>(<ICheckIdCard>{
    result: {}
});
export const checkPhoneReducer = createReducer<IAppAction, typeof initialPhoneState>(initialPhoneState, {
    [CHECK_PHONE_BLACK_LIST]: (state, action) => {
        if (!immutable.isImmutable(state)) {
            state = immutable(state);
        }
        return state.setIn(['result'], action.payload);
    }
});
export const checkIdCardReducer = createReducer<IAppAction, typeof initialIdCardState>(initialIdCardState, {
    [CHECK_ID_CARD_BLACK_LIST]: (state, action) => {
        if (!immutable.isImmutable(state)) {
            state = immutable(state);
        }
        return state.setIn(['result'], action.payload);
    }
});
