import React from 'react';
import './PolicyPageView.scss';

const PolicyPageView = () => (
  <div className="px-4 pt-1 overflow-y-scroll policy">
    <h1 className="policy-heading">Chính sách và quyền riêng tư</h1>
    <p className="policy-paragraph">
      Chính sách bảo mật này thể hiện cam kết của chúng tôi về quyền được bảo mật thông tin riêng tư đối với Quý khách và người dùng
      của Trang web này. Chính sách bảo mật này quyết định cách thức Công ty cổ phần công nghệ tài chính Dagoras sử dụng, duy trì
      và quản lý thông tin được thu thập từ Quý khách và người dùng Trang web của mình.
    </p>

    <h1 className="policy-heading">Cam kết của GoCard.vn</h1>
    <p className="policy-paragraph">
      GoCard.vn chịu trách nhiệm bảo mật thông tin về dữ liệu của Quý khách và tuyệt đối không tiết lộ cho bất kỳ bên thứ ba nào khác
      ngoại trừ yêu cầu của cơ quan có thẩm quyền theo quy định của pháp luật, hoặc có sự đồng ý của Khách hàng. GoCard.vn sẽ thực hiện
      các bước cần thiết để đảm bảo lưu trữ an toàn thông tin cá nhân Quý khách cung cấp cho công ty. GoCard.vn không chịu trách nhiệm về các
      thất thoát dữ liệu, bí mật thông tin của Khách hàng do Khách hàng vô tình hoặc cố ý gây ra.
    </p>

    <h1 className="policy-heading">Thu thập thông tin</h1>
    <p className="policy-paragraph">
      Thông tin cá nhân từ người dùng sẽ được Trang web ghi nhận, thu thập trực tuyến khi người dùng đăng ký tài khoản sử dụng dịch vụ, sử dụng
      dịch vụ, tương tác trên Trang web hay trong các trường hợp khác mà người dùng được mời để tình nguyện cung cấp thông tin, chủ động,
      trực tiếp cung cấp thông tin cho GoCard.vn, thông tin có được từ Bên thứ ba, thông tin được tự động lưu trữ dữ liệu về người dùng,
      cũng như dữ liệu về máy tính/ thiết bị, thông tin thu thập từ Cookies (Cookies là các tệp dữ liệu được lưu trữ trên ổ cứng của người dùng.
      Chúng tôi có thể sử dụng Cookies theo phiên (sẽ hết hạn sau khi bạn đóng trình duyệt web) và Cookies liên tục (vẫn còn lưu trên máy tính của
      người dùng cho đến khi được xóa) để cung cấp cho người dùng trải nghiệm tốt nhất).
    </p>
    <p className="policy-paragraph">
      Dữ liệu GoCard.vn thu thập là thông tin nhận dạng cá nhân, tất cả thông tin về sản phẩm và các số liệu liên quan đến sản phẩm, dịch vụ,
      chẳng hạn như: tên, địa chỉ bưu chính, địa chỉ email, số điện thoại, thông tin nhân khẩu học (giới tính và nghề nghiệp), …
    </p>

    <h1 className="policy-heading">Mục đích thu thập, sử dụng</h1>
    <p className="policy-paragraph">
      Chúng tôi có thể chia sẻ thông tin cá nhân của người dùng theo các điều sau:
    </p>
    <p className="policy-paragraph">Chia sẻ thông tin cá nhân với sự đồng ý của người dùng. Ví dụ: Người dùng có thể cho phép chúng tôi chia sẻ thông tin cá nhân với các
      bên liên quan để sử dụng với mục đích quảng bá/tiếp thị sản phẩm dịch vụ cho Người dùng. Chia sẻ thông tin cho Đối tác, tổ chức tín dụng
      nhằm thực hiện yêu cầu của Người dùng. Hoạt động này phải được tuân theo chính sách bảo mật của các bên liên quan.
    </p>
    <p className="policy-paragraph">Chia sẻ thông tin cá nhân khi chúng tôi thực hiện một thỏa thuận kinh doanh hoặc đàm phán một thỏa thuận kinh doanh liên quan đến việc
      bán hoặc chuyển nhượng tất cả, hoặc một phần của doanh nghiệp, hoặc tài sản của chúng tôi. Các giao dịch này có thể bao gồm bất kỳ
      dịch hoặc sáp nhập, tài trợ, mua lại hoặc thủ tục phá sản.
    </p>
    <p className="policy-paragraph">Chia sẻ thông tin cá nhân cho các mục đích pháp lý, bảo vệ hoặc bảo mật.</p>
    <p className="policy-paragraph">Chia sẻ thông tin để tuân thủ Luật pháp, đáp ứng các yêu cầu pháp lý và quy trình pháp lý.</p>
    <p className="policy-paragraph">Chia sẻ thông tin để bảo vệ quyền và tài sản của Công ty cổ phần công nghệ tài chính Dagoras, đại lý,
      Quý khách, Đối tác và các bên liên quan.
    </p>
    <p className="policy-paragraph">Chia sẻ thông tin trong trường hợp khẩn cấp. Điều này bao gồm bảo vệ sự an toàn của nhân viên và đại lý,
      Đối tác hoặc bất kỳ các bên liên quan.
    </p>
    <p className="policy-paragraph">Chia sẻ dữ liệu tổng hợp và/hoặc ẩn danh với các bên liên quan.</p>
  </div>
);

export default PolicyPageView;
