import React, {useCallback, useEffect, useState} from 'react';
import './ModalConfirm.scss';
import * as data from "@assets/data/data.module.json"
import AppImage from "../../elements/AppImage/AppImage";
import {ICard} from "@types";
import {convertLink, onSubmit} from "@utils/constants";

interface props {
    closeModal: any;
    value: number;
}

const ModalConfirm: React.FC<props> = ({closeModal, value}) => {
    const [width, setWidth] = useState<number>(290);
    const [card0, setCard0] = useState<ICard>();
    const [card1, setCard1] = useState<ICard>();
    const [card2, setCard2] = useState<ICard>();
    const [transform, setTransform] = useState<number>(0);
    const [isNext, setIsNext] = useState<boolean>(true);
    const [touchStartX, setTouchStartX] = useState<number | null>(null);
    const [pValue, setPValue] = useState<string | null>('');
    const [refId, setRefId] = useState<string | null>('');
    useEffect(() => {
        console.log(value)
        setCard0(getCard("vib-online-plus-2in1"))
        setCard1(getCard("tpbank-evo-visa-silver"))
        setCard2(getCard("vib-financial-free"))
    }, [card0, card1, card2]);
    const getCard = (id: string): ICard => {
        const allCard: ICard[] = [...data.partners, ...data.otherCards];
        return allCard.filter(card => card.id === id)[0];
    }
    useEffect(() => {
        setPValue(localStorage.getItem("pValue"));
        setRefId(localStorage.getItem("refId"))
        decreaseWidthByOne();

        const handleResize = () => {
            const newWidth = window.innerWidth;
            if (newWidth < 1000) {
                decreaseWidthByOne();
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const decreaseWidthByOne = () => {
        setWidth((prevWidth) => (prevWidth > 1 ? window.innerWidth / 2 : prevWidth));
    };
    const onNext = useCallback(() => {
        setTransform(-(window.innerWidth / 2));
        setIsNext(false);
    }, []);

    const onPrev = useCallback(() => {
        setTransform(0);
        setIsNext(true);
    }, []);
    const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        setTouchStartX(event.touches[0].clientX);
    }, []);

    const handleTouchEnd = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        if (touchStartX !== null) {
            const touchDistance = event.changedTouches[0].clientX - touchStartX;
            const swipeThreshold = 50;

            if (touchDistance > swipeThreshold) {
                onPrev();
            } else if (touchDistance < -swipeThreshold) {
                onNext();
            }

            setTouchStartX(null); // Reset touchStartX after handling the swipe
        }
    }, [onPrev, onNext, touchStartX]);
    const onSubmitLink = (card: ICard | undefined) => {
        if (card?.code === 'vpbankthe') {
            if (pValue === 'mvt-gocard') {
                window.location.replace("/dang-ky-thong-tin");
            } else {
                window.open(convertLink(pValue, card));
                onSubmit(card, pValue, refId);
            }
        } else {
            window.open(convertLink(pValue, card));
        }
    }
    return (
        <>
            <div className={`modal-confirm open`}>
                <div className="compare-overlay d-flex">
                    <div className="compare-popup showMobile">
                        <div onClick={closeModal} className="close-icon">
                            <span className="icons icons-small icon-general-close"/>
                        </div>
                        <div className="confirm-dang-ky text-center uk-hidden-mobile">
                            <div className="anchor1 mt-4">Cảm ơn bạn đã đăng ký mở thẻ tín dụng trên GoCard.</div>
                            <div className="anchor1">Dưới đây là một số thẻ phù hợp với bạn!</div>
                        </div>
                        <div className="confirm-dang-ky text-center uk-hidden-desktop">
                            <div className="anchor1 mt-4">Cảm ơn bạn đã đăng ký mở thẻ tín dụng trên GoCard. Dưới đây là
                                một số thẻ phù hợp với bạn!
                            </div>
                        </div>
                        {card0 && card1 && card2 &&
                        <div onTouchStart={handleTouchStart}
                             onTouchEnd={handleTouchEnd}
                             tabIndex={0} id="compare-box-use" style={{marginTop: "-490px"}}>
                            <div className="dg-card-v2-title-mobile-compare">
                                <div className="bx-wrapper" style={{maxWidth: "1000px", margin: "0px auto"}}>
                                    <div className="bx-viewport" style={{
                                        width: "100%",
                                        overflow: "hidden",
                                        position: "relative",
                                    }}>
                                        <div className="dg-card-v2-slider-compare" style={{
                                            width: "515%",
                                            position: "relative",
                                            transitionDuration: "0.5s",
                                            transform: `translate3d(${transform}px, 0px, 0px)`
                                        }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                 style={{
                                                     float: "left",
                                                     listStyle: "none",
                                                     position: "relative",
                                                     width: `${width}px`
                                                 }}>
                                                <span>{card0?.name}</span>
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                 style={{
                                                     float: "left",
                                                     listStyle: "none",
                                                     position: "relative",
                                                     width: `${width}px`
                                                 }}>

                                                <span>{card1?.name}</span>
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                 style={{
                                                     float: "left",
                                                     listStyle: "none",
                                                     position: "relative",
                                                     width: `${width}px`
                                                 }}>
                                                <span>{card2?.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dg-card-v2-header-compare-card">
                                <div className="dg-card-v2-wrapper_new">
                                    <div className="dg-card-v2-slider-compare desktop">
                                        <div className="dg-card-v2-box-compare-content">
                                            <>
                                                <div className="dg-card-v2-img-compare-card ">
                                                    <div className="img_card_effect">
                                                        <a className="top-img  ui-card">
                                                            <div className="bg-card">
                                                                <AppImage className={"img-card1"}
                                                                          pathImg={card0?.img}/>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-info">
                                                    <h3><a
                                                        onClick={() => onSubmitLink(card0)}
                                                        className="new-style-link">{card0?.name}</a>
                                                    </h3>
                                                    <a onClick={() => onSubmitLink(card0)}
                                                       className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                    <p>
                                                        <a href={`/chi-tiet-the/${card0?.id}`} target="_blank"
                                                           className="link new-style-link">Xem chi tiết</a>
                                                    </p>
                                                </div>
                                            </>
                                        </div>
                                        <div className="dg-card-v2-box-compare-content">
                                            <>
                                                <div className="dg-card-v2-img-compare-card ">
                                                    <div className="img_card_effect">
                                                        <a className="top-img  ui-card">
                                                            <div className="bg-card">
                                                                <AppImage className={"img-card1"}
                                                                          pathImg={card1?.img}/>

                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-info">
                                                    <h3><a
                                                        onClick={() => onSubmitLink(card1)}
                                                        className="new-style-link">{card1?.name}</a>
                                                    </h3>
                                                    <a onClick={() => onSubmitLink(card1)}
                                                       className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                    <p>
                                                        <a href={`/chi-tiet-the/${card1?.id}`} target="_blank"
                                                           className="link new-style-link">Xem chi tiết</a>
                                                    </p>
                                                </div>
                                            </>
                                        </div>
                                        <div className="dg-card-v2-box-compare-content">
                                            <>
                                                <div className="dg-card-v2-img-compare-card ">
                                                    <div className="img_card_effect">
                                                        <a className="top-img  ui-card">
                                                            <div className="bg-card">
                                                                <AppImage className={"img-card1"}
                                                                          pathImg={card2?.img}/>

                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-info">
                                                    <h3><a
                                                        onClick={() => onSubmitLink(card2)}
                                                        className="new-style-link">{card2?.name}</a>
                                                    </h3>
                                                    <a onClick={() => onSubmitLink(card2)}
                                                       className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                    <p>
                                                        <a href={`/chi-tiet-the/${card2?.id}`} target="_blank"
                                                           className="link new-style-link">Xem chi tiết</a>
                                                    </p>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                    <div className="bx-wrapper" style={{maxWidth: "1000px", margin: "0px auto"}}>
                                        <div className="bx-viewport"
                                             style={{
                                                 width: "100%",
                                                 overflow: "hidden",
                                                 position: "relative",
                                                 height: "300px"
                                             }}>
                                            <div className="dg-card-v2-slider-compare"
                                                 style={{
                                                     width: "515%",
                                                     position: "relative",
                                                     transitionDuration: "0.5s",
                                                     transform: `translate3d(${transform}px, 0px, 0px)`
                                                 }}>
                                                <div className="dg-card-v2-box-compare-content"
                                                     style={{
                                                         float: "left",
                                                         listStyle: "none",
                                                         position: "relative",
                                                         width: `${width}px`
                                                     }}>
                                                    <>
                                                        <div className="dg-card-v2-img-compare-card ">
                                                            <div className="img_card_effect">
                                                                <a className="top-img ui-card">
                                                                    <div className="bg-card">
                                                                        <AppImage className={"img-card1"}
                                                                                  pathImg={card0?.img}/>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="card-info">
                                                            <a onClick={() => onSubmitLink(card0)}
                                                               className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                            <p>
                                                                <a href={`/chi-tiet-the/${card0?.id}`} target="_blank"
                                                                   className="link new-style-link">Xem chi tiết</a>
                                                            </p>
                                                        </div>
                                                    </>
                                                </div>
                                                <div className="dg-card-v2-box-compare-content"
                                                     style={{
                                                         float: "left",
                                                         listStyle: "none",
                                                         position: "relative",
                                                         width: `${width}px`
                                                     }}>
                                                    <>
                                                        <div className="dg-card-v2-img-compare-card ">
                                                            <div className="img_card_effect">
                                                                <a className="top-img  ui-card">
                                                                    <div className="bg-card">
                                                                        <AppImage className={"img-card1"}
                                                                                  pathImg={card1?.img}/>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="card-info">
                                                            <a onClick={() => onSubmitLink(card1)}
                                                               className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                            <p>
                                                                <a href={`/chi-tiet-the/${card1?.id}`} target="_blank"
                                                                   className="link new-style-link">Xem chi tiết</a>
                                                            </p>
                                                        </div>
                                                    </>
                                                </div>
                                                <div className="dg-card-v2-box-compare-content"
                                                     style={{
                                                         float: "left",
                                                         listStyle: "none",
                                                         position: "relative",
                                                         width: `${width}px`
                                                     }}>
                                                    <>
                                                        <div className="dg-card-v2-img-compare-card ">
                                                            <div className="img_card_effect">
                                                                <a className="top-img  ui-card">
                                                                    <div className="bg-card">
                                                                        <AppImage className={"img-card1"}
                                                                                  pathImg={card2?.img}/>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="card-info">
                                                            <a onClick={() => onSubmitLink(card2)}
                                                               className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                            <p>
                                                                <a href={`/chi-tiet-the/${card2?.id}`} target="_blank"
                                                                   className="link new-style-link">Xem chi tiết</a>
                                                            </p>
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bx-controls bx-has-controls-direction">
                                            <div className="bx-controls-direction">
                                                <a className={isNext ? "bx-prev disabled" : "bx-prev"}
                                                   onClick={onPrev}>Prev</a>
                                                <a className={!isNext ? "bx-next disabled" : "bx-next"}
                                                   onClick={onNext}>Next</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="dg-card-v2-box-content-compare-card">
                                <div className="dg-card-v2-box-content-compare-card">
                                    <div className="dg-card-v2-title-compare-card">
                                        <div className="dg-card-v2-wrapper_new">
                                            <span>Thẻ tín dụng tốt nhất cho</span>
                                        </div>
                                    </div>
                                    <div className="dg-card-v2-body-compare-card">
                                        <div className="dg-card-v2-wrapper_new">
                                            <div className="dg-card-v2-slider-compare desktop">
                                                <div className="dg-card-v2-box-compare-content">
                                                    <p dir="ltr">{card0?.the_tot_nhat}</p>

                                                </div>
                                                <div className="dg-card-v2-box-compare-content">
                                                    <p dir="ltr">{card1?.the_tot_nhat}</p>

                                                </div>
                                                <div className="dg-card-v2-box-compare-content">
                                                    <p dir="ltr">{card2?.the_tot_nhat}</p>
                                                </div>
                                            </div>
                                            <div className="bx-wrapper"
                                                 style={{maxWidth: "1000px", margin: "0px auto"}}>
                                                <div className="bx-viewport"
                                                     style={{
                                                         width: "100%",
                                                         overflow: "hidden",
                                                         position: "relative",
                                                         height: "auto"
                                                     }}>
                                                    <div className="dg-card-v2-slider-compare"
                                                         style={{
                                                             width: "515%",
                                                             position: "relative",
                                                             transitionDuration: "0.5s",
                                                             transform: `translate3d(${transform}px, 0px, 0px)`
                                                         }}>
                                                        <div className="dg-card-v2-box-compare-content"
                                                             style={{
                                                                 float: "left",
                                                                 listStyle: "none",
                                                                 position: "relative",
                                                                 width: `${width}px`
                                                             }}>
                                                            <p dir="ltr">{card0?.the_tot_nhat}</p>

                                                        </div>
                                                        <div className="dg-card-v2-box-compare-content"
                                                             style={{
                                                                 float: "left",
                                                                 listStyle: "none",
                                                                 position: "relative",
                                                                 width: `${width}px`
                                                             }}>
                                                            <p dir="ltr">{card1?.the_tot_nhat}</p>

                                                        </div>
                                                        <div className="dg-card-v2-box-compare-content"
                                                             style={{
                                                                 float: "left",
                                                                 listStyle: "none",
                                                                 position: "relative",
                                                                 width: `${width}px`
                                                             }}>
                                                            <p dir="ltr">{card2?.the_tot_nhat}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dg-card-v2-box-content-compare-card">
                                    <div className="dg-card-v2-title-compare-card">
                                        <div className="dg-card-v2-wrapper_new">
                                            <span>Hạn mức thẻ</span></div>
                                    </div>
                                    <div className="dg-card-v2-body-compare-card">
                                        <div className="dg-card-v2-wrapper_new">
                                            <div className="dg-card-v2-slider-compare desktop">
                                                <div className="dg-card-v2-box-compare-content">
                                                    <p dir="ltr">{card0?.han_muc}
                                                    </p>

                                                </div>
                                                <div className="dg-card-v2-box-compare-content">
                                                    <p dir="ltr">{card1?.han_muc}</p>

                                                </div>
                                                <div className="dg-card-v2-box-compare-content">
                                                    <p dir="ltr">{card2?.han_muc}</p>

                                                </div>
                                            </div>
                                            <div className="bx-wrapper"
                                                 style={{maxWidth: "1000px", margin: "0px auto"}}>
                                                <div className="bx-viewport"
                                                     style={{
                                                         width: "100%",
                                                         overflow: "hidden",
                                                         position: "relative",
                                                         height: "auto"
                                                     }}>
                                                    <div className="dg-card-v2-slider-compare"
                                                         style={{
                                                             width: "515%",
                                                             position: "relative",
                                                             transitionDuration: "0.5s",
                                                             transform: `translate3d(${transform}px, 0px, 0px)`
                                                         }}>
                                                        <div className="dg-card-v2-box-compare-content"
                                                             style={{
                                                                 float: "left",
                                                                 listStyle: "none",
                                                                 position: "relative",
                                                                 width: `${width}px`
                                                             }}>
                                                            <p dir="ltr">{card0?.han_muc}</p>

                                                        </div>
                                                        <div className="dg-card-v2-box-compare-content"
                                                             style={{
                                                                 float: "left",
                                                                 listStyle: "none",
                                                                 position: "relative",
                                                                 width: `${width}px`
                                                             }}>
                                                            <p dir="ltr">{card1?.han_muc}</p>

                                                        </div>
                                                        <div className="dg-card-v2-box-compare-content"
                                                             style={{
                                                                 float: "left",
                                                                 listStyle: "none",
                                                                 position: "relative",
                                                                 width: `${width}px`
                                                             }}>
                                                            <p dir="ltr">{card2?.han_muc}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(ModalConfirm);
