import React, { useEffect } from 'react';
import "./TinTucPageView.scss"
import * as data from "@assets/data/data.module.json"
import "@assets/css/landing-page.css"
import {
    CardCity, NewsCards, NewsCardsFinance, NewsCardsHot, NewsCardsLatest
} from "../../shared";
import free from "@assets/images/label-free.png"
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

const TinTucPageView = () => {
    useEffect(() => {
        document.title = "Tin tức";
        localStorage.removeItem("cardsCompare");
    }, [data.partners]);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <main className="col-md-12 ms-sm-auto col-lg-12">
                        <div className="row justify-content-center">

                            <div className="d-flex justify-content-center" style={{ backgroundColor: "#fff" }}>
                                <div id="top" className="row col-12 col-md-11 col-lg-10 tab-news my-4">

                                    <Tabs defaultActiveKey="1" type="card" size="large">
                                        <TabPane tab="Tất cả" key="1">
                                            <NewsCards />
                                        </TabPane>
                                        <TabPane tab="Tin tức mới" key="2">
                                            <NewsCardsLatest />
                                        </TabPane>
                                        <TabPane tab="Tin tức nổi bật" key="3">
                                            <NewsCardsHot />
                                        </TabPane>
                                        <TabPane tab="Thông tin tài chính" key="4">
                                            <NewsCardsFinance />
                                        </TabPane>
                                        <TabPane tab="Video tin tức" key="5">

                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>

                        </div>

                        <div className="tu-van-card">
                            <div className="top-tu-van d-flex align-items-center justify-content-center">
                                <span className="top-title-tu-van">Quá nhiều thẻ tín dụng không biết nên chọn thẻ tín dụng nào ?</span>
                                <button onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }
                                } className="dg-card-v2-btn-dk01"
                                    style={{ backgroundColor: "#FFA20C" }}>
                                    <span>Tư vấn ngay</span>
                                    <img className="default-label" src={free} alt="free" />
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollToTopButton />
            </div>

            <CardCity />
        </>
    );
};

export default React.memo(TinTucPageView);
