import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { map } from 'lodash';
import './ComparePageView.scss';
import * as data from "@assets/data/data.module.json"
import { ModalAddCard } from "../../shared";
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import AppImage from "../../elements/AppImage/AppImage";
import { ICard } from "@types";
import { convertLink, onSubmit } from '@utils/constants';

const ComparePageView = () => {
    const [width, setWidth] = useState<number>(490);
    const [transform, setTransform] = useState<number>(0);
    const [isNext, setIsNext] = useState<boolean>(true);
    const [isModalAddCard, setIsModalAddCard] = useState<boolean>(false);
    const [cardsCompare, setCardsCompare] = useState<string[]>([]);
    const [showTopInfo, setShowTopInfo] = useState(false);
    const [card0, setCard0] = useState<ICard>();
    const [card1, setCard1] = useState<ICard>();
    const [card2, setCard2] = useState<ICard>();
    const [touchStartX, setTouchStartX] = useState<number | null>(null);
    const [pValue, setPValue] = useState<string | null>('');
    const [refId, setRefId] = useState<string | null>('');

    const decreaseWidthByOne = () => {
        setWidth((prevWidth) => (prevWidth > 1 ? window.innerWidth / 2 : prevWidth));
    };
    useEffect(() => {
        document.title = "So sánh thẻ";
        setPValue(localStorage.getItem("pValue"));
        setRefId(localStorage.getItem("refId"))

        // Get the existing cardsCompare array from localStorage
        const existingCardsCompare = JSON.parse(localStorage.getItem("cardsCompare") as string) || [];
        setCardsCompare(existingCardsCompare);
        setCard0(existingCardsCompare && existingCardsCompare.length > 0 && filterById(existingCardsCompare[0])[0]);
        setCard1(existingCardsCompare && existingCardsCompare.length > 1 && filterById(existingCardsCompare[1])[0]);
        setCard2(existingCardsCompare && existingCardsCompare.length > 2 && filterById(existingCardsCompare[2])[0]);
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setShowTopInfo(true);
            } else {
                setShowTopInfo(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // Event listener to track screen width changes
    useEffect(() => {
        decreaseWidthByOne();

        const handleResize = () => {
            const newWidth = window.innerWidth;
            if (newWidth < 1000) {
                decreaseWidthByOne();
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const openModal = () => {
        setIsModalAddCard(true);
    }

    const closeModal = () => {
        setIsModalAddCard(false);
        // Get the existing cardsCompare array from localStorage
        const existingCardsCompare = JSON.parse(localStorage.getItem("cardsCompare") as string) || [];
        setCardsCompare(existingCardsCompare);
    }
    const onCompare = (id: string) => {
        // Get the existing cardsCompare array from localStorage
        const existingCardsCompare = JSON.parse(localStorage.getItem("cardsCompare") as string) || [];

        // Check if the id exists in the array
        const index = existingCardsCompare.indexOf(id);

        // If the id exists, remove it from the array
        if (index !== -1) {
            existingCardsCompare.splice(index, 1);
        } else {
            // If the id doesn't exist, add it to the array
            existingCardsCompare.push(id);
        }

        // Store the updated array in localStorage
        localStorage.setItem("cardsCompare", JSON.stringify(existingCardsCompare));
        setCardsCompare(existingCardsCompare);
        setCard0(existingCardsCompare && existingCardsCompare.length > 0 && filterById(existingCardsCompare[0])[0]);
        setCard1(existingCardsCompare && existingCardsCompare.length > 1 && filterById(existingCardsCompare[1])[0]);
        setCard2(existingCardsCompare && existingCardsCompare.length > 2 && filterById(existingCardsCompare[2])[0]);
    };
    const onNext = useCallback(() => {
        setTransform(-(window.innerWidth / 2));
        setIsNext(false);
    }, []);

    const onPrev = useCallback(() => {
        setTransform(0);
        setIsNext(true);
    }, []);

    const filterById: (id: string) => any = (id: string) => {
        return [...data.partners, ...data.otherCards].filter(card => card.id === id);
    }

    const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        setTouchStartX(event.touches[0].clientX);
    }, []);

    const handleTouchEnd = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        if (touchStartX !== null) {
            const touchDistance = event.changedTouches[0].clientX - touchStartX;
            const swipeThreshold = 50;

            if (touchDistance > swipeThreshold) {
                onPrev();
            } else if (touchDistance < -swipeThreshold) {
                onNext();
            }

            setTouchStartX(null); // Reset touchStartX after handling the swipe
        }
    }, [onPrev, onNext, touchStartX]);

    const onSubmitLink = (card: ICard | undefined) => {
        if (card?.code === 'vpbankthe') {
            if (pValue === 'mvt-gocard') {
                window.location.replace("/dang-ky-thong-tin");
            } else {
                window.open(convertLink(pValue, card));
                onSubmit(card, pValue, refId);
            }
        } else {
            window.open(convertLink(pValue, card));
        }
    }
    return (
        <>
            <div className="dg-card-v2-wrapper_new">
                <div data-cmp-is="Breadcrumb" className="cmp-breadcrumb bread-crumb">
                    <i style={{ fontSize: "17px" }} className="material-icons opacity-6">home</i>
                    <a className="cmp-breadcrumb__page--home" href={`/`}>
                        Trang chủ</a>
                    &nbsp;
                    &nbsp;
                    <span className="cmp-breadcrumb__page--to">&gt;</span>
                    &nbsp;
                    &nbsp;
                    <span className="cmp-breadcrumb__page--current">So sánh thẻ </span>
                </div>
            </div>

            <div onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                tabIndex={0} id="compare-box-use">
                <div className="dg-card-v2-title-mobile-compare">
                    <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                        <div className="bx-viewport" style={{
                            width: "100%",
                            overflow: "hidden",
                            position: "relative",
                        }}>
                            <div className="dg-card-v2-slider-compare" style={{
                                width: "515%",
                                position: "relative",
                                transitionDuration: "0.5s",
                                transform: `translate3d(${transform}px, 0px, 0px)`
                            }}>
                                <div className="dg-card-v2-box-compare-content"
                                    style={{
                                        float: "left",
                                        listStyle: "none",
                                        position: "relative",
                                        width: `${width}px`
                                    }}>
                                    <span>{card0?.name}</span>
                                </div>
                                <div className="dg-card-v2-box-compare-content"
                                    style={{
                                        float: "left",
                                        listStyle: "none",
                                        position: "relative",
                                        width: `${width}px`
                                    }}>

                                    <span>{card1?.name}</span>
                                </div>
                                <div className="dg-card-v2-box-compare-content"
                                    style={{
                                        float: "left",
                                        listStyle: "none",
                                        position: "relative",
                                        width: `${width}px`
                                    }}>
                                    <span>{card2?.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dg-card-v2-header-compare-card">
                    <div className="dg-card-v2-wrapper_new">
                        <div className="dg-card-v2-slider-compare desktop">
                            <div className="dg-card-v2-box-compare-content">
                                {cardsCompare && cardsCompare[0] === undefined ?
                                    <div className="dg-card-v2-box_card_no_thing" onClick={openModal}>
                                        <a href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#popup-chonthemthe">
                                            <div className="dg-card-v2-img_box_card_no_thing" />
                                        </a>
                                        <div className="dg-card-v2-link_box_card_no_thing">
                                            <a href="javascript:void(0);"
                                                className="link">+ Thêm thẻ so sánh</a></div>
                                    </div> :
                                    <>
                                        <div className="dg-card-v2-img-compare-card ">
                                            <div className="img_card_effect">
                                                <a className="top-img  ui-card">
                                                    <div className="bg-card">
                                                        <AppImage className={"img-card1"}
                                                            pathImg={card0?.img} />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-info">
                                            <h3><a
                                                onClick={() => onSubmitLink(card0)}
                                                className="new-style-link">{card0?.name}</a>
                                            </h3>
                                            <a onClick={() => onSubmitLink(card0)}
                                                className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                            <p>
                                                <a href={`/chi-tiet-the/${card0?.id}`}
                                                    className="link new-style-link">Xem chi tiết</a>
                                            </p>
                                            <div className="dg-card-v2-chose-card-other" onClick={openModal}>
                                                <i className="icon-card-other" /> Chọn thẻ khác
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="dg-card-v2-box-compare-content">
                                {cardsCompare && cardsCompare[1] === undefined ?
                                    <div className="dg-card-v2-box_card_no_thing" onClick={openModal}>
                                        <a href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#popup-chonthemthe">
                                            <div className="dg-card-v2-img_box_card_no_thing" />
                                        </a>
                                        <div className="dg-card-v2-link_box_card_no_thing">
                                            <a href="javascript:void(0);"
                                                className="link">+ Thêm thẻ so sánh</a></div>
                                    </div> :
                                    <>
                                        <div className="dg-card-v2-img-compare-card ">
                                            <div className="img_card_effect">
                                                <a className="top-img  ui-card">
                                                    <div className="bg-card">
                                                        <AppImage className={"img-card1"}
                                                            pathImg={card1?.img} />

                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-info">
                                            <h3><a
                                                onClick={() => onSubmitLink(card1)}
                                                className="new-style-link">{card1?.name}</a>
                                            </h3>
                                            <a onClick={() => onSubmitLink(card1)}
                                                className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                            <p>
                                                <a href={`/chi-tiet-the/${card1?.id}`}
                                                    className="link new-style-link">Xem chi tiết</a>
                                            </p>
                                            <div className="dg-card-v2-chose-card-other" onClick={openModal}>
                                                <i className="icon-card-other" /> Chọn thẻ khác
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="dg-card-v2-box-compare-content">
                                {cardsCompare && cardsCompare[2] === undefined ?
                                    <div className="dg-card-v2-box_card_no_thing" onClick={openModal}>
                                        <a href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#popup-chonthemthe">
                                            <div className="dg-card-v2-img_box_card_no_thing" />
                                        </a>
                                        <div className="dg-card-v2-link_box_card_no_thing">
                                            <a href="javascript:void(0);"
                                                className="link">+ Thêm thẻ so sánh</a></div>
                                    </div> :
                                    <>
                                        <div className="dg-card-v2-img-compare-card ">
                                            <div className="img_card_effect">
                                                <a className="top-img  ui-card">
                                                    <div className="bg-card">
                                                        <AppImage className={"img-card1"}
                                                            pathImg={card2?.img} />

                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-info">
                                            <h3><a
                                                onClick={() => onSubmitLink(card2)}
                                                className="new-style-link">{card2?.name}</a>
                                            </h3>
                                            <a onClick={() => onSubmitLink(card2)}
                                                className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                            <p>
                                                <a href={`/chi-tiet-the/${card2?.id}`}
                                                    className="link new-style-link">Xem chi tiết</a>
                                            </p>
                                            <div className="dg-card-v2-chose-card-other" onClick={openModal}>
                                                <i className="icon-card-other" /> Chọn thẻ khác
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                        <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                            <div className="bx-viewport"
                                style={{ width: "100%", overflow: "hidden", position: "relative", height: "300px" }}>
                                <div className="dg-card-v2-slider-compare"
                                    style={{
                                        width: "515%",
                                        position: "relative",
                                        transitionDuration: "0.5s",
                                        transform: `translate3d(${transform}px, 0px, 0px)`
                                    }}>
                                    <div className="dg-card-v2-box-compare-content"
                                        style={{
                                            float: "left",
                                            listStyle: "none",
                                            position: "relative",
                                            width: `${width}px`
                                        }}>
                                        {cardsCompare && cardsCompare[0] === undefined ?
                                            <div className="dg-card-v2-box_card_no_thing" onClick={openModal}>
                                                <a href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#popup-chonthemthe">
                                                    <div className="dg-card-v2-img_box_card_no_thing" />
                                                </a>
                                                <div className="dg-card-v2-link_box_card_no_thing">
                                                    <a href="javascript:void(0);"
                                                        className="link">+ Thêm thẻ so sánh</a></div>
                                            </div> :
                                            <>
                                                <div className="dg-card-v2-img-compare-card ">
                                                    <div className="img_card_effect">
                                                        <a className="top-img ui-card">
                                                            <div className="bg-card">
                                                                <AppImage className={"img-card1"}
                                                                    pathImg={card0?.img} />

                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-info">
                                                    <a onClick={() => onSubmitLink(card0)}
                                                        className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                    <p>
                                                        <a href={`/chi-tiet-the/${card0?.id}`}
                                                            className="link new-style-link">Xem chi tiết</a>
                                                    </p>
                                                    <div className="dg-card-v2-chose-card-other" onClick={openModal}>
                                                        <i className="icon-card-other" /> Chọn thẻ khác
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div className="dg-card-v2-box-compare-content"
                                        style={{
                                            float: "left",
                                            listStyle: "none",
                                            position: "relative",
                                            width: `${width}px`
                                        }}>
                                        {cardsCompare && cardsCompare[1] === undefined ?
                                            <div className="dg-card-v2-box_card_no_thing" onClick={openModal}>
                                                <a href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#popup-chonthemthe">
                                                    <div className="dg-card-v2-img_box_card_no_thing" />
                                                </a>
                                                <div className="dg-card-v2-link_box_card_no_thing">
                                                    <a href="javascript:void(0);"
                                                        className="link">+ Thêm thẻ so sánh</a></div>
                                            </div> :
                                            <>
                                                <div className="dg-card-v2-img-compare-card ">
                                                    <div className="img_card_effect">
                                                        <a className="top-img  ui-card">
                                                            <div className="bg-card">
                                                                <AppImage className={"img-card1"}
                                                                    pathImg={card1?.img} />

                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-info">
                                                    <a onClick={() => onSubmitLink(card1)}
                                                        className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                    <p>
                                                        <a href={`/chi-tiet-the/${card1?.id}`}
                                                            className="link new-style-link">Xem chi tiết</a>
                                                    </p>
                                                    <div className="dg-card-v2-chose-card-other" onClick={openModal}>
                                                        <i className="icon-card-other" /> Chọn thẻ khác
                                                    </div>
                                                </div>
                                            </>}

                                    </div>
                                    <div className="dg-card-v2-box-compare-content"
                                        style={{
                                            float: "left",
                                            listStyle: "none",
                                            position: "relative",
                                            width: `${width}px`
                                        }}>
                                        {cardsCompare && cardsCompare[2] === undefined ?
                                            <div className="dg-card-v2-box_card_no_thing" onClick={openModal}>
                                                <a href="javascript:void(0);">
                                                    <div className="dg-card-v2-img_box_card_no_thing" />
                                                </a>
                                                <div className="dg-card-v2-link_box_card_no_thing">
                                                    <a href="javascript:void(0);"
                                                        className="link">+ Thêm thẻ so sánh</a></div>
                                            </div> :
                                            <>
                                                <div className="dg-card-v2-img-compare-card ">
                                                    <div className="img_card_effect">
                                                        <a className="top-img  ui-card">
                                                            <div className="bg-card">
                                                                <AppImage className={"img-card1"}
                                                                    pathImg={card2?.img} />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-info">
                                                    <a onClick={() => onSubmitLink(card2)}
                                                        className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                    <p>
                                                        <a href={`/chi-tiet-the/${card2?.id}`}
                                                            className="link new-style-link">Xem chi tiết</a>
                                                    </p>
                                                    <div className="dg-card-v2-chose-card-other" onClick={openModal}>
                                                        <i className="icon-card-other" /> Chọn thẻ khác
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="bx-controls bx-has-controls-direction">
                                <div className="bx-controls-direction">
                                    <a className={isNext ? "bx-prev disabled" : "bx-prev"} onClick={onPrev}>Prev</a>
                                    <a className={!isNext ? "bx-next disabled" : "bx-next"} onClick={onNext}>Next</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="dg-card-v2-box-content-compare-card">
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Thẻ tín dụng tốt nhất cho</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.the_tot_nhat}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.the_tot_nhat}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.the_tot_nhat}</p>
                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.the_tot_nhat}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.the_tot_nhat}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.the_tot_nhat}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Hạn mức thẻ</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.han_muc}
                                        </p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.han_muc}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.han_muc}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.han_muc}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.han_muc}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.han_muc}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Hạng thẻ</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.hang_the}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.hang_the}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.hang_the}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.hang_the}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.hang_the}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.hang_the}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Thương hiệu thẻ</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.thuong_hieu}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.thuong_hieu}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.thuong_hieu}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.thuong_hieu}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.thuong_hieu}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.thuong_hieu}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <span>Thu nhập tối thiểu</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.thu_nhap}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.thu_nhap}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.thu_nhap}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.thu_nhap}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.thu_nhap}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.thu_nhap}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Hoàn phí thường niên</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].hoan_phi_thuong_nien, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].hoan_phi_thuong_nien, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].hoan_phi_thuong_nien, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].hoan_phi_thuong_nien, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].hoan_phi_thuong_nien, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].hoan_phi_thuong_nien, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Hoàn tiền</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].hoan_tien, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].hoan_tien, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].hoan_tien, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].hoan_tien, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].hoan_tien, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].hoan_tien, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Tích điểm</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].tich_diem, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].tich_diem, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].tich_diem, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].tich_diem, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].tich_diem, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].tich_diem, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Chi tiêu/dặm</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].chi_tieu_dam, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].chi_tieu_dam, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].chi_tieu_dam, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].chi_tieu_dam, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].chi_tieu_dam, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].chi_tieu_dam, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Ưu đãi đặc quyền</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].uu_dai_dac_quyen, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].uu_dai_dac_quyen, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].uu_dai_dac_quyen, (content: string, index: number) => (
                                            <p key={index} dir="ltr">{content}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 0 && map(filterById(cardsCompare[0])[0].uu_dai_dac_quyen, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 1 && map(filterById(cardsCompare[1])[0].uu_dai_dac_quyen, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                {cardsCompare && cardsCompare.length > 2 && map(filterById(cardsCompare[2])[0].uu_dai_dac_quyen, (content: string, index: number) => (
                                                    <p key={index} dir="ltr">{content}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Thời gian miễn lãi tối đa</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.thoi_gian_mien_lai}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.thoi_gian_mien_lai}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.thoi_gian_mien_lai}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.thoi_gian_mien_lai}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.thoi_gian_mien_lai}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.thoi_gian_mien_lai}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Lãi suất</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.lai_suat}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.lai_suat}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.lai_suat}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.lai_suat}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.lai_suat}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.lai_suat}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí phát hành thẻ</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_phat_hanh_the}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_phat_hanh_the}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_phat_hanh_the}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_phat_hanh_the}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_phat_hanh_the}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_phat_hanh_the}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí thường niên thẻ chính</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_thuong_nien_the_chinh}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_thuong_nien_the_chinh}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_thuong_nien_the_chinh}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_thuong_nien_the_chinh}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_thuong_nien_the_chinh}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_thuong_nien_the_chinh}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí thường niên thẻ phụ</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_thuong_nien_the_phu}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_thuong_nien_the_phu}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_thuong_nien_the_phu}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_thuong_nien_the_phu}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_thuong_nien_the_phu}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_thuong_nien_the_phu}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí ứng/rút tiền mặt tại ATM</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_ung_rut_tien_mat}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_ung_rut_tien_mat}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_ung_rut_tien_mat}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_ung_rut_tien_mat}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_ung_rut_tien_mat}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_ung_rut_tien_mat}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Khoản thanh toán tối thiểu</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.khoan_thanh_toan_toi_thieu}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.khoan_thanh_toan_toi_thieu}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.khoan_thanh_toan_toi_thieu}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.khoan_thanh_toan_toi_thieu}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.khoan_thanh_toan_toi_thieu}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.khoan_thanh_toan_toi_thieu}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí chậm thanh toán</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_cham_thanh_toan}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_cham_thanh_toan}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_cham_thanh_toan}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_cham_thanh_toan}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_cham_thanh_toan}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_cham_thanh_toan}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí chuyển đổi trả góp</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_chuyen_doi_tra_gop}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_chuyen_doi_tra_gop}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_chuyen_doi_tra_gop}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_chuyen_doi_tra_gop}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_chuyen_doi_tra_gop}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_chuyen_doi_tra_gop}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí giao dịch ngoại tệ</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_giao_dich_ngoai_te}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_giao_dich_ngoai_te}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_giao_dich_ngoai_te}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_giao_dich_ngoai_te}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_giao_dich_ngoai_te}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_giao_dich_ngoai_te}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dg-card-v2-box-content-compare-card">
                        <div className="dg-card-v2-title-compare-card">
                            <div className="dg-card-v2-wrapper_new"><span>Phí vượt hạn mức tín dụng</span></div>
                        </div>
                        <div className="dg-card-v2-body-compare-card">
                            <div className="dg-card-v2-wrapper_new">
                                <div className="dg-card-v2-slider-compare desktop">
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card0?.phi_vuot_han_muc_tin_dung}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card1?.phi_vuot_han_muc_tin_dung}</p>

                                    </div>
                                    <div className="dg-card-v2-box-compare-content">
                                        <p dir="ltr">{card2?.phi_vuot_han_muc_tin_dung}</p>

                                    </div>
                                </div>
                                <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                    <div className="bx-viewport"
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            position: "relative",
                                            height: "auto"
                                        }}>
                                        <div className="dg-card-v2-slider-compare"
                                            style={{
                                                width: "515%",
                                                position: "relative",
                                                transitionDuration: "0.5s",
                                                transform: `translate3d(${transform}px, 0px, 0px)`
                                            }}>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card0?.phi_vuot_han_muc_tin_dung}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card1?.phi_vuot_han_muc_tin_dung}</p>

                                            </div>
                                            <div className="dg-card-v2-box-compare-content"
                                                style={{
                                                    float: "left",
                                                    listStyle: "none",
                                                    position: "relative",
                                                    width: `${width}px`
                                                }}>
                                                <p dir="ltr">{card2?.phi_vuot_han_muc_tin_dung}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showTopInfo &&
                    <div className="dg-card-v2-header-slider-clone scrolled">
                        <div className="dg-card-v2-wrapper_new">
                            <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                <div className="bx-viewport"
                                    style={{ width: "100%", overflow: "hidden", position: "relative", height: "145px" }}>
                                    <div className="bx-wrapper" style={{ maxWidth: "1000px", margin: "0px auto" }}>
                                        <div className="bx-viewport"
                                            style={{
                                                width: "100%",
                                                overflow: "hidden",
                                                position: "relative",
                                                height: "119px"
                                            }}>
                                            <div className="dg-card-v2-slider-compare"
                                                style={{
                                                    width: "515%",
                                                    position: "relative",
                                                    transitionDuration: "0.5s",
                                                    transform: `translate3d(${transform}px, 0px, 0px)`
                                                }}>
                                                <div className="dg-card-v2-box-compare-content"
                                                    style={{
                                                        float: "left",
                                                        listStyle: "none",
                                                        position: "relative",
                                                        width: `${width}px`
                                                    }}>
                                                    {cardsCompare && cardsCompare[0] === undefined ?
                                                        <div className="dg-card-v2-footer-compare" onClick={openModal}>
                                                            <a href="javascript:void(0);">
                                                                <div className="dg-card-v2-img-footer-compare" />
                                                            </a>
                                                            <div className="dg-card-v2-link-footer-compare"><a
                                                                href="javascript:void(0);" className="link">Thêm thẻ so
                                                                sánh</a></div>
                                                        </div> :
                                                        <>
                                                            <h3 style={{ height: "43px" }}><a
                                                                href={`/chi-tiet-the/${card0?.id}`}
                                                                className="new-style-link">
                                                                {card0?.name}</a>
                                                            </h3>
                                                            <a onClick={() => onSubmitLink(card0)}
                                                                className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                        </>
                                                    }
                                                </div>
                                                <div className="dg-card-v2-box-compare-content"
                                                    style={{
                                                        float: "left",
                                                        listStyle: "none",
                                                        position: "relative",
                                                        width: `${width}px`
                                                    }}>
                                                    {cardsCompare && cardsCompare[1] === undefined ?
                                                        <div className="dg-card-v2-footer-compare" onClick={openModal}>
                                                            <a href="javascript:void(0);">
                                                                <div className="dg-card-v2-img-footer-compare" />
                                                            </a>
                                                            <div className="dg-card-v2-link-footer-compare"><a
                                                                href="javascript:void(0);" className="link">Thêm thẻ so
                                                                sánh</a></div>
                                                        </div> :
                                                        <>
                                                            <h3 style={{ height: "43px" }}><a
                                                                href={`/chi-tiet-the/${card1?.id}`}
                                                                className="new-style-link">
                                                                {card1?.name}</a>
                                                            </h3>
                                                            <a onClick={() => onSubmitLink(card1)}
                                                                className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                        </>
                                                    }
                                                </div>
                                                <div className="dg-card-v2-box-compare-content"
                                                    style={{
                                                        float: "left",
                                                        listStyle: "none",
                                                        position: "relative",
                                                        width: `${width}px`
                                                    }}>
                                                    {cardsCompare && cardsCompare[2] === undefined ?
                                                        <div className="dg-card-v2-footer-compare" onClick={openModal}>
                                                            <a href="javascript:void(0);">
                                                                <div className="dg-card-v2-img-footer-compare" />
                                                            </a>
                                                            <div className="dg-card-v2-link-footer-compare"><a
                                                                href="javascript:void(0);" className="link">Thêm thẻ so
                                                                sánh</a></div>
                                                        </div> :
                                                        <>
                                                            <h3 style={{ height: "43px" }}><a
                                                                href={`/chi-tiet-the/${card2?.id}`}
                                                                className="new-style-link">
                                                                {card2?.name}</a>
                                                            </h3>
                                                            <a onClick={() => onSubmitLink(card2)}
                                                                className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>}
            </div>
            {isModalAddCard && <ModalAddCard onCompare={onCompare} closeModal={closeModal} />}
            <ScrollToTopButton />
        </>
    );
}

export default React.memo(ComparePageView);
