import React, { useEffect, useState } from 'react';
import "./CreditPageView.scss"
import * as data from "@assets/data/data.module.json"
import AppImage from "../../elements/AppImage/AppImage";
import { map } from 'lodash';
import "@assets/css/landing-page.css"
import start_rating from '@assets/images/star_rating.png'
import brand from '@assets/images/brand.png'
import {
    CardCity, ModalConfirm,
    // ModalConfirm,
    ModalDetailCard, ModalPrivacy, ModalPrivacyConfirm
} from "../../shared";
import { useLocation } from 'react-router-dom';
import free from "@assets/images/label-free.png"
import hot from "@assets//images/bage_hot.svg"
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import CardCompare from "../../shared/CardCompare/CardCompare";
import { ICard } from "@types";
import {
    convertLink,
    convert_trieu,
    convert_ty,
    convert_thu_nhap,
    validation,
    onSubmit,
} from '@utils/constants';
import { Select } from "@components/elements";
import { Select2Data, Select2UpdateValue } from "select2-react-component";
import aim from "@assets/images/ico-aim.png"
import touch from "@assets/images/ico-touch.png"
import bank from "@assets/images/ico-bank.png";
import ModalLead from "../../shared/ModalLead/ModalLead";
import { ILead } from "../HomePageView/HomePageView";
import axiosInstance from "@utils/axiosInstance";

const CreditPageView = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const p = queryParams.get('p');
    const [lead, setLead] = useState<ILead>({
        lead_status: 0,
        name: "",
        email: "",
        phone: "",
        idCard: "",
        source: p ?? "gocard",
        confirm: "Tôi đồng ý và muốn sử dụng dịch vụ"
    });
    const [query, setQuery] = useState({ aim: "", income: "", bank: "" })
    const [errors, setErrors] = useState({
        name: "",
        phone: "",
        email: "",
        idCard: ""
    })
    const [cards, setCards] = useState<ICard[]>([]);
    const [otherCards, setOtherCards] = useState<ICard[]>([]);
    const [visibleCardCount, setVisibleCardCount] = useState(6);
    const [visibleOtherCardCount, setVisibleOtherCardCount] = useState(6);
    const [card, setCard] = useState<ICard>();
    const [cardsCompare, setCardsCompare] = useState<string[]>();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isPrivacy, setIsPrivacy] = useState<boolean>(false);
    const [isBtnMoreCard, setIsBtnMoreCard] = useState<boolean>(true);
    const [isBtnMoreOtherCard, setIsBtnMoreOtherCard] = useState<boolean>(true);
    const [value, setValue] = useState<string>("");
    const [value1, setValue1] = useState<number>(0);
    const [isPrivacyConfirm, setIsPrivacyConfirm] = useState<boolean>(false);
    const [pValue, setPValue] = useState<string | null>('');
    const [refId, setRefId] = useState<string | null>('');
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString('en-US', {
        month: '2-digit',
        year: 'numeric',
    });
    const incomes: Select2Data = JSON.parse(JSON.stringify([
        { value: "Dưới 7 triệu", label: "Dưới 7 triệu" },
        { value: "Từ 7 triệu - 10 triệu", label: "Từ 7 triệu - 10 triệu" },
        { value: "Từ 10 triệu - 15 triệu", label: "Từ 10 triệu - 15 triệu" },
        { value: "Từ 15 triệu - 20 triệu", label: "Từ 15 triệu - 20 triệu" },
        { value: "Trên 20 triệu", label: "Trên 20 triệu" },
    ]));
    const sorts: Select2Data = JSON.parse(JSON.stringify([
        { value: "Đánh giá cao", label: "Đánh giá cao" },
        { value: "Hạn mức cao nhất", label: "Hạn mức cao nhất" },
        { value: "Hạn mức thấp nhất", label: "Hạn mức thấp nhất" },
        { value: "Phí thường niên cao nhất", label: "Phí thường niên cao nhất" },
        { value: "Phí thường niên thấp nhất", label: "Phí thường niên thấp nhất" },
        { value: "Yêu cầu thu nhập cao nhất", label: "Yêu cầu thu nhập cao nhất" },
        { value: "Yêu cầu thu nhập thấp nhất", label: "Yêu cầu thu nhập thấp nhất" },
    ]));
    const aimUses: Select2Data = JSON.parse(JSON.stringify([
        { value: "Hoàn tiền", label: "Hoàn tiền" },
        { value: "Tích điểm", label: "Tích điểm thưởng" },
        { value: "Trả góp 0%", label: "Trả góp lãi suất 0%" },
        { value: "Giảm giá khi mua sắm", label: "Giảm giá khi mua sắm" },
        { value: "Rút tiền mặt", label: "Rút tiền mặt" },
    ]));
    const banks: Select2Data = JSON.parse(JSON.stringify([
        { value: "vib", label: "Ngân Hàng Quốc Tế Việt Nam (VIB)" },
        { value: "vpbank", label: "Ngân hàng TMCP Việt Nam Thịnh Vượng (VPBank)" },
        { value: "vietcombank", label: "Ngân hàng TMCP Ngoại thương Việt Nam (Vietcombank)" },
        { value: "tpbank", label: "Ngân hàng Thương mại Cổ phần Tiên Phong (TPBank)" },
        { value: "agribank", label: "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam (Agribank)" },
        { value: "hsbc", label: "Ngân hàng TNHH Một thành viên HSBC (HSBC)" },
        { value: "techcombank", label: "Ngân hàng Thương mại cổ phần Kỹ thương Việt Nam (Techcombank)" },
    ]));
    useEffect(() => {
        document.title = "Thẻ tín dụng";
        setPValue(localStorage.getItem("pValue"));
        setRefId(localStorage.getItem("refId"))
        setVisibleCardCount((prevCount) => prevCount + 6);
        setVisibleOtherCardCount((prevCount) => prevCount + 6);
        filterCard(null);
        filterOtherCard(null);
        localStorage.removeItem("cardsCompare");
    }, [data.partners]);

    const openModal = (data: ICard) => {
        setIsOpenModal(true);
        setCard(data);
    }

    const closeModal = () => {
        setIsOpenModal(false);
    }
    const loadMoreOtherCard = () => {
        setVisibleOtherCardCount((prevCount) => prevCount + 6); // Increase the visible item count by 6
    };
    const moreOtherCard = () => {
        loadMoreOtherCard();
        filterOtherCard(null);
    }
    const loadMoreCard = () => {
        setVisibleCardCount((prevCount) => prevCount + 6); // Increase the visible item count by 6
    };
    const moreCard = () => {
        loadMoreCard();
        filterCard(null);
    }
    const filterOtherCard = (type: string | null) => {
        const filteredOtherCards: ICard[] = data.otherCards
            .sort((a, b) => a.stt - b.stt)
            .filter((d) => type ? d.type === type : d.type !== "");
        setOtherCards(filteredOtherCards.slice(0, visibleOtherCardCount));

        if (otherCards.length == data.otherCards.length) {
            setIsBtnMoreOtherCard(false);
        }
        closeCollapse();
    }
    const filterCard = (type: string | null) => {
        const filteredCards: ICard[] = data.partners
            .sort((a, b) => a.stt - b.stt)
            .filter((d) => type ? d.type === type : d.type !== "");
        setCards(filteredCards.slice(0, visibleCardCount));

        if (cards.length == data.partners.length) {
            setIsBtnMoreCard(false);
        }
        closeCollapse();
    }

    const closeCollapse = () => {
        //close collapse
        const collapseContent = document.getElementsByClassName("collapse-content");
        if (collapseContent) {
            for (let i = 0; i < collapseContent.length; i++) {
                collapseContent[i].classList.remove("show");
            }
        }
        const collapseHeader = document.getElementById("collapse-header");
        if (collapseHeader) {
            collapseHeader.classList.add("collapsed");
        }
    }

    const onCompare = (id: string) => {
        // Get the existing cardsCompare array from localStorage
        const existingCardsCompare = JSON.parse(localStorage.getItem("cardsCompare") as string) || [];

        // Check if the id exists in the array
        const index = existingCardsCompare.indexOf(id);

        // If the id exists, remove it from the array
        if (index !== -1) {
            existingCardsCompare.splice(index, 1);
        } else {
            // If the id doesn't exist, add it to the array
            existingCardsCompare.push(id);
        }

        // Store the updated array in localStorage
        localStorage.setItem("cardsCompare", JSON.stringify(existingCardsCompare));
        setCardsCompare(existingCardsCompare);
    };

    const sort_phi_thuong_nien = (sortType: string) => {
        let filterCards: ICard[];
        filterCards = data.partners.sort((a, b) => {
            if (sortType === "desc") {
                return (convert_phi_thuong_nien(b.phi_thuong_nien) - convert_phi_thuong_nien(a.phi_thuong_nien));
            } else {
                return (convert_phi_thuong_nien(a.phi_thuong_nien) - convert_phi_thuong_nien(b.phi_thuong_nien));
            }
        })
        setCards([...filterCards]);
        closeCollapse();
    }

    const convert_phi_thuong_nien: (string: string) => number = (string: string) => {
        let numericValue: string;
        numericValue = string.trim().replace(/\D/g, "");
        return parseInt(numericValue);
    }

    const sort_thu_nhap = (sortType: string) => {
        let filterCards: ICard[];
        filterCards = data.partners.sort((a, b) => {
            if (sortType === "desc") {
                return (convert_thu_nhap(b.thu_nhap) - convert_thu_nhap(a.thu_nhap));
            } else {
                return (convert_thu_nhap(a.thu_nhap) - convert_thu_nhap(b.thu_nhap));
            }
        })
        setCards([...filterCards]);
        closeCollapse();
    }

    const sort_han_muc = (sortType: string) => {
        const filterCards: ICard[] = data.partners;
        let cardType: ICard[];
        let cardTrieu: ICard[];

        cardType = filterCards.filter((c) => c.han_muc.includes("Tỷ")).sort((a, b) => {
            if (sortType === "desc") {
                return (convert_ty(b.han_muc) - convert_ty(a.han_muc));
            } else {
                return (convert_ty(a.han_muc) - convert_ty(b.han_muc));
            }
        })
        cardType = cardType.filter((c) => c.han_muc.includes("Tỷ")).sort((a, b) => {
            if (sortType === "desc") {
                return (convert_trieu(b.han_muc) - convert_trieu(a.han_muc));
            } else {
                return (convert_trieu(a.han_muc) - convert_trieu(b.han_muc));
            }
        })
        cardTrieu = data.partners.filter((c) => !c.han_muc.includes("Tỷ")).sort((a, b) => {
            if (sortType === "desc") {
                return (convert_trieu(b.han_muc) - convert_trieu(a.han_muc));
            } else {
                return (convert_trieu(a.han_muc) - convert_trieu(b.han_muc));
            }
        })
        if (sortType === "desc") {
            setCards([...cardType, ...cardTrieu]);
        } else {
            setCards([...cardTrieu, ...cardType])
        }
        closeCollapse();
    }
    const validateForm = () => {
        const { name, phone, idCard, email } = lead;
        const newErrors = {
            name: !name ? "Vui lòng nhập trường này" : "",
            email: !email ? "Vui lòng nhập email" : "",
            phone: !phone
                ? "Vui lòng nhập trường này"
                : !validation.isNumberPhone(phone)
                    ? "Nhập sai định dạng"
                    : "",
            idCard: !idCard
                ? "Vui lòng nhập trường này"
                : !validation.isIdCard(idCard)
                    ? "Nhập sai định dạng"
                    : ""
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error);
    };


    const onSelectAimQuery = (e: Select2UpdateValue) => {
        const value = e as string;
        setQuery(lead => ({
            ...lead,
            aim: value ? value : ""
        }))
    }
    const onSelectIncomeQuery = (e: Select2UpdateValue) => {
        const value = e as string;
        setQuery(lead => ({
            ...lead,
            income: value ? value : ""
        }))
    }
    const onSelectBankQuery = (e: Select2UpdateValue) => {
        const value = e as string;
        setQuery(lead => ({
            ...lead,
            bank: value ? value : ""
        }))
    }

    const onSearch = () => {
        let filteredCards: ICard[] = data.partners
            .sort((a, b) => a.stt - b.stt)
            .filter((d) => {
                if (query.aim && query.bank) {
                    return d.label.includes(query.aim) && d.type === query.bank;
                } else if (query.aim) {
                    return d.label.includes(query.aim);
                } else if (query.bank) {
                    return d.type === query.bank;
                } else {
                    return true; // No filters applied, include all items
                }
            });
        if (query.income) {
            filteredCards = filterThuNhap(query.income, filteredCards);
        }
        let filteredOtherCards: ICard[] = data.otherCards
            .sort((a, b) => a.stt - b.stt)
            .filter((d) => {
                if (query.aim && query.bank) {
                    return d.label.includes(query.aim) && d.type === query.bank;
                } else if (query.aim) {
                    return d.label.includes(query.aim);
                } else if (query.bank) {
                    return d.type === query.bank;
                } else {
                    return true; // No filters applied, include all items
                }
            });
        if (query.income) {
            filteredCards = filterThuNhap(query.income, filteredCards);
            filteredOtherCards = filterThuNhap(query.income, filteredOtherCards);
        }
        setCards(filteredCards.slice(0, visibleCardCount));
        setOtherCards(filteredOtherCards.slice(0, visibleCardCount));

        setIsBtnMoreCard(false);
        closeCollapse();
    }
    const filter_favorite = () => {
        const filteredCards: ICard[] = data.partners
            .filter(card => card.badge === 'hot');
        setCards(filteredCards);
    }
    const handleSortByChange = (e: Select2UpdateValue) => {
        const selectedValue = e as string;
        setValue(selectedValue);
        // Call the appropriate function based on the selected option value
        switch (selectedValue) {
            case 'Đánh giá cao':
                filter_favorite();
                break;
            case 'Hạn mức thấp nhất':
                sort_han_muc("asc");
                break;
            case 'Hạn mức cao nhất':
                sort_han_muc("desc");
                break;
            case 'Phí thường niên thấp nhất':
                sort_phi_thuong_nien("asc");
                break;
            case 'Phí thường niên cao nhất':
                sort_phi_thuong_nien("desc");
                break;
            case 'Yêu cầu thu nhập thấp nhất':
                sort_thu_nhap("asc");
                break;
            case 'Yêu cầu thu nhập cao nhất':
                sort_thu_nhap("desc");
                break;
            default:
                onSearch();
                break;
        }
    };
    const filterThuNhap = (income: string, cards: ICard[]) => {
        if (income === 'Dưới 7 triệu') {
            cards = cards.filter(card => convert_thu_nhap(card.thu_nhap) < 7);
        } else if (income === 'Từ 7 triệu - 10 triệu') {
            cards = cards.filter(card => convert_thu_nhap(card.thu_nhap) < 10 && convert_thu_nhap(card.thu_nhap) >= 7);
        } else if (income === 'Từ 10 triệu - 15 triệu') {
            cards = cards.filter(card => convert_thu_nhap(card.thu_nhap) < 15 && convert_thu_nhap(card.thu_nhap) >= 10);
        } else if (income === 'Từ 15 triệu - 20 triệu') {
            cards = cards.filter(card => convert_thu_nhap(card.thu_nhap) < 20 && convert_thu_nhap(card.thu_nhap) >= 15);
        } else if (income === 'Trên 20 triệu') {
            cards = cards.filter(card => convert_thu_nhap(card.thu_nhap) > 20);
        }
        return cards;
    }
    const openModalPrivacyConfirm = () => {
        if (validateForm()) {
            setIsPrivacyConfirm(true);
            axiosInstance.post(`/filter/check`, lead).then(response => {
                if (response.status === 200) {
                    setValue1(response.data);
                    setLead(lead => ({
                        ...lead,
                        lead_status: response.data
                    })
                    )
                }
            })
        }
    }

    const onSubmitLink = (card: ICard | undefined) => {
        if (card?.direct === 1) {
            if (card?.code === 'vpbankthe') {
                if (pValue === 'mvt-gocard') {
                    window.location.replace("/dang-ky-thong-tin");
                } else {
                    window.open(convertLink(pValue, card));
                    onSubmit(card, pValue, refId);
                }
            } else {
                window.open(convertLink(pValue, card));
            }
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <main className="col-md-12 ms-sm-auto col-lg-12">
                        <div className="row justify-content-center">
                            <div className="banner-form">
                                <h1></h1>
                                <div className="form-home">
                                    <div className="text-form text-center">
                                        Tư vấn nhanh thẻ tín dụng phù hợp nhất với bạn
                                    </div>
                                    <ModalLead setLead={setLead} lead={lead} errors={errors} setErrors={setErrors}
                                        openModalPrivacyConfirm={openModalPrivacyConfirm}
                                        setIsPrivacy={() => setIsPrivacy(true)} />

                                </div>
                            </div>
                            <div id="filteredPartner" className="d-flex align-items-center mb-0"
                                style={{ paddingBottom: "16px" }}>
                                <div className="container col-12 col-md-11 col-lg-10 ">
                                    <div className="row credit-search">
                                        <div className="label_result tim_kiem_the_tin_dung">
                                            <span>TÌM KIẾM THẺ TÍN DỤNG</span>
                                        </div>

                                        <div className="col-md-4 col-12 d-flex justify-content-center">
                                            <Select placeholder="Mục đích sử dụng thẻ" onSelect={onSelectAimQuery}
                                                value={query.aim} error="" label="" data={aimUses}
                                                required={false} image={aim}
                                            />
                                        </div>
                                        <div className="col-md-4 col-12 d-flex justify-content-center">
                                            <Select placeholder="Chọn mức thu nhập" onSelect={onSelectIncomeQuery}
                                                value={query.income} error="" label="" data={incomes}
                                                required={false} image={touch}
                                            />
                                        </div>
                                        <div className="col-md-4 col-12 d-flex justify-content-center">
                                            <Select placeholder="Ngân hàng phát hành thẻ" onSelect={onSelectBankQuery}
                                                value={query.bank} error="" label="" data={banks}
                                                required={false} image={bank}
                                            />
                                        </div>

                                        <div className="col-12 text-center">
                                            <button className="btn-search" onClick={onSearch}>
                                                <span>Tìm kiếm</span>
                                            </button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="d-flex justify-content-center" style={{ backgroundColor: "#fff" }}>
                                <div className="row col-12 col-md-11 col-lg-10 card-list__list-item-display">
                                    <div
                                        className="label_result d-flex justify-content-between mb-2 align-items-center">
                                        <span
                                            className="uk-visible@m">Top thẻ tín dụng được mở nhiều nhất tháng {formattedDate}</span>
                                        <div className="sort d-flex align-items-center">
                                            <div className="sort-title">Sắp xếp:&nbsp;&nbsp;</div>
                                            <Select placeholder="Mặc định" onSelect={handleSortByChange}
                                                value={value} error="" label="" data={sorts}
                                                required={false} image={null}
                                            />
                                        </div>
                                    </div>
                                    {map(cards, (data: ICard, index) => (
                                        <div key={index} className="col-sm-6 col-lg-4 card-item">
                                            <div className="card-item__wrap">
                                                <div className="card-item__image-container">
                                                    <a onClick={() => onSubmitLink(data)}>
                                                        <AppImage className={"card-item__image-container--card-image"}
                                                            pathImg={data.img} style={"70%"} />
                                                    </a>
                                                    <div className="card-item__image-container--tag">{data.label}</div>
                                                    {data.badge && data.badge === 'hot' &&
                                                        <div className="card-item__image-container--badge">
                                                            <img src={hot} alt="badge" />
                                                        </div>}
                                                </div>
                                                <div className="CardBank_compareAction__1ttUE">
                                                    <button type="button"
                                                        onClick={() => onCompare(data.id)}
                                                        className={`title Button_Button__zmFXg Button_White__vjLjB Button_boxShadow__XcPM0 ${cardsCompare?.includes(data.id) ? 'active' : ''} ${cardsCompare && cardsCompare.length > 2 && !cardsCompare?.includes(data.id) ? 'disabled' : ''}`}
                                                        style={{ justifyContent: "space-between" }}>
                                                        {!cardsCompare?.includes(data.id) &&
                                                            <span className="Button_text__d9Ltb"> Thêm vào so sánh</span>}
                                                        {cardsCompare?.includes(data.id) &&
                                                            <span
                                                                className="Button_text__d9Ltb"> Đã thêm vào so sánh</span>}
                                                        <div className="Button_iconSvg__mjjl_">
                                                            {!cardsCompare?.includes(data.id) &&
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="Button_icon__NZexy icon-svg">
                                                                    <path id="svg-icon"
                                                                        d="M16 8C16 8.63118 15.4883 9.14286 14.8571 9.14286H9.14286V14.8571C9.14286 15.4883 8.63118 16 8 16C7.36882 16 6.85714 15.4883 6.85714 14.8571V9.14286H1.14286C0.511674 9.14286 0 8.63118 0 8C0 7.36882 0.511674 6.85714 1.14286 6.85714H6.85714V1.14286C6.85714 0.511674 7.36882 0 8 0C8.63118 0 9.14286 0.511674 9.14286 1.14286V6.85714H14.8571C15.4883 6.85714 16 7.36882 16 8Z"
                                                                        fill="#1E0BA2" />
                                                                </svg>}
                                                            {cardsCompare?.includes(data.id) &&
                                                                <>{cardsCompare?.length}/3 thẻ</>
                                                            }
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="card-item__description">
                                                    <h3 className="name" style={{ height: "2.7em" }}>
                                                        <a onClick={() => onSubmitLink(data)}>Thẻ tín
                                                            dụng {data.name}</a>
                                                    </h3>

                                                    <p className="description">
                                                        <div className="d-flex justify-content-between"
                                                            style={{ "marginTop": "10px" }}>
                                                            <span className="partner-item-label">Hạn mức:&nbsp;</span>
                                                            <span
                                                                className="partner-item-value">{data.han_muc}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span
                                                                className="partner-item-label">Phí thường niên:&nbsp;</span>
                                                            <span
                                                                className="partner-item-value price-format">{data.phi_thuong_nien}đ</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="partner-item-label">Thu nhập:&nbsp;</span>
                                                            <span
                                                                className="partner-item-value">{data.thu_nhap}</span>
                                                        </div>
                                                    </p>
                                                    <div className="content-more-rating d-flex justify-content-evenly">
                                                        <div className="rating d-flex align-items-start">
                                                            <img style={{ width: "19px" }} src={start_rating} alt="icon" />
                                                            <span style={{ fontSize: "13px" }}>&nbsp;5.0</span>
                                                        </div>
                                                        <div className="rating d-flex align-items-start">
                                                            <img style={{ width: "19px" }} src={brand} alt="icon" />
                                                            <span
                                                                style={{ fontSize: "13px" }}>&nbsp;1000+ đã mở thẻ</span>
                                                        </div>
                                                    </div>
                                                    <div className="title-detail text-center mt-3 mb-3">
                                                        <span style={{
                                                            fontSize: "15px",
                                                            fontWeight: 500,
                                                            textDecoration: "underline"
                                                        }} onClick={() => openModal(data)}>Xem chi tiết</span>
                                                    </div>
                                                </div>

                                                <div className="card-item__action">
                                                    <a onClick={() => onSubmitLink(data)}>
                                                        <a className="dg-card-v2-btn-dk01 home">
                                                            Đăng ký ngay&nbsp;
                                                            <i className="fa fa-arrow-right" aria-hidden="true" />
                                                        </a>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {isBtnMoreCard &&
                                <div className="see-more text-center my-3">
                                    <span onClick={moreCard}>Xem nhiều hơn&nbsp;
                                        <i className="fa fa-plus" aria-hidden="true" /></span>
                                </div>
                            }
                            <div className="d-flex justify-content-center" style={{ backgroundColor: "#fff" }}>
                                <div className="row col-12 col-md-11 col-lg-10 card-list__list-item-display">
                                    <div className="label_result text-center mb-4">
                                        <span>Xem thêm thẻ tín dụng các ngân hàng khác</span>
                                    </div>
                                    {map(otherCards, (data: ICard, index) => (
                                        <div key={index} className="col-sm-6 col-lg-4 card-item">
                                            <div className="card-item__wrap">
                                                <div className="card-item__image-container">
                                                    <a onClick={() => onSubmitLink(data)}>
                                                        <AppImage className={"card-item__image-container--card-image"}
                                                            pathImg={data.img} style={"70%"} />
                                                    </a>
                                                    <div className="card-item__image-container--tag">{data.label}</div>
                                                    {data.badge && data.badge === 'hot' &&
                                                        <div className="card-item__image-container--badge">
                                                            <img src={hot} alt="badge" />
                                                        </div>}
                                                </div>
                                                <div className="CardBank_compareAction__1ttUE">
                                                    <button type="button"
                                                        onClick={() => onCompare(data.id)}
                                                        className={`title Button_Button__zmFXg Button_White__vjLjB Button_boxShadow__XcPM0 ${cardsCompare?.includes(data.id) ? 'active' : ''} ${cardsCompare && cardsCompare.length > 2 && !cardsCompare?.includes(data.id) ? 'disabled' : ''}`}
                                                        style={{ justifyContent: "space-between" }}>
                                                        {!cardsCompare?.includes(data.id) &&
                                                            <span className="Button_text__d9Ltb"> Thêm vào so sánh</span>}
                                                        {cardsCompare?.includes(data.id) &&
                                                            <span
                                                                className="Button_text__d9Ltb"> Đã thêm vào so sánh</span>}
                                                        <div className="Button_iconSvg__mjjl_">
                                                            {!cardsCompare?.includes(data.id) &&
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="Button_icon__NZexy icon-svg">
                                                                    <path id="svg-icon"
                                                                        d="M16 8C16 8.63118 15.4883 9.14286 14.8571 9.14286H9.14286V14.8571C9.14286 15.4883 8.63118 16 8 16C7.36882 16 6.85714 15.4883 6.85714 14.8571V9.14286H1.14286C0.511674 9.14286 0 8.63118 0 8C0 7.36882 0.511674 6.85714 1.14286 6.85714H6.85714V1.14286C6.85714 0.511674 7.36882 0 8 0C8.63118 0 9.14286 0.511674 9.14286 1.14286V6.85714H14.8571C15.4883 6.85714 16 7.36882 16 8Z"
                                                                        fill="#1E0BA2" />
                                                                </svg>}
                                                            {cardsCompare?.includes(data.id) &&
                                                                <>{cardsCompare?.length}/3 thẻ</>
                                                            }
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="card-item__description">
                                                    <h3 className="name" style={{ height: "2.7em" }}>
                                                        <a onClick={() => onSubmitLink(data)}>Thẻ tín
                                                            dụng {data.name}</a>
                                                    </h3>

                                                    <p className="description">
                                                        <div className="d-flex justify-content-between"
                                                            style={{ "marginTop": "10px" }}>
                                                            <span className="partner-item-label">Hạn mức:&nbsp;</span>
                                                            <span
                                                                className="partner-item-value">{data.han_muc}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span
                                                                className="partner-item-label">Phí thường niên:&nbsp;</span>
                                                            <span
                                                                className="partner-item-value price-format">{data.phi_thuong_nien}đ</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="partner-item-label">Thu nhập:&nbsp;</span>
                                                            <span
                                                                className="partner-item-value">{data.thu_nhap}</span>
                                                        </div>
                                                    </p>
                                                    <div className="content-more-rating d-flex justify-content-evenly">
                                                        <div className="rating d-flex align-items-start">
                                                            <img style={{ width: "19px" }} src={start_rating} alt="icon" />
                                                            <span style={{ fontSize: "13px" }}>&nbsp;5.0</span>
                                                        </div>
                                                        <div className="rating d-flex align-items-start">
                                                            <img style={{ width: "19px" }} src={brand} alt="icon" />
                                                            <span
                                                                style={{ fontSize: "13px" }}>&nbsp;1000+ đã mở thẻ</span>
                                                        </div>
                                                    </div>
                                                    <div className="title-detail text-center mt-3 mb-3">
                                                        <span style={{
                                                            fontSize: "15px",
                                                            fontWeight: 500,
                                                            textDecoration: "underline"
                                                        }} onClick={() => openModal(data)}>Xem chi tiết</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {isBtnMoreOtherCard &&
                                <div className="see-more text-center py-3" style={{ backgroundColor: "#F5F5F5" }}>
                                    <span onClick={moreOtherCard}>Xem nhiều hơn&nbsp;
                                        <i className="fa fa-plus" aria-hidden="true" /></span>
                                </div>
                            }
                        </div>

                        <div className="tu-van-card">
                            <div className="top-tu-van d-flex align-items-center justify-content-center">
                                <span className="top-title-tu-van">Quá nhiều thẻ tín dụng không biết nên chọn thẻ tín dụng nào ?</span>
                                <button onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }
                                } className="dg-card-v2-btn-dk01"
                                    style={{ backgroundColor: "#FFA20C" }}>
                                    <span>Tư vấn ngay</span>
                                    <img className="default-label" src={free} alt="free" />
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollToTopButton />
            </div>
            {cardsCompare && cardsCompare?.length > 0 &&
                <CardCompare onCompare={onCompare} cardsCompare={cardsCompare} />}
            {isOpenModal && <ModalDetailCard data={card} closeModal={closeModal} isOpenModal={isOpenModal} />}
            <CardCity />
            {isPrivacy && <ModalPrivacy closeModal={() => setIsPrivacy(false)} />}
            {isSuccess && <ModalConfirm closeModal={() => setIsSuccess(false)} value={value1} />}
            {isPrivacyConfirm &&
                <ModalPrivacyConfirm closeModal={() => setIsPrivacyConfirm(false)} lead={lead} setLead={setLead}
                    openConfirm={() => setIsSuccess(true)} />}

        </>
    );
};

export default React.memo(CreditPageView);
