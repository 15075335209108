import './ManagingUnitPageView.scss';
import React from 'react';

const ManagingUnitPageView = () => {

    return (
        <div className='container'>
            <h1>Đơn vị chủ quản</h1>
            <span>
                Công ty Cổ phần Công nghệ và Truyền thông Dagoras.
            </span>
            <span>
                Trụ sở: Tầng 08, 80 Duy Tân, Cầu Giấy, Hà Nội.
            </span>
            <span>
                Hotline: 024.6689.0248
            </span>
            <span>
                Email: support@gocard.vn
            </span>
        </div>
    );
}

export default React.memo(ManagingUnitPageView);