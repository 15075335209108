import React, {useState} from 'react';
import './ReceiveOTPPageView.scss';
import phoneIcon from '@assets/icon/phone-icon.svg';
import emailIcon from '@assets/icon/email-icon.svg';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import {notification, Spin} from 'antd';

interface RouteParams {
    type: string;
}

interface LocationState {
    phone: any;
    emailUsername: any;
    emailDomain: any;
    partner?: string;
}

const ReceiveOTPPageView: React.FC = () => {
    const history = useHistory();

    const [api, contextHolder] = notification.useNotification();
    const [isDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const {type} = useParams<RouteParams>();
    const location = useLocation<LocationState>();
    const {phone, emailUsername, emailDomain, partner} = location.state || {};

    const query = new URLSearchParams(location.search);
    const partnerFromQuery = query.get('partner');

    const finalPartner = partner || partnerFromQuery;

    const cutPhoneNumber = phone.substring(phone.length - 3);

    const cutEmailUsername = emailUsername ? emailUsername.slice(0, 3) + "*****" : '';
    const maskedEmail = `${cutEmailUsername}@${emailDomain}`;

    const handleReceivePhoneOtp = async (step: string) => {
        setLoading(true);
        const sessionKey = type === 'card' ? 'cardInfo' : 'loanInfo';

        const otpSentTime = sessionStorage.getItem('otpSentTime');
        const currentTime = Date.now();

        if (otpSentTime && (currentTime - parseInt(otpSentTime)) < 120000) {
            history.push({
                pathname: `/sent-phone-OTP/${type}`,
                state: { phone: phone, partner: finalPartner },
                search: finalPartner ? `?partner=${finalPartner}` : ''
            });
            return;
        }

        let storedInfo = sessionStorage.getItem(sessionKey);
        if (storedInfo) {
            let infoObj = JSON.parse(storedInfo);
            sessionStorage.setItem(sessionKey, JSON.stringify(infoObj));
        }
        try {
            const resp = await publicService.applyRegister(step, type, {});
            if (resp.status === 200) {
                sessionStorage.setItem('otpSentTime', currentTime.toString());
                history.push({
                    pathname: `/sent-phone-OTP/${type}`,
                    state: { phone: phone, partner: finalPartner },
                    search: finalPartner ? `?partner=${finalPartner}` : ''
                });
            }
        } catch (error: any) {
            api.error({
                message: 'Error',
                description: error.response.data,
            });
        } finally {
            setLoading(false);
        }
    };

    // const  backToInfo = () =>{
    //     history.push(`/contact-info/credit-card`);
    // }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            handleReceivePhoneOtp('gui-otp-dien-thoai');
        } catch (error) {
            console.log("Err", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Spin spinning={loading}>
                <div className="container-receive">
                    <span className='receive-otp'>Nhận mã OTP</span>
                    <p>Chọn phương thức nhận mã xác thực đăng ký trên<br/> Gocard</p>
                    <div className='phone' onClick={handleSubmit}>
                        <img src={phoneIcon} alt="phone-icon"/>
                        <span>Sử dụng điện thoại *****{cutPhoneNumber} của bạn để nhận mã xác thực</span>
                    </div>
                    <div className={`email ${isDisabled ? 'disabled' : ''}`}>
                        <img src={emailIcon} alt="email-icon"/>
                        <span>Sử dụng địa chỉ email {maskedEmail} của bạn để nhận mã xác thực</span>
                    </div>
                    <button className='btn-back-otp'>Quay lại</button>
                </div>
            </Spin>
        </>
    )
}

export default React.memo(ReceiveOTPPageView);
