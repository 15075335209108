import {TAppDispatch, IAppAction} from '@types';
import {ICheckPhone,ICheckIdCard} from "@types";
import axios from "axios";

export const CHECK_PHONE_BLACK_LIST = 'check.CHECK_PHONE_BLACK_LIST';
export const CHECK_ID_CARD_BLACK_LIST = 'check.CHECK_ID_CARD_BLACK_LIST';

const checkPhone = (phone: string) => async (dispatch: TAppDispatch<IAppAction<ICheckPhone>>) => {
    try {
        // Make an API call to get all banks
        const response = await axios.get(`https://api.gocard.vn/api/check/phone?phone=${phone}`);
        const check: ICheckPhone = response.data;
        // Dispatch an action with the categories as the payload
        dispatch({
            type: CHECK_PHONE_BLACK_LIST,
            payload: check,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};

const checkIdCard = (idCard: string) => async (dispatch: TAppDispatch<IAppAction<ICheckIdCard>>) => {
    try {
        // Make an API call to get all banks
        const response = await axios.get(`https://api.gocard.vn/api/check/idCard?idCard=${idCard}`);
        const check: ICheckIdCard = response.data;
        // Dispatch an action with the categories as the payload
        dispatch({
            type: CHECK_ID_CARD_BLACK_LIST,
            payload: check,
        });
    } catch (error) {
        // Handle the error if the API call fails
        console.error(error);
    }
};
export const checkActions = {checkPhone, checkIdCard};
