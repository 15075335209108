import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AppRouter, AppLayout} from '@components/shared';
import {DeviceProvider} from '@components/elements';
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {persistor, store} from "./redux/configurestore";

const App = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <DeviceProvider>
                <Router>
                    <AppLayout>
                        <AppRouter/>
                    </AppLayout>
                </Router>
            </DeviceProvider>
        </PersistGate>
    </Provider>
);

export default App;
