import React from 'react';
import "./Input.scss";
import warning from "@assets/images/icon-warning.png";
import {IonIcon} from "@ionic/react";

interface InputProps {
    name: string;
    type: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    icon: any;
    value: string;
    error: string;
    maxLength?: number;
    minLength?: number;
}

const Input: React.FC<InputProps> = ({
                                         name,
                                         type,
                                         label,
                                         onChange,
                                         icon,
                                         required,
                                         value,
                                         error,
                                         minLength,
                                         maxLength
                                     }) => {
    return (
        <>
            <div className={`input-group input-group-outline ${error ? 'mb-5' : 'mb-3'} ${value ? 'is-focused' : ''}`}>
                <IonIcon icon={icon}/>
                <label htmlFor={name} className="form-label">{label}
                    {required && <span className="fire_engine_red"> *</span>}
                </label>
                <input id={name}
                       type={type}
                       name={name}
                       onChange={onChange}
                       value={value}
                       minLength={minLength}
                       maxLength={maxLength}
                       className="form-control"
                       autoComplete="off"/>
                {error &&
                <p className="error_show">
                    <img src={warning} alt={"warning"}/>
                    {error}
                </p>
                }
            </div>
        </>
    )
};

export default React.memo(Input);
