import axios from "axios";

export const callReceiver = async () => {
    const config = {
        headers: {
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJwYXNzd29yZFwiOlwiXCIsXCJ1c2VybmFtZVwiOlwidG5leF9sYW5kaW5nX3BhZ2VcIixcImF1dGhvcml0aWVzXCI6W10sXCJhY2NvdW50Tm9uRXhwaXJlZFwiOnRydWUsXCJhY2NvdW50Tm9uTG9ja2VkXCI6dHJ1ZSxcImNyZWRlbnRpYWxzTm9uRXhwaXJlZFwiOnRydWUsXCJlbmFibGVkXCI6dHJ1ZX0iLCJleHAiOjE3ODc0NDcxNDIsImlhdCI6MTcwMTA0NzE0Mn0.tG1l7y6qP0Kqx_7SohTAMzUg9kLLnUlCo7l5_wwiqOIluB_SjPxuAddlmjPc-fZmLSx18wwkcd2ONcRs2DtE8A',
            'Content-Type': 'application/json',
        },
    };
    const storedData = sessionStorage.getItem('loanInfo');
    const loanInfoObj = JSON.parse(storedData!);

    const link = localStorage.getItem('link');

    await axios.post('https://receiver.dagoras.io/receiver', {
        "fullName": loanInfoObj.fullName,
        "phone": loanInfoObj.phone,
        "identityNumber": loanInfoObj.identityNumber,
        "loanAmount": loanInfoObj.loanAmount,
        "link": link,
    }, config)
        .then(_ => {
            // if (window.location.href.indexOf("ViettelPost") > -1) {
            //     window.location.href = "https://dagoras.tnexfinance.com.vn/thankyou";
            // } else {
            //     window.location.href = "https://tnexcampaign.onelink.me/93RY/pjo9ho9a";
            // }
            console.log('success');
        })
        .catch(function () {
            saveToBackup(loanInfoObj);
        });
}

function saveToBackup(data: any) {
    axios.get('https://receiver-backup.dagoras.io?data=' + JSON.stringify(data))
    // .then(response => {
    // })
}
