import React from 'react';
import { validation} from "@utils/constants";
import {Input1} from "@components/elements";
// import {Select2Data, Select2UpdateValue} from "select2-react-component";
import {Errors, ILead} from "../../views/HomePageView/HomePageView";

interface props {
    lead: ILead;
    errors: Errors;
    setLead: React.Dispatch<React.SetStateAction<ILead>>;
    setErrors: React.Dispatch<React.SetStateAction<Errors>>;
    setIsPrivacy: any;
    openModalPrivacyConfirm: any;
}

const ModalLead: React.FC<props> = ({lead, setLead, errors, setErrors, setIsPrivacy, openModalPrivacyConfirm}) => {
    // const careers: Select2Data = JSON.parse(JSON.stringify([
    //     {value: "Nhân viên văn phòng", label: "Nhân viên văn phòng"},
    //     {value: "Kỹ sư", label: "Kỹ sư"},
    //     {value: "Công an", label: "Công an"},
    //     {value: "Quân đội", label: "Quân đội"},
    //     {value: "Ngân hàng", label: "Ngân hàng"},
    //     {value: "Bác sĩ/y tá", label: "Bác sĩ/y tá"},
    //     {value: "Tự do", label: "Tự do"},
    //     {value: "Công nhân, tạp vụ", label: "Công nhân, tạp vụ"},
    //     {value: "Luật sư", label: "Luật sư"},
    //     {value: "Giáo viên/Giảng viên", label: "Giáo viên/Giảng viên"},
    //     {value: "Tài xế", label: "Tài xế"},
    //     {value: "Phi công", label: "Phi công"},
    //     {value: "Thuyền viên, thuỷ thủ", label: "Thuyền viên, thuỷ thủ"},
    //     {value: "Nông dân", label: "Nông dân"},
    //     {
    //         value: "Nhân viên massage, bar/club/pub/lounge, sòng bạc, tiệm cầm đồ",
    //         label: "Nhân viên massage, bar/club/pub/lounge, sòng bạc, tiệm cầm đồ"
    //     },
    //     {value: "Nhân viên thu nợ", label: "Nhân viên thu nợ"},
    //     {value: "Tự kinh doanh", label: "Tự kinh doanh"},
    //     {value: "Nghề nghiệp khác", label: "Nghề nghiệp khác"},
    // ]));
    // const incomes: Select2Data = JSON.parse(JSON.stringify([
    //     {value: "Dưới 7 triệu", label: "Dưới 7 triệu"},
    //     {value: "Từ 7 triệu - 10 triệu", label: "Từ 7 triệu - 10 triệu"},
    //     {value: "Từ 10 triệu - 15 triệu", label: "Từ 10 triệu - 15 triệu"},
    //     {value: "Từ 15 triệu - 20 triệu", label: "Từ 15 triệu - 20 triệu"},
    //     {value: "Trên 20 triệu", label: "Trên 20 triệu"},
    // ]));
    // const provinces: Select2Data = JSON.parse(JSON.stringify(provincesArray));
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setLead((lead: any) => ({
            ...lead,
            [name]: value ? value : ""
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() === "" ? "Vui lòng nhập trường này" :
                (name === 'phone' && !validation.isNumberPhone(value) || name === 'idCard' && !validation.isIdCard(value)
                    ? "Nhập sai định dạng" : "")
        }));
    }

    // const onSelectCareer = (e: Select2UpdateValue) => {
    //     const value = e as string;
    //     setLead(lead => ({
    //         ...lead,
    //         career: value ? value : ""
    //     }))
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         career: value.trim() === "" ? "Vui lòng chọn nghề nghiệp" : "",
    //     }));
    // }
    // const onSelectIncome = (e: Select2UpdateValue) => {
    //     const value = e as string;
    //     setLead(lead => ({
    //         ...lead,
    //         income: value ? value : ""
    //     }))
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         income: value.trim() === "" ? "Vui lòng chọn mức thu nhập" : "",
    //     }));
    // }
    // const onSelectProvince = (e: Select2UpdateValue) => {
    //     const value = e as string;
    //     setLead(lead => ({
    //         ...lead,
    //         province: value ? value : ""
    //     }))
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         province: value.trim() === "" ? "Vui lòng chọn tỉnh/ thành phố" : "",
    //     }));
    // }

    return (
        <>
            <Input1 name="name"
                    type="text"
                    label="Họ và tên"
                    value={lead.name}
                    onChange={onChange}
                    placeholder="Vui lòng nhập họ và tên"
                    icon={null}
                    required={true}
                    error={errors.name}/>
            <Input1 name="phone"
                    type="text"
                    inputMode="numeric"
                    label="Số điện thoại"
                    value={lead.phone}
                    onChange={onChange}
                    placeholder="Số điện thoại của bạn"
                    maxLength={10}
                    icon={null}
                    required={true}
                    error={errors.phone}/>
            <Input1 name="idCard"
                    type="text"
                    inputMode="numeric"
                    label="Số CMND/CCCD"
                    value={lead.idCard}
                    onChange={onChange}
                    placeholder="Số CMND/CCCD của bạn"
                    maxLength={12}
                    icon={null}
                    required={true}
                    error={errors.idCard}/>
            <Input1 name="email"
                    type="text"
                    label="Email"
                    value={lead.email}
                    onChange={onChange}
                    placeholder="Địa chỉ email của bạn"
                    icon={null}
                    required={true}
                    error={errors.email}/>
            {/*<Select placeholder="Nghề nghiệp của bạn"*/}
            {/*        value={lead.career}*/}
            {/*        onSelect={onSelectCareer}*/}
            {/*        required={true}*/}
            {/*        label="Nghề nghiệp"*/}
            {/*        data={careers}*/}
            {/*        error={errors.career}/>*/}
            {/*<Select placeholder="Thu nhập mỗi tháng của bạn"*/}
            {/*        value={lead.income}*/}
            {/*        onSelect={onSelectIncome}*/}
            {/*        required={true}*/}
            {/*        label="Thu nhập"*/}
            {/*        data={incomes}*/}
            {/*        error={errors.income}/>*/}
            {/*<Select data={provinces}*/}
            {/*        placeholder='Chọn tỉnh/ thành phố'*/}
            {/*        value={lead.province}*/}
            {/*        onSelect={onSelectProvince}*/}
            {/*        required={true}*/}
            {/*        label="Tỉnh/ thành phố"*/}
            {/*        error={errors.province}/>*/}
            <div style={{fontStyle: "italic", color: "white"}}>Ấn nút “Tư vấn thẻ
                tín dụng” là bạn đã chấp nhận các điều khoản và <a
                    onClick={() => setIsPrivacy(true)}
                    style={{color: "#0ff"}}>chính
                    sách bảo mật</a> của GoCard
            </div>

            <div className="text-center">
                <button className="btn-form"
                        onClick={openModalPrivacyConfirm}>Tư vấn thẻ tín
                    dụng
                </button>
            </div>
        </>
    );
}

export default React.memo(ModalLead);
