import React, {useEffect, useState} from 'react';
import './ModalDetailCard.scss';
import AppImage from "../../elements/AppImage/AppImage";
import {convertLink, onSubmit} from "@utils/constants";
import {ICard} from "@types";

interface props {
    data?: ICard;
    closeModal: any;
    isOpenModal: boolean;
}

const ModalDetailCard: React.FC<props> = ({data, closeModal, isOpenModal}) => {
    const [pValue, setPValue] = useState<string | null>('');
    const [refId, setRefId] = useState<string | null>('');

    useEffect(() => {
        setPValue(localStorage.getItem("pValue"));
        setRefId(localStorage.getItem("refId"))

    }, [])
    const onSubmitLink = (card: ICard | undefined) => {
        if (card?.direct === 1) {
            if (card?.code === 'vpbankthe') {
                if (pValue === 'mvt-gocard') {
                    window.location.replace("/dang-ky-thong-tin");
                } else {
                    window.open(convertLink(pValue, card));
                    onSubmit(card, pValue, refId);
                }
            } else {
                window.open(convertLink(pValue, card));
            }
        } else {
            closeModal();
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }
    return (
        <>
            <div id="myModal" className={`modal ${isOpenModal ? 'open' : ''}`}>
                <div className={`modal-content`}>
                    <span onClick={closeModal} className="close">&times;</span>
                    <h4>{data?.name}</h4>
                    <div className="dg-card-v2-colum-img-card mt-3">
                        <div className="dg-card-v2-img-card-detail">
                            <div className="bg-card">
                                <div className="img-card1">
                                    <AppImage className={"card-item__image-container--card-image"}
                                              pathImg={data?.img}/>
                                </div>
                            </div>
                        </div>
                        <div className="dg-card-v2-btn-card-detail">
                            <a onClick={() => onSubmitLink(data)}
                               className="dg-card-v2-btn-dk01">
                                <span>Đăng ký ngay</span>
                            </a>
                            <a href={`/chi-tiet-the/${data?.id}`}
                               className="link new-style-link text-center">Tìm hiểu thêm</a>

                        </div>
                    </div>
                    <p className="description" style={{color: "black"}}>
                        <div className="d-flex justify-content-between"
                             style={{"marginTop": "10px"}}>
                            <span className="partner-item-label">Hạn mức:&nbsp;</span>
                            <span
                                className="partner-item-value">{data?.han_muc}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                                                        <span
                                                            className="partner-item-label">Phí thường niên:&nbsp;</span>
                            <span
                                className="partner-item-value price-format">{data?.phi_thuong_nien}đ</span>
                        </div>
                        <div className="d-flex justify-content-between">
                                                        <span
                                                            className="partner-item-label">Phí rút tiền mặt:&nbsp;</span>
                            <span
                                className="partner-item-value">{data?.phi_rut_tien_mat}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="partner-item-label">Thu nhập:&nbsp;</span>
                            <span
                                className="partner-item-value">{data?.thu_nhap}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="partner-item-label">Tuổi:&nbsp;</span>
                            <span
                                className="partner-item-value">{data?.tuoi}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div style={{minWidth: "75px", textAlign: "left"}}>
                                <span className="partner-item-label">Mục đích: <br/></span>
                            </div>
                            <div className="text-end">
                                {data?.muc_dich.map((data1: any, index1: number) => (
                                    <>
                                <span key={index1}
                                      className="partner-item-value">{data1}</span><br/>
                                    </>
                                ))}
                            </div>

                        </div>

                    </p>
                </div>
            </div>
        </>
    );
}

export default React.memo(ModalDetailCard);
