import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';
import './ListCardPageView.scss';
import publicService from "../../../service/PublicService";
import {notification, Spin} from "antd";

interface LocationState {
    listCard: any;
    filterType: string;
}

declare global {
    interface Window {
        BM: any;
    }
}

const ListCardPageView = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {listCard, filterType} = location.state || {};
    const [activeButton, setActiveButton] = useState<string>(filterType);
    const [cardTypes, setCardTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorNotify, contextHolder] = notification.useNotification();
    const [cardType, setCardType] = useState(filterType);
    const [visibleCount, setVisibleCount] = useState(3);

    useEffect(() => {
        window.scroll(0, 0);

        const script = document.createElement('script');
        script.src = "https://apicampaign.bizfly.vn/js/sdk.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.BM) {
                window.BM.init({
                    project_token: '70963d3f-220d-449a-8014-35fe11e8fdb2',
                    app_key: 'ad53d774-dfdc-4b4a-a6c3-c62594b62904',
                    version: 'v3.0'
                });
            } else {
                console.error('BizMail SDK not loaded correctly, window.BM is undefined.');
            }
        };
        script.onerror = () => {
            console.error('Failed to load BizMail SDK script.');
        };
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        console.log('aaa', listCard)
    }, [listCard])

    const handleButtonClick = (buttonType: string) => {
        setCardType(buttonType);
        setActiveButton(buttonType);
        setVisibleCount(3);
    };

    const handleSeeMore = () => {
        setVisibleCount((prevCount) => prevCount + 3);
    };

    const filterCreditCards = (listCard: any, filterType: string) => {
        if (filterType === 'Tất cả') {
            return listCard;
        }
        return listCard.filter((card: any) => card.types.includes(filterType));
    };

    const resultCard = filterCreditCards(listCard, cardType);

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const handleDetailCard = (cardItem: any) => {
        setLoading(true);
        try {
            history.push('/card-detail', {cardItem: cardItem});
        } catch (error) {
            console.log("Error", error);
        } finally {
            setLoading(false);
        }
    }

    const handleCreate = async (card: any) => {
        const info = {
            product: card.name,
        }
        setLoading(true);
        try {
            const resp = await publicService.applyRegister('dang-ky', 'credit-card', info);
            if (resp.status === 200) {

                const UUID = sessionStorage.getItem('user_id');

                await publicService.saveLeadProduct({
                    name: card.name,
                    creditLimitTo: card.creditLimitTo,
                    creditLimitFrom: card.creditLimitFrom,
                    annualFee: card.annualFee,
                    cashAdvanceFee: card.cashAdvanceFee,
                    monthlyIncome: card.monthlyIncome,
                    requiredAge: card.requiredAge,
                    features: card.features,
                    interestRate: card.interestRate,
                    uuid: UUID,
                })

                if (window.BM) {
                    const email = localStorage.getItem("userEmail")
                    window.BM.importAutomation({
                        uuid: '75c185d6-3cb5-4a08-8d82-5727e3c9b055',
                        source: 'source-name',
                        subscriber: {
                            name: 'Tên người đăng ký',
                            emails: email,
                            phone: 'số điện thoại'
                        }
                    });
                }

                history.push('/success', {cardItem: card})
                setLoading(false);
            }
        } catch (error: any) {
            errorNotify.error({
                message: 'Lỗi',
                description: error.response?.data || 'An error occurred',
            });
        } finally {
            setLoading(false);
        }
    }

    const fetchCardTypes = async () => {
        const response = await publicService.getAllCardTypes();
        if (response.status === 200) {
            setCardTypes(response.data);
        }
    };

    useEffect(() => {
        fetchCardTypes();
    }, []);

    return (
        <>
            {contextHolder}
            <div className='container'>
                <Spin spinning={loading}>
                    <div className='btn-list-card'>
                        <button className={`btn-all ${activeButton === 'Tất cả' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('Tất cả')}>
                            Tất cả
                            <div className="line"></div>
                        </button>
                        {cardTypes.map((type: any) => (
                            <button key={type.value}
                                    className={`btn-all ${activeButton === type.value ? 'active' : ''}`}
                                    onClick={() => handleButtonClick(type.value)}>
                                {type.value}
                                <div className="line"></div>
                            </button>
                        ))}
                    </div>
                    <h1 className='text-list-card'>DANH SÁCH THẺ TÍN DỤNG</h1>
                    <div className='list'>
                        {resultCard.slice(0, visibleCount).map((card: any) => (
                            <div key={card.id} className='list-card mb-3'>
                                <div className={`card-icon ${card.partner.toLowerCase().includes('vpbank') ? 'vp-icon'
                                    : card.partner.toLowerCase().includes('tp') ? 'tp-icon'
                                        : card.partner.toLowerCase().includes('vib') ? 'vib-icon' : ''}`}>
                                    <img src={card.iconUrl} alt="card-icon"/>
                                </div>
                                <span className='pt-2'>{card.name}</span>
                                <div className='card-open row'>
                                    <div className="col-6">
                                        <img src={card.imageUrl} className = {card.partner.toLowerCase().includes('tp') ? 'tp-img' : ''} alt="card"/>
                                    </div>
                                    <div
                                        className="col-6 d-flex flex-column justify-content-center align-items-center open-card">
                                        <button className="mb-2" onClick={() => handleCreate(card)}>Mở thẻ ngay
                                        </button>
                                        <a onClick={() => handleDetailCard(card)}>Tìm hiểu thêm &gt;</a>
                                    </div>
                                </div>
                                <div className='credit-limit'>
                                    <span className='span-title'>Loại thẻ:</span>
                                    <span className="span-content">
                                        {card.types}
                                    </span>
                                </div>
                                <div className='credit-limit mt-2'>
                                    <span className='span-title'>Phí thường niên:</span>
                                    <span className="span-content">{formatNumberCustom(card.annualFee)}</span>
                                </div>
                                <div className='credit-limit mt-2'>
                                    <span className='span-title'>Lãi suất năm:</span>
                                    <span className="span-content">{card.interestRate}</span>
                                </div>
                            </div>
                        ))}
                        {visibleCount < resultCard.length && (
                            <a className='see-more' onClick={handleSeeMore}>Xem thêm -&gt;</a>
                        )}
                    </div>

                    <ScrollToTopButton/>
                </Spin>
            </div>
        </>
    );
}

export default React.memo(ListCardPageView);