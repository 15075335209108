import React, { useEffect, useState } from 'react';
import './DetailCardPageView.scss';
import { ICard } from "@types";
import * as data from "@assets/data/data.module.json"
import AppImage from "../../elements/AppImage/AppImage";
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import { convertLink } from '@utils/constants';

const DetailCardPageView = () => {
    const location = useLocation();
    let cards = [...data.partners, ...data.otherCards];
    const card: ICard = cards.filter(card => card.id === location.pathname.split("/")[2])[0];

    const [showTopInfo, setShowTopInfo] = useState(false);
    const [removeFixed, setIsRemoveFix] = useState(false);
    const [pValue, setPValue] = useState<string | null>('');

    useEffect(() => {
        setPValue(localStorage.getItem("pValue"));
        // Add the scroll event listener and handle scroll behavior
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setShowTopInfo(true);
            } else {
                setShowTopInfo(false);
            }
            const y_scroll_pos = window.pageYOffset;
            const footerElement = document.querySelector('.footer') as HTMLElement;
            const leftContentElement = document.querySelector('.dg-card-left-content-detail') as HTMLElement;

            if (footerElement && leftContentElement) {
                const scroll_pos_test = footerElement.offsetTop;

                if (y_scroll_pos > 55 && y_scroll_pos + leftContentElement.offsetHeight + 190 + 40 > scroll_pos_test) {
                    setIsRemoveFix(true);
                } else {
                    setIsRemoveFix(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="dg-card-v2-wrapper_new">
                <div data-cmp-is="Breadcrumb" className="cmp-breadcrumb bread-crumb">
                    <i style={{ fontSize: "17px" }} className="material-icons opacity-6">home</i>
                    <a className="cmp-breadcrumb__page--home" href={`/`}>
                        Trang chủ</a>
                    &nbsp;
                    &nbsp;
                    <span className="cmp-breadcrumb__page--to">&gt;</span>
                    &nbsp;
                    &nbsp;
                    <span className="cmp-breadcrumb__page--current">{card?.name}</span>
                </div>
            </div>
            <section className="dg-card-v2-content-detail-card" style={{ marginTop: "0px !important" }}>

                <div className="dg-card-v2-wrapper_new">
                    <div className={`dg-card-left-content-detail ${removeFixed ? 'remove-fix' : ''}`}>
                        <h1>{card?.name}</h1>
                        <div className="dg-card-v2-colum-img-card">
                            <div className="dg-card-v2-img-card-detail">
                                <div className="bg-card">
                                    <div className="img-card1">
                                        <AppImage pathImg={card?.img} />
                                    </div>
                                </div>
                                <i className="dg-card-v2-sticker-mptn" />

                            </div>
                            <div className="dg-card-v2-btn-card-detail">
                                {card?.direct === 1 &&
                                    <a href={card?.code === 'vpbankthe' ? '/dang-ky-thong-tin' : convertLink(pValue, card)}
                                        target={card?.code === 'vpbankthe' ? '' : '_blank'}
                                        className="dg-card-v2-btn-dk01"><span>Đăng ký ngay</span></a>
                                }
                                <div className="">
                                    <i className="dg-card-v2-sticker-mptn">
                                        <div className="dg-card-v2-news-best">{card?.label}</div>
                                    </i>
                                </div>
                            </div>
                            <div className="clearfix" />
                        </div>
                        <div className="dg-card-v2-colum-txt-card">
                            <div className="dg-card-v2-box-txt-card-detail">
                                <h4>THẺ TÍN DỤNG TỐT NHẤT CHO</h4>
                                <p dir="ltr">{card?.the_tot_nhat}</p>

                            </div>
                            <div className="dg-card-v2-box-txt-card-detail">
                                <h4>HẠNG THẺ VÀ THƯƠNG HIỆU</h4>
                                <p dir="ltr">Hạng thẻ: <strong>{card?.hang_the}</strong></p>
                                <p dir="ltr">Thương hiệu: <strong>{card?.thuong_hieu}</strong></p>

                            </div>

                            <div className="dg-card-v2-box-txt-card-detail">
                                <h4>HẠN MỨC VÀ PHÍ THƯỜNG NIÊN</h4>
                                <p dir="ltr">Hạn mức: <strong>{card?.han_muc}</strong></p>
                                <p dir="ltr">Phí thường niên: <strong>{card?.phi_thuong_nien}</strong></p>
                            </div>
                            <div className="dg-card-v2-box-txt-card-detail" />
                        </div>
                        <div className="clearfix" />
                        <div className="custom-class" />
                    </div>
                    <div className="dg-card-right-content-deatil" style={{ minHeight: "661px" }}>
                        <div className="dg-card-v2-box-colum-right-content"
                            id="dg-card-v2-box-colum-right-content-benefit">

                            <h2>Đặc quyền khi mở thẻ {card?.name}</h2>
                            <div className="dg-card-v2-list-benefit-card">
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>1.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Thời gian miễn lãi
                                            </h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- Tối đa <strong>{card?.thoi_gian_mien_lai}</strong></li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>2.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Lãi suất</h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- {card?.lai_suat}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>3.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Phí phát hành thẻ</h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- {card?.phi_phat_hanh_the}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>4.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Phí thường niên thẻ chính và thẻ
                                                phụ</h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- Thẻ chính: <strong>{card?.phi_thuong_nien_the_chinh}</strong></li>
                                            <li>- Thẻ phụ: <strong>{card?.phi_thuong_nien_the_phu}</strong></li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>5.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Phí ứng/rút tiền mặt tại ATM
                                            </h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- {card?.phi_ung_rut_tien_mat}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>6.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Khoản thanh toán tối thiểu
                                            </h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>{card?.khoan_thanh_toan_toi_thieu}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>7.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Phí chậm thanh toán
                                            </h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- {card?.phi_cham_thanh_toan}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>8.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Phí chuyển đổi trả góp
                                            </h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- {card?.phi_chuyen_doi_tra_gop}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>9.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Phí giao dịch ngoại tệ
                                            </h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- {card?.phi_giao_dich_ngoai_te}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>10.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Phí vượt hạn mức tín dụng
                                            </h4>
                                        </div>
                                        <ul dir="ltr">
                                            <li>- {card?.phi_vuot_han_muc_tin_dung}</li>
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>11.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Hoàn phí thường niên</h4>
                                        </div>
                                        <ul className="lv1">
                                            {map(card?.hoan_phi_thuong_nien, (content: string, index: number) => (
                                                <li key={index}>{content}</li>
                                            ))}
                                        </ul>

                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>12.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Hoàn tiền</h4>
                                        </div>
                                        <ul dir="ltr">
                                            {map(card?.hoan_tien, (content: string, index: number) => (
                                                <li key={index}>{content}</li>
                                            ))}
                                        </ul>

                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>13.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Tích diểm</h4>
                                        </div>
                                        <ul dir="ltr">
                                            {map(card?.tich_diem, (content: string, index: number) => (
                                                <li key={index}>{content}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>14.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Chi tiêu dặm</h4>
                                        </div>
                                        <ul dir="ltr">
                                            {map(card?.chi_tieu_dam, (content: string, index: number) => (
                                                <li key={index}>{content}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                <div className="dg-card-v2-box-benefit-card">
                                    <div className="txt_benefit_card">
                                        <div style={{ verticalAlign: "top", display: "inline-flex", width: "100%" }}>
                                            <h4 style={{ marginRight: "5px" }}>15.</h4>
                                            <h4 style={{ maxWidth: "calc(100% - 25px)" }}>Ưu đãi đặc quyền</h4>
                                        </div>
                                        <ul dir="ltr">
                                            {map(card?.uu_dai_dac_quyen, (content: string, index: number) => (
                                                <li key={index}>{content}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="clearfix" />
                </div>
                {showTopInfo &&
                    <div className="dg-card-v2-call-to-action-fix-top">
                        <div><h2>{card?.name}</h2></div>
                        <div><a href={card?.code === 'vpbankthe' ? '/dang-ky-thong-tin' : convertLink(pValue, card)}
                            target={card?.code === 'vpbankthe' ? '' : '_blank'}
                            className="dg-card-v2-btn-dk01">
                            <span>Đăng ký ngay</span>
                        </a>
                        </div>
                    </div>}
            </section>
            <ScrollToTopButton />
        </>
    );
};

export default React.memo(DetailCardPageView);
